/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

function PaginationComponent({
  page, hasPrevPage, hasNextPage, totalPages, onPageChange,
}) {
  const getPaginationItem = () => {
    const paginationItem = [];
    const interval = 3;
    let start;
    let end;

    if (page < totalPages - interval) {
      start = page;
      end = page + interval;
    } else if (totalPages > interval) {
      start = totalPages - interval;
      end = totalPages;
    } else {
      start = 1;
    }

    for (let i = start; i < end; i += 1) {
      paginationItem.push(i);
    }

    return paginationItem;
  };

  return (
    <Pagination>
      {page > 1 && (
        <PaginationItem onClick={() => onPageChange(1)}>
          <PaginationLink>
            <i className="fas fa-angle-double-left" />
          </PaginationLink>
        </PaginationItem>
      )}
      {hasPrevPage && (
        <PaginationItem onClick={() => onPageChange(page - 1)}>
          <PaginationLink>
            <i className="fas fa-angle-left" />
          </PaginationLink>
        </PaginationItem>
      )}
      {getPaginationItem().map((paginationItem) => (
        <PaginationItem
          key={paginationItem}
          active={page === paginationItem}
          onClick={() => onPageChange(paginationItem)}
        >
          <PaginationLink>{paginationItem}</PaginationLink>
        </PaginationItem>
      ))}
      {hasNextPage && (
        <PaginationItem onClick={() => onPageChange(page + 1)}>
          <PaginationLink>
            <i className="fas fa-angle-right" />
          </PaginationLink>
        </PaginationItem>
      )}
      {page < totalPages && (
        <PaginationItem onClick={() => onPageChange(totalPages)}>
          <PaginationLink>
            <i className="fas fa-angle-double-right" />
          </PaginationLink>
        </PaginationItem>
      )}
    </Pagination>
  );
}

export default PaginationComponent;
