import React, { useCallback, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';

import { AppContext } from '../../../contexts/app';
import { useAuth } from '../../../hooks/auth';

function Topbar({ toggleSidebar }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { setLoading } = useContext(AppContext);
  const { signOutAdmin } = useAuth();

  const handleLogout = useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        navigate('/');
        await signOutAdmin();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div className="d-flex justify-content-between shadow-sm p-3 mb-5 bg-info rounded">
      <div>
        <Button color="primary" onClick={toggleSidebar}>
          <i className="fa-solid fa-align-left text-white" />
        </Button>
        {location.pathname !== '/' && (
          <Button color="primary" className="ml-3 text-white" onClick={() => navigate(-1)}>
            <i className="fa-solid fa-angles-left mr-2" />
            Voltar
          </Button>
        )}
      </div>
      <Button color="primary" onClick={handleLogout}>
        <i className="fa-solid fa-power-off mr-2" />
        Sair
      </Button>
    </div>
  );
}

export default Topbar;
