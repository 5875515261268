import { validateCNPJ } from 'validations-br';
import * as Yup from 'yup';

export const schema = Yup.object().shape({
  nome: Yup.string().required('Informe uma Razão Social'),
  nomeFantasia: Yup.string().required('Informe um nome Fantasia'),
  cnpj: Yup.string()
    .test('is-valid-cpf', 'CNPJ inválido', (value) => validateCNPJ(value))
    .required('Informe um CNPJ'),
  /* grupo: Yup.object().nullable().required('Selecione um grupo').shape({
    value: Yup.string().required('Selecione um grupo'),
  }), */
});
