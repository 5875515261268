import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { NavItem, NavLink, Nav, Button } from 'reactstrap';

import classNames from 'classnames';

import athoslabsicon from '../../../assets/img/athoslabs-icon.svg';
import athoslabs from '../../../assets/img/athoslabs.svg';
import logocompleto from '../../../assets/img/logocompleto.png';
import navigation from './navigation';
// import SubMenu from './SubMenu';

import './styles.css';

function SideBar({ isOpen, isShow, toggle }) {
  const location = useLocation();
  const params = useParams();

  return (
    <div className={classNames('sidebar', { 'is-open': isOpen, closed: !isShow })}>
      <div>
        <div className="position-relative sidebar-header">
          <Button className="position-absolute top-2 left-1 text-white" color="link" onClick={toggle}>
            <i className="fa-solid fa-circle-xmark" />
          </Button>
          <img
            src={logocompleto}
            className={classNames({ 'mt-3': isOpen, 'mt-5': !isOpen })}
            alt="nic"
          />
          <h3 className={classNames({ 'd-none': !isOpen })}>NIC - ADMIN</h3>
        </div>
        <div className="side-menu">
          <Nav vertical className="list-unstyled pb-3">
            {navigation.map(({ to, icon, name }) => (
              <NavItem
                title={name}
                key={`${to}`}
                className={classNames('items-menu d-flex justify-content-between align-items-center', {
                  active: location.pathname === to || `${location.pathname}` === `${to}/${params.id}`,
                })}
              >
                <NavLink className="w-100" tag={Link} to={to}>
                  <i className={`${icon} mr-2`} />
                  <span className={classNames({ 'd-none': !isOpen })}>{ name }</span>
                </NavLink>
                <Button className={classNames({ 'd-none': !isOpen })} color="link" tag="a" target="_blank" href={`${process.env.REACT_APP_URL}${to}`}>
                  <i className="fa-solid fa-arrow-up-right-from-square" />
                </Button>
              </NavItem>
            ))}
          </Nav>
        </div>
      </div>
      <div className={classNames('sidebar-footer', { 'is-open': isOpen })}>
        <a href="https://athoslabs.com.br" title="by athoslabs" target="_blank" rel="noopener noreferrer">
          <img src={athoslabsicon} alt="" />
        </a>
        <a href="https://athoslabs.com.br" title="by athoslabs" target="_blank" rel="noopener noreferrer">
          <img src={athoslabs} alt="athoslabs" className="ml-3" />
        </a>
      </div>
    </div>
  );
}

export default SideBar;
