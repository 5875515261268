/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import InputMask from 'react-input-mask';
import { PatternFormat } from 'react-number-format';
import { useParams } from 'react-router-dom';
import { Alert, Button, Card, CardBody, CardHeader, Col, Collapse, Container, Form, FormFeedback, FormGroup, Input, Label, Row, UncontrolledCollapse } from 'reactstrap';

import { format, getYear, parseISO, subYears } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { range } from 'lodash';
import { validateCPF, validatePhone } from 'validations-br';
import * as Yup from 'yup';

import CustomMaskedInput from '../../components/CustomMaskedInput';
import CustomSelect from '../../components/CustomSelect';
import DatePickerMaskedInput from '../../components/DatePickerMaskedInput';
import Endereco from '../../components/Endereco';
import Notification from '../../components/Notification';
import Select from '../../components/Select';
import TabelaValores from '../../components/TabelaValorCliente';
import UploadFiles from '../../components/UploadFiles';
import ValidationErrorsCount from '../../components/ValidationErrorCount';
import { AppContext } from '../../contexts/app';
import { useAuth } from '../../hooks/auth';
import { useManager } from '../../hooks/manager';
import api from '../../services/api';

function Cliente() {
  // const navigate = useNavigate();
  const params = useParams();
  const notificationRef = useRef();
  const criancaEnderecoRef = useRef();
  const responsavelEnderecoRef = useRef();
  const tabelaValoresRef = useRef();
  const { loggedUser } = useAuth();
  const { update, store } = useManager();
  const { setLoading } = useContext(AppContext);
  const [loadingTabelaValores, setLoadingTabelaValores] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [validationErrorsResponsaveisCount, setValidationErrorsResponsaveisCount] = useState(0);
  const [validationErrorsCriancasCount, setValidationErrorsCriancasCount] = useState(0);
  const [validationErrorsTabelaValoresCount, setValidationErrorsTabelaValoresCount] = useState(0);
  const [id, setId] = useState();
  const [supervisores, setSupervisores] = useState([]);
  const [supervisor, setSupervisor] = useState('');
  const [adminsFaturamento, setAdminsFaturamento] = useState([]);
  const [adminFaturamento, setAdminFaturamento] = useState('');
  const [grupos, setGrupos] = useState([]);
  const [grupo, setGrupo] = useState('');
  const [dadosCriancasOpen, setDadosCriancasOpen] = useState(false);
  const [criancaCreatedAt, setCriancaCreatedAt] = useState('');
  const [criancaNome, setCriancaNome] = useState('');
  const [criancaCpf, setCriancaCpf] = useState('');
  const [criancaRg, setCriancaRg] = useState('');
  const [criancaNascimento, setCriancaNascimento] = useState('');
  const [criancaDadoEmissaoNFBoleto, setCriancaDadoEmissaoNFBoleto] = useState('');
  const [criancaDadoEmissaoNFBoletoValue, setCriancaDadoEmissaoNFBoletoValue] = useState('');
  const [criancaReembolso, setCriancaReembolso] = useState(false);
  const [criancaReembolsoValue, setCriancaReembolsoValue] = useState('');
  const [criancas, setCriancas] = useState([]);
  const [criancasDadoEmissaoNFBoleto, setCriancasDadoEmissaoNfBoleto] = useState([]);
  const [dadosResponsavelOpen, setDadosResponsavelOpen] = useState(false);
  const [responsavelCreatedAt, setResponsavelCreatedAt] = useState('');
  const [responsavelNome, setResponsavelNome] = useState('');
  const [responsavelCpf, setResponsavelCpf] = useState('');
  const [responsavelRg, setResponsavelRg] = useState('');
  const [responsavelNascimento, setResponsavelNascimento] = useState('');
  const [responsavelEmail, setResponsavelEmail] = useState('');
  const [responsavelPassword, setResponsavelPassword] = useState('');
  const [responsavelPasswordConfirmation, setResponsavelPasswordConfirmation] = useState('');
  const [responsavelWhatsapp, setResponsavelWhatsapp] = useState('');
  const [responsavelProfissao, setResponsavelProfissao] = useState('');
  const [responsavelNacionalidade, setResponsavelNacionalidade] = useState('');
  const [responsavelEstadoCivil, setResponsavelEstadoCivil] = useState('SOLTEIRO');
  const [responsaveis, setResponsaveis] = useState([]);
  const [observacao, setObservacao] = useState('');
  const [observacoes, setObservacoes] = useState([]);
  const [solicitationDeleteAccount, setSolicitationDeleteAccount] = useState();
  const [newTabelaValor, setNewTabelaValor] = useState('');
  const [tabelaValor, setTabelaValor] = useState('');
  const [tabelaValores, setTabelaValores] = useState([]);
  const [tabelaValoresList, setTabelaValoresList] = useState([]);
  const [documentosAnexados, setDocumentosAnexados] = useState([]);
  const [dataAssinaturaContrato, setDataAssinaturaContrato] = useState('');
  const [dataEncerramentoContrato, setDataEncerramentoContrato] = useState('');

  useEffect(() => {
    setValidationErrors({});
    setValidationErrorsResponsaveisCount(0);
    setValidationErrorsCriancasCount(0);
    setValidationErrorsTabelaValoresCount(0);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { data = [] } = await api.get('/admins?limit=0');
        // console.log(data);
        setAdminsFaturamento(data.map((d) => ({
          value: d._id,
          label: d.name,
        })));
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { data = [] } = await api.get('/profissionais?search=[{"field":"isSupervisor","value":"true"}]&limit=0');
        // console.log(data);
        setSupervisores(data.map((d) => ({
          value: d._id,
          label: d.name,
        })));
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { data = [] } = await api.get('/grupos?search=[{"field":"tipo","value":"cliente"}]&limit=0');
        // console.log(data);
        setGrupos(data.map((d) => ({
          value: d._id,
          label: d.nome,
        })));
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoadingTabelaValores(true);
        const { data = [] } = await api.get('/tabelaValoresClientes?limit=0');
        // console.log(data);
        setTabelaValoresList(
          data.map((tv) => ({
            value: tv._id,
            label: tv.nome,
          })),
        );
        setTabelaValores((prevTabelaValores) => prevTabelaValores.filter((tv) => !!data.find((d) => tv.value === d._id)));
        setLoadingTabelaValores(false);
      } catch (error) {
        setLoadingTabelaValores(false);
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (newTabelaValor) {
      setTabelaValores((prevTabelaValores) => [{ value: newTabelaValor._id, label: newTabelaValor.nome }, ...prevTabelaValores]);
      setNewTabelaValor('');
    }
  }, [newTabelaValor]);

  useEffect(() => {
    (async () => {
      try {
        if (params?.id) {
          setLoading({
            loading: true,
            message: 'Aguarde... Carregando informações do cliente... ',
          });
          const { data } = await api.get(`/users/${params.id}`);
          console.log(data);
          setValidationErrors({});
          setId(data?._id || '');
          setGrupo(data?.grupo ? { value: data.grupo._id, label: data.grupo.nome } : '');
          setCriancas(data?.criancas?.map((c) => ({
            ...c,
            originalCpf: c.cpf,
            supervisor: c.supervisor ? { value: c.supervisor._id, label: c.supervisor.name } : '',
            adminFaturamento: c.adminFaturamento ? { value: c.adminFaturamento._id, label: c.adminFaturamento.name } : '',
          })) || []);
          setCriancasDadoEmissaoNfBoleto(data?.criancas?.map((c) => ({
            cpf: c.cpf,
          })) || []);
          setResponsaveis(data?.responsaveis?.map((r) => ({ ...r, originalCpf: r.cpf })) || []);
          setObservacoes(data?.observacoes?.map((o) => ({ ...o, createdByUser: o?.createdByUser?.name ? { ...o.createdByUser, name: o?.createdByUser?.name } : { name: 'Não informado' }, createdAt: parseISO(o.createdAt) })) || []);
          setTabelaValores(data?.tabelaValores?.map((t) => ({ label: t.nome, value: t._id })) || []);
          setDocumentosAnexados(data?.documentosAnexados || []);
          setSolicitationDeleteAccount(data?.solicitationDeleteAccount);
          setDataAssinaturaContrato(data?.dataAssinaturaContrato ? parseISO(data.dataAssinaturaContrato) : '');
          setDataEncerramentoContrato(data?.dataEncerramentoContrato ? parseISO(data.dataEncerramentoContrato) : '');
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, [params?.id]);

  const onFocusCriancaDadoEmissaoNfBoleto = useCallback((value) => {
    if (value === criancaDadoEmissaoNFBoleto) {
      setCriancaDadoEmissaoNFBoleto('');
    }
    setCriancasDadoEmissaoNfBoleto((prevStates = []) => prevStates.filter(({ cpf }) => cpf !== value));
  }, [criancaDadoEmissaoNFBoleto]);

  const onBlurCriancaDadoEmissaoNfBoleto = useCallback((value) => {
    setCriancasDadoEmissaoNfBoleto((prevStates = []) => [{ cpf: value }, ...prevStates.filter(({ cpf }) => cpf !== value)]);
  }, []);

  const clearDadosCrianca = useCallback(() => {
    setCriancaCreatedAt('');
    setCriancaNome('');
    setCriancaNascimento('');
    setCriancaCpf('');
    setCriancaRg('');
    setCriancaDadoEmissaoNFBoleto('');
    /* criancaEnderecoRef.current.setData({
      cep: '',
      logradouro: '',
      numero: '',
      bairro: '',
      cidade: '',
      ibge: '',
      regiao: '',
      estado: '',
      localizacao: null,
    }); */
    setDadosCriancasOpen(false);
  }, []);

  const handleAddCrianca = useCallback(() => {
    (async () => {
      try {
        const dataToValidation = {
          criancaNome,
          criancaNascimento,
          criancaCpf,
          criancaDadoEmissaoNFBoleto,
        };

        const schema = Yup.object().shape({
          criancaNome: Yup.string().required(
            'Informe o nome completo da criança',
          ),
          criancaNascimento: Yup.string()
            .required('Informe o nascimento'),
          criancaCpf: Yup.string()
            .test('is-valid-cpf', 'CPF inválido', (value) => validateCPF(value))
            .required('Informe'),
          criancaDadoEmissaoNFBoleto: Yup.string().required('Selecione a forma de emissão NF e boleto'),
        });

        await schema.validate(dataToValidation, {
          abortEarly: false,
        });

        setCriancas((prevCriancas = []) => {
          console.log('🚀 ~ file: index.js:273 ~ returnprevCriancas.map ~ supervisor:', supervisor);
          if (prevCriancas.find((c) => c.cpf === c.prevCpf)) {
            clearDadosCrianca();
            return prevCriancas.map((c) => (c.cpf === c.prevCpf ? {
              ...c,
              supervisor: supervisor?.value ? supervisor : '',
              adminFaturamento: adminFaturamento?.value ? adminFaturamento : '',
              nome: criancaNome,
              nascimento: criancaNascimento,
              cpf: criancaCpf,
              prevCpf: criancaCpf,
              rg: criancaRg,
              dadoEmissaoNFBoleto: criancaDadoEmissaoNFBoleto,
              dadoEmissaoNFBoletoValue: criancaDadoEmissaoNFBoletoValue,
              reembolso: criancaReembolso,
              reembolsoValue: criancaReembolsoValue,
              endereco: criancaEnderecoRef.current.getData(),
            } : c));
          }

          clearDadosCrianca();
          setValidationErrors((prevState) => ({
            ...prevState,
            criancaNome: '',
            criancaNascimento: '',
            criancaCpf: '',
            criancaDadoEmissaoNFBoleto: '',
          }));

          return [
            ...prevCriancas,
            {
              supervisor: supervisor?.value ? supervisor : '',
              adminFaturamento: adminFaturamento?.value ? adminFaturamento : '',
              nome: criancaNome,
              nascimento: criancaNascimento,
              cpf: criancaCpf,
              rg: criancaRg,
              dadoEmissaoNFBoleto: criancaDadoEmissaoNFBoleto,
              dadoEmissaoNFBoletoValue: criancaDadoEmissaoNFBoletoValue,
              reembolso: criancaReembolso,
              reembolsoValue: criancaReembolsoValue,
              endereco: criancaEnderecoRef.current.getData(),
            },
          ];
        });
      } catch (err) {
        console.log(err);
        setValidationErrors((prevState) => ({
          ...prevState,
          criancaNome: '',
          criancaNascimento: '',
          criancaCpf: '',
          criancaDadoEmissaoNFBoleto: '',
        }));
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message: 'Por favor, preencha todos os dados necessários da criança',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors((prevState) => ({ ...prevState, ...ve }));
        }
      }
    })();
  }, [
    supervisor,
    adminFaturamento,
    criancaNome,
    criancaNascimento,
    criancaCpf,
    criancaRg,
    criancaDadoEmissaoNFBoleto,
    criancaDadoEmissaoNFBoletoValue,
    criancaReembolso,
    criancaReembolsoValue,
    criancaEnderecoRef,
    validateCPF,
  ]);

  const handleRemoveCrianca = useCallback((crianca) => {
    setCriancas((prevCriancas = []) => (prevCriancas.filter((c) => c.cpf !== crianca.cpf)));
  }, []);

  const handleUpdateCrianca = useCallback((crianca) => {
    console.log('🚀 ~ file: index.js:307 ~ handleUpdateCrianca ~ crianca:', crianca);
    setCriancaCreatedAt(crianca?.createdAt || '');
    setSupervisor(crianca?.supervisor ? { value: crianca.supervisor.value, label: crianca.supervisor.label } : '');
    setAdminFaturamento(crianca?.adminFaturamento ? { value: crianca.adminFaturamento.value, label: crianca.adminFaturamento.label } : '');
    setCriancaNome(crianca.nome);
    setCriancaNascimento(new Date(crianca.nascimento));
    setCriancaCpf(crianca.cpf);
    setCriancaDadoEmissaoNFBoleto(crianca.dadoEmissaoNFBoleto);
    setCriancaDadoEmissaoNFBoletoValue(crianca.dadoEmissaoNFBoletoValue || '');
    setCriancaReembolso(crianca.reembolso);
    setCriancaReembolsoValue(crianca.reembolsoValue || '');
    criancaEnderecoRef.current.setData(crianca.endereco);
    setDadosCriancasOpen(true);
    setCriancas((prevCriancas = []) => prevCriancas.map((c) => (c.cpf === crianca.cpf ? { ...c, prevCpf: crianca.cpf, originalCpf: c.originalCpf ? c.originalCpf : crianca.cpf } : { ...c, prevCpf: undefined })));
  }, [criancaEnderecoRef]);

  const clearDadosResponsavel = useCallback(() => {
    setResponsavelCreatedAt('');
    setResponsavelNome('');
    setResponsavelCpf('');
    setResponsavelRg('');
    setResponsavelNascimento('');
    setResponsavelEmail('');
    setResponsavelWhatsapp('');
    setResponsavelNacionalidade('');
    setResponsavelEstadoCivil('SOLTEIRO');
    setResponsavelProfissao('');
    setResponsavelPassword('');
    setResponsavelPasswordConfirmation('');
    /* responsavelEnderecoRef.current.setData({
      cep: '',
      logradouro: '',
      numero: '',
      bairro: '',
      cidade: '',
      ibge: '',
      regiao: '',
      estado: '',
      localizacao: null,
    }); */
    setDadosResponsavelOpen(false);
  }, []);

  const handleAddResponsavel = useCallback(() => {
    (async () => {
      try {
        const endereco = responsavelEnderecoRef.current.getData();
        const dataToValidation = {
          responsavelNome,
          responsavelNascimento,
          responsavelCpf,
          responsavelEmail,
          responsavelWhatsapp,
          responsavelPassword,
          responsavelPasswordConfirmation,
          cep: endereco?.cep || '',
          logradouro: endereco?.logradouro || '',
          numero: endereco?.numero || '',
          bairro: endereco?.bairro || '',
          cidade: endereco?.cidade?.value || '',
          estado: endereco?.estado?.sigla || '',
        };

        const schemaValidation = {
          responsavelNome: Yup.string().required(
            'Informe o nome completo do Responsável',
          ),
          responsavelEmail: Yup.string()
            .email('E-mail inválido')
            .required('Informe um email para contato e login'),
          responsavelWhatsapp: Yup.string()
            .test('is-valid-phone', 'Número de telefone inválido', (value) => validatePhone(value))
            .required('Informe este campo'),
          /* responsavelNascimento: Yup.string()
            .required('Informe o nascimento'), */
          responsavelCpf: Yup.string()
            .test('is-valid-cpf', 'CPF inválido', (value) => validateCPF(value))
            .required('Informe'),
          cep: Yup.string().required('Informe este campo'),
          logradouro: Yup.string().required('Informe este campo'),
          // numero: Yup.string().required('Informe este campo'),
          bairro: Yup.string().required('Informe este campo'),
          cidade: Yup.string().required('Informe este campo'),
          estado: Yup.string().required('Informe este campo'),
        };

        if (!id || (id && responsavelPasswordConfirmation)) {
          Object.assign(schemaValidation, {
            responsavelPassword: Yup.string().required('Informe uma senha'),
            responsavelPasswordConfirmation: Yup.string().oneOf(
              [Yup.ref('responsavelPassword'), null],
              'As senhas precisam ser iguais',
            ),
          });
        }

        const schema = Yup.object().shape(schemaValidation);

        await schema.validate(dataToValidation, {
          abortEarly: false,
        });

        setResponsaveis((prevResponsaveis = []) => {
          if (prevResponsaveis.find((r) => r.cpf === r.prevCpf)) {
            clearDadosResponsavel();
            setValidationErrors((prevState) => ({
              ...prevState,
              responsavelNome: '',
              responsavelNascimento: '',
              responsavelCpf: '',
              responsavelPassword: '',
              responsavelPasswordConfirmation: '',
              responsavelWhatsapp: '',
              cep: '',
              logradouro: '',
              numero: '',
              bairro: '',
              cidade: '',
              estado: '',
            }));

            console.log(prevResponsaveis);
            return prevResponsaveis.map((r) => {
              console.log('🚀 ~ file: index.js:438 ~ returnprevResponsaveis.map ~ r:', r.nome, r.cpf, r.prevCpf, r.cpf === r.prevCpf);
              return r.cpf === r.prevCpf ? {
                ...r,
                nome: responsavelNome,
                cpf: responsavelCpf,
                prevCpf: responsavelCpf,
                rg: responsavelRg,
                nascimento: responsavelNascimento,
                email: responsavelEmail,
                password: responsavelPasswordConfirmation ? responsavelPassword : '',
                whatsapp: responsavelWhatsapp,
                nacionalidade: responsavelNacionalidade,
                estadoCivil: responsavelEstadoCivil,
                profissao: responsavelProfissao,
                endereco: responsavelEnderecoRef.current.getData(),
              } : r;
            });
          }

          clearDadosResponsavel();
          setValidationErrors((prevState) => ({
            ...prevState,
            responsavelNome: '',
            responsavelNascimento: '',
            responsavelCpf: '',
            responsavelPassword: '',
            responsavelPasswordConfirmation: '',
            responsavelWhatsapp: '',
            cep: '',
            logradouro: '',
            numero: '',
            bairro: '',
            cidade: '',
            estado: '',
          }));

          return [
            ...prevResponsaveis,
            {
              nome: responsavelNome,
              cpf: responsavelCpf,
              rg: responsavelRg,
              nascimento: responsavelNascimento,
              email: responsavelEmail,
              password: responsavelPasswordConfirmation ? responsavelPassword : '',
              whatsapp: responsavelWhatsapp,
              nacionalidade: responsavelNacionalidade,
              estadoCivil: responsavelEstadoCivil,
              profissao: responsavelProfissao,
              endereco: responsavelEnderecoRef.current.getData(),
            },
          ];
        });
      } catch (err) {
        console.log(err);
        setValidationErrors((prevState) => ({
          ...prevState,
          responsavelNome: '',
          responsavelNascimento: '',
          responsavelCpf: '',
          responsavelPassword: '',
          responsavelPasswordConfirmation: '',
          responsavelWhatsapp: '',
          cep: '',
          logradouro: '',
          numero: '',
          bairro: '',
          cidade: '',
          estado: '',
        }));
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message: 'Por favor, preencha todos os dados necessários do responsável',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors((prevState) => ({ ...prevState, ...ve }));
        }
      }
    })();
  }, [
    responsavelNome,
    responsavelCpf,
    responsavelRg,
    responsavelNascimento,
    responsavelEmail,
    responsavelPassword,
    responsavelPasswordConfirmation,
    responsavelWhatsapp,
    responsavelNacionalidade,
    responsavelEstadoCivil,
    responsavelProfissao,
    responsavelEnderecoRef,
    validateCPF,
  ]);

  const handleRemoveResponsavel = useCallback((responsavel) => {
    setResponsaveis((prevResponsaveis = []) => (prevResponsaveis.filter((r) => r.cpf !== responsavel.cpf)));
  }, []);

  const handleUpdateResponsavel = useCallback((responsavel) => {
    setResponsavelCreatedAt(responsavel?.createdAt || '');
    setResponsavelNome(responsavel?.nome || '');
    setResponsavelCpf(responsavel?.cpf || '');
    setResponsavelRg(responsavel?.rg || '');
    setResponsavelNascimento(responsavel?.nascimento ? new Date(responsavel?.nascimento) : '');
    setResponsavelEmail(responsavel?.email || '');
    setResponsavelPassword(responsavel?.createdAt ? '' : responsaveis?.password || '');
    setResponsavelPasswordConfirmation(responsavel?.createdAt ? '' : responsaveis?.passwordConfirmation || '');
    setResponsavelWhatsapp(responsavel?.whatsapp || '');
    setResponsavelNacionalidade(responsavel?.nacionalidade || '');
    setResponsavelEstadoCivil(responsavel?.estadoCivil || '');
    setResponsavelProfissao(responsavel?.profissao || '');
    responsavelEnderecoRef.current.setData(responsavel?.endereco || '');
    setDadosResponsavelOpen(true);
    setResponsaveis((prevResponsaveis = []) => prevResponsaveis.map((r) => (r.cpf === responsavel.cpf ? { ...r, prevCpf: responsavel.cpf, originalCpf: r.originalCpf ? r.originalCpf : responsavel.cpf } : { ...r, prevCpf: undefined })));
  }, []);

  const handleAddTabelaValores = useCallback(() => {
    (async () => {
      try {
        const data = {
          tabelaValor: tabelaValor?.value ? tabelaValor.value : '',
          tabelaValores,
        };

        // console.log(data);
        const schema = Yup.object().shape({
          tabelaValor: Yup.string().required('Selecione uma tabela de valores'),
          tabelaValores: Yup.array().length(0, 'É permitido escolher apenas uma tabela de valores, 👍!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setTabelaValor('');
        setTabelaValoresList((prevTabelaValores = []) => prevTabelaValores.filter((e) => e.value !== tabelaValor.value));
        setTabelaValores((prevTabelaValores = []) => [...prevTabelaValores, tabelaValor]);
        setValidationErrors((prevState) => ({
          ...prevState,
          tabelaValor: '',
          tabelaValores: '',
        }));
        setValidationErrorsTabelaValoresCount(0);
      } catch (err) {
        console.log(err);
        setValidationErrors((prevState) => ({
          ...prevState,
          tabelaValor: '',
          tabelaValores: '',
        }));

        if (err instanceof Yup.ValidationError) {
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;

            if (e.path === 'tabelaValores') {
              setTabelaValor('');
            }
          });
          setValidationErrors((prevState) => ({ ...prevState, ...ve }));
        }
      }
    })();
  }, [tabelaValor]);

  const handleRemoveTabelaValores = useCallback((tabelaValorData) => {
    setTabelaValoresList((prevTabelaValores = []) => (prevTabelaValores.find((tv) => tv.value === tabelaValorData.value) ? prevTabelaValores : [...prevTabelaValores, tabelaValorData].sort((a, b) => (a.label > b.label ? 1 : -1))));
    setTabelaValores((prevTabelaValores = []) => prevTabelaValores.filter((e) => e.value !== tabelaValorData.value));
  }, []);

  const handleAddObservacoes = useCallback(() => {
    (async () => {
      try {
        const dataToValidation = {
          observacao,
        };

        const schema = Yup.object().shape({
          observacao: Yup.string().required('Informe esse campo'),
        });

        await schema.validate(dataToValidation, {
          abortEarly: false,
        });

        setObservacoes((prevObservacoes = []) => [
          ...prevObservacoes,
          {
            observacao,
            createdAt: new Date(),
            createdByUser: {
              _id: loggedUser._id,
              name: loggedUser.name,
            },
          },
        ]);

        setObservacao('');
        setValidationErrors((prevState) => ({
          ...prevState,
          observacao: '',
        }));
      } catch (err) {
        console.log(err);
        setValidationErrors((prevState) => ({
          ...prevState,
          observacao: '',
        }));
        if (err instanceof Yup.ValidationError) {
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors((prevState) => ({ ...prevState, ...ve }));
        }
      }
    })();
  }, [observacao, loggedUser]);

  const handleRemoveObservacao = useCallback((index) => {
    setObservacoes((prevObservacoes = []) => (prevObservacoes.filter((h, i) => i !== index)));
  }, []);

  const handleUpdateObservacao = useCallback((o, index) => {
    setObservacao(o.observacao);
    setObservacoes((prevObservacoes = []) => (prevObservacoes.filter((h, i) => i !== index)));
  }, []);

  const handleSave = async () => {
    try {
      const dataToValidation = {
        grupo: grupo?.value ? grupo.value : '',
        criancas,
        responsaveis,
        tabelaValores,
      };

      console.log(dataToValidation);

      const schemaValidation = {
        responsaveis: Yup.array().min(1, 'Adicione pelo menos um cliente responsável'),
        criancas: Yup.array().min(1, 'Adicione pelo menos uma criança'),
        tabelaValores: Yup.array().min(1, 'Selecione pelo menos uma tabela de valores para o cliente'),
      };

      const schema = Yup.object().shape(schemaValidation);

      await schema.validate(dataToValidation, {
        abortEarly: false,
      });

      setLoading(true);

      const data = {
        grupo: grupo?.value ? grupo.value : '',
        adminFaturamento: adminFaturamento?.value ? adminFaturamento.value : '',
        criancas,
        responsaveis,
        observacoes: observacoes.map((o) => ({ ...o, createdByUser: o.createdByUser._id })),
        tabelaValores: tabelaValores.map((t) => t.value),
        criancaDadoEmissaoNFBoleto,
        dataAssinaturaContrato,
        dataEncerramentoContrato,
      };

      if (id) {
        if (responsavelPasswordConfirmation) {
          Object.assign(data, { responsavelPassword });
        }
        update(id, '/users', data, () => {
          setLoading(false);
          setValidationErrors({});
          setValidationErrorsResponsaveisCount(0);
          setValidationErrorsCriancasCount(0);
          setValidationErrorsTabelaValoresCount(0);
          // navigate('/clientes');
        }, () => setLoading(false), false);
        return;
      }

      Object.assign(data, { responsavelPassword });
      store('/users', data, () => {
        setId(data._id);
        setLoading(false);
        setValidationErrors({});
        setValidationErrorsResponsaveisCount(0);
        setValidationErrorsCriancasCount(0);
        setValidationErrorsTabelaValoresCount(0);
        // navigate('/clientes');
      }, () => setLoading(false), false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      setValidationErrors({});
      setValidationErrorsResponsaveisCount(0);
      setValidationErrorsCriancasCount(0);
      setValidationErrorsTabelaValoresCount(0);

      // Validation failed
      if (err instanceof Yup.ValidationError) {
        notificationRef.current.notify({
          message: 'Por favor, preencha todos os dados necessários para salvar',
          color: 'warning',
        });
        const ve = {};
        err.inner.forEach((e) => {
          ve[e.path] = e.message;
        });
        // console.log(Object.keys(ve).length);
        Object.keys(ve).forEach((path) => {
          if (path === 'responsaveis') {
            setValidationErrorsResponsaveisCount((prevState = 0) => prevState + 1);
          }

          if (path === 'criancas') {
            setValidationErrorsCriancasCount((prevState = 0) => prevState + 1);
          }

          if (path === 'tabelaValores') {
            setValidationErrorsTabelaValoresCount((prevState = 0) => prevState + 1);
          }
        });
        setValidationErrors((prevState) => ({ ...prevState, ...ve }));
        return;
      }

      if (err && err.response && err.response.data) {
        const { message } = err.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });

        return;
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível salvar',
        color: 'danger',
      });
    }
  };

  return (
    <Container fluid>
      <Row className="my-5">
        <Col>
          <Notification ref={notificationRef} />
          <Card className="shadow">
            <CardBody>
              <h3>
                {`${
                  id
                    ? 'Alterar Cliente'
                    : 'Cadastro de Cliente'
                }`}
              </h3>
            </CardBody>
          </Card>
          <Form>
            <Row>
              <Col>
                <Button color="primary" id="toggler-grupo" block>
                  Grupo
                </Button>
                <UncontrolledCollapse toggler="#toggler-grupo">
                  <Card>
                    <CardBody>
                      <Row className="my-3">
                        <Col>
                          <CustomSelect
                            label="Grupo"
                            placeholder="Selecione um grupo"
                            value={grupo}
                            options={grupos}
                            onChange={setGrupo}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Button color="primary" id="toggler-responsaveis" block>
                  Dados dos Responsáveis
                  <ValidationErrorsCount
                    count={validationErrorsResponsaveisCount}
                  />
                </Button>
                <UncontrolledCollapse toggler="#toggler-responsaveis">
                  <Card>
                    <CardBody>
                      <p className="text-danger">
                        {validationErrors.responsaveis}
                      </p>
                      {!dadosResponsavelOpen && (
                      <Button
                        className="mb-4"
                        color="success"
                        onClick={() => setDadosResponsavelOpen(true)}
                      >
                        Adicionar
                      </Button>
                      )}
                      <Collapse isOpen={dadosResponsavelOpen}>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for="responsavel-nome">
                                Nome Completo do Responsável
                              </Label>
                              <Input
                                id="responsavel-nome"
                                type="text"
                                placeholder="Nome Completo do Responsável"
                                value={responsavelNome}
                                invalid={!!validationErrors.responsavelNome}
                                onChange={({ target: { value } }) => setResponsavelNome(value)}
                              />
                              <FormFeedback>
                                {validationErrors.responsavelNome}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} sm={4}>
                            <FormGroup>
                              <Label for="responsavel-cpf">CPF</Label>
                              <PatternFormat
                                customInput={Input}
                                id="responsavel-cpf"
                                format="###.###.###-##"
                                mask="_"
                                invalid={!!validationErrors.responsavelCpf}
                                valueIsNumericString
                                type="text"
                                placeholder="CPF"
                                value={responsavelCpf}
                                onValueChange={({ value }) => setResponsavelCpf(value)}
                              />
                              <FormFeedback>
                                {validationErrors.responsavelCpf}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col xs={12} sm={4}>
                            <InputMask
                              mask="99.999.999-**"
                              value={responsavelRg}
                              onChange={({ target: { value } }) => setResponsavelRg(value)}
                            >
                              {(inputProps) => (
                                <FormGroup>
                                  <Label for="responsavel-rg">RG</Label>
                                  <Input
                                    id="responsavel-rg"
                                    type="text"
                                    placeholder="RG"
                                    invalid={!!validationErrors.responsavelRg}
                                    {...inputProps}
                                  />
                                  <FormFeedback>
                                    {validationErrors.responsavelRg}
                                  </FormFeedback>
                                </FormGroup>
                              )}
                            </InputMask>
                          </Col>
                          <Col xs={12} sm={4}>
                            <DatePickerMaskedInput
                              label="Nascimento"
                              placeholderText="Nascimento"
                              selected={responsavelNascimento}
                              invalidMessage={validationErrors.responsavelNascimento}
                              yearsRange={range(
                                1900,
                                getYear(new Date()) + 1,
                                1,
                              )}
                              maxDate={new Date()}
                              openToDate={new Date('2000/01/01')}
                              onChange={(date) => setResponsavelNascimento(date)}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} sm={6}>
                            <FormGroup>
                              <Label for="responsavel-whatsapp">Whatsapp</Label>
                              <PatternFormat
                                customInput={Input}
                                id="responsavel-whatsapp"
                                format="(##) #####-####"
                                mask="_"
                                valueIsNumericString
                                type="text"
                                invalid={!!validationErrors.responsavelWhatsapp}
                                placeholder="Whatsapp"
                                value={responsavelWhatsapp}
                                onValueChange={({ value }) => setResponsavelWhatsapp(value)}
                              />
                              <FormFeedback>
                                {validationErrors.responsavelWhatsapp}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} sm={6}>
                            <FormGroup>
                              <Label for="responsavel-nacionalidade">
                                Nacionalidade
                              </Label>
                              <Input
                                id="responsavel-nacionalidade"
                                type="text"
                                placeholder="Nacionalidade"
                                value={responsavelNacionalidade}
                                invalid={!!validationErrors.responsavelNacionalidade}
                                onChange={({ target: { value } }) => setResponsavelNacionalidade(value)}
                              />
                              <FormFeedback>
                                {validationErrors.responsavelNacionalidade}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <FormGroup>
                              <Label for="responsavel-estado-civil">Estado Civil</Label>
                              <Input
                                id="responsavel-estado-civil"
                                type="select"
                                placeholder="responsavel-estado-civil"
                                value={responsavelEstadoCivil}
                                invalid={!!validationErrors.responsavelEstadoCivil}
                                onChange={({ target: { value } }) => setResponsavelEstadoCivil(value)}
                              >
                                <option value="SOLTEIRO">SOLTEIRO</option>
                                <option value="CASADO">CASADO</option>
                                <option value="DIVORCIADO">DIVORCIADO</option>
                                <option value="VIÚVO">VIÚVO</option>
                                <option value="OUTRO">OUTRO</option>
                              </Input>
                              <FormFeedback>
                                {validationErrors.responsavelEstadoCivil}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for="responsavel-profissao">
                                Profissão
                              </Label>
                              <Input
                                id="responsavel-profissao"
                                type="text"
                                placeholder="Profissão"
                                value={responsavelProfissao}
                                invalid={!!validationErrors.responsavelProfissao}
                                onChange={({ target: { value } }) => setResponsavelProfissao(value)}
                              />
                              <FormFeedback>
                                {validationErrors.responsavelProfissao}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Endereco
                              id="responsavel-endereco"
                              ref={responsavelEnderecoRef}
                              validationErrors={validationErrors}
                              onValidationErrors={setValidationErrors}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Card>
                              <CardBody>
                                <CardHeader>
                                  Dados de Login
                                </CardHeader>
                                <Row className="mb-3">
                                  <Col>
                                    <h6>
                                      Cadastro de Email e Senha para Login
                                    </h6>
                                    {/* <small>
                                        O cliente receberá um email com os dados de login
                                      </small> */}
                                  </Col>
                                </Row>
                                <FormGroup>
                                  <Label for="responsavel-email">
                                    Email para Login no Aplicativo e Contato
                                  </Label>
                                  <Input
                                    id="responsavel-email"
                                    type="email"
                                    placeholder="Email"
                                    value={responsavelEmail}
                                    invalid={!!validationErrors.responsavelEmail}
                                    onChange={({ target: { value } }) => setResponsavelEmail(value)}
                                  />
                                  <FormFeedback>{validationErrors.responsavelEmail}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                  <Label for="responsavelPassword">{responsavelCreatedAt ? 'Trocar Senha' : 'Cadastrar Senha'}</Label>
                                  <Input
                                    id="responsavelPassword"
                                    type="password"
                                    placeholder={responsavelCreatedAt ? 'Caso deseja trocar de senha informe a nova senha' : 'Cadastrar Senha'}
                                    value={responsavelPassword}
                                    invalid={!!validationErrors.responsavelPassword}
                                    onChange={({ target: { value } }) => setResponsavelPassword(value)}
                                  />
                                  <FormFeedback>
                                    {validationErrors.responsavelPassword}
                                  </FormFeedback>
                                </FormGroup>
                                <FormGroup className="mb-5">
                                  <Label for="responsavelPassword-confirmation">
                                    Repetir Senha
                                  </Label>
                                  <Input
                                    id="responsavelPassword-confirmation"
                                    type="password"
                                    placeholder="Repetir Senha"
                                    value={responsavelPasswordConfirmation}
                                    invalid={
                                        !!validationErrors.responsavelPasswordConfirmation
                                      }
                                    onChange={({ target: { value } }) => setResponsavelPasswordConfirmation(value)}
                                  />
                                  <FormFeedback>
                                    {validationErrors.responsavelPasswordConfirmation}
                                  </FormFeedback>
                                </FormGroup>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        <Row className="mt-2 mb-4">
                          <Col xs={12} sm={6}>
                            <Button
                              color="success"
                              onClick={handleAddResponsavel}
                              block
                            >
                              {responsavelCreatedAt ? (
                                <>
                                  Alterar
                                  <i className="ml-2 fa-regular fa-pen-to-square text-white" />
                                </>
                              ) : (
                                <>
                                  Adicionar
                                  <i className="ml-2 fa-solid fa-circle-plus text-white" />
                                </>
                              )}
                            </Button>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Button
                              color="danger"
                              onClick={clearDadosResponsavel}
                              block
                            >
                              Cancelar
                              <i className="ml-2 fa-solid fa-circle-xmark text-white" />
                            </Button>
                          </Col>
                        </Row>
                      </Collapse>
                      <Row>
                        <Col>
                          {responsaveis.map((r, index) => (
                            <Row className="mt-2" key={`${r.cpf}-${index}`}>
                              <Col xs={12} sm={5}>
                                {r.nome}
                              </Col>
                              <Col xs={12} sm={2}>
                                {r.nascimento ? format(
                                  new Date(r.nascimento),
                                  'dd/MM/yyyy',
                                  { locale: ptBR },
                                ) : null}
                              </Col>
                              <Col xs={12} sm={3}>
                                <PatternFormat
                                  format="###.###.###-##"
                                  value={r.cpf}
                                  displayType="text"
                                  renderText={(formattedValue) => formattedValue}
                                />
                              </Col>
                              <Col xs={12} sm={2}>
                                <Button
                                  className="mr-2"
                                  color="primary"
                                  onClick={() => handleUpdateResponsavel(r)}
                                >
                                  <i className="fa-solid fa-edit text-white" />
                                </Button>
                                <Button
                                  color="danger"
                                  onClick={() => handleRemoveResponsavel(r)}
                                >
                                  <i className="fa-solid fa-trash-can text-white" />
                                </Button>
                              </Col>
                            </Row>
                          ))}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Button color="primary" id="toggler-criancas" block>
                  Dados das Crianças
                  <ValidationErrorsCount
                    count={validationErrorsCriancasCount}
                  />
                </Button>
                <UncontrolledCollapse toggler="#toggler-criancas">
                  <Card>
                    <CardBody>
                      <p className="text-danger">
                        {validationErrors.criancas}
                      </p>
                      {!dadosCriancasOpen && (
                      <Button
                        className="mb-5"
                        color="success"
                        onClick={() => setDadosCriancasOpen((prevState) => !prevState)}
                      >
                        Adicionar
                      </Button>
                      )}
                      <Collapse isOpen={dadosCriancasOpen}>
                        <Card>
                          <CardBody>
                            <Row>
                              <Col>
                                <CustomSelect
                                  label="Supervisor"
                                  placeholder="Selecione um Supervisor"
                                  value={supervisor}
                                  options={supervisores}
                                  onChange={setSupervisor}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <CustomSelect
                                  label="Responsável por Faturamento"
                                  placeholder="Selecione um Admin"
                                  value={adminFaturamento}
                                  options={adminsFaturamento}
                                  onChange={setAdminFaturamento}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label for="crianca-nome">
                                    Nome Completo da Criança
                                  </Label>
                                  <Input
                                    id="crianca-nome"
                                    type="text"
                                    placeholder="Nome Completo da Criança"
                                    value={criancaNome}
                                    invalid={!!validationErrors.criancaNome}
                                    onChange={({ target: { value } }) => setCriancaNome(value)}
                                  />
                                  <FormFeedback>
                                    {validationErrors.criancaNome}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} sm={4}>
                                <FormGroup>
                                  <Label for="crianca-cpf">CPF</Label>
                                  <PatternFormat
                                    customInput={Input}
                                    id="crianca-cpf"
                                    format="###.###.###-##"
                                    mask="_"
                                    invalid={!!validationErrors.criancaCpf}
                                    valueIsNumericString
                                    type="text"
                                    placeholder="CPF"
                                    value={criancaCpf}
                                    onValueChange={({ value }) => setCriancaCpf(value)}
                                    onBlur={({ target: { value } }) => onBlurCriancaDadoEmissaoNfBoleto(value)}
                                    onFocus={({ target: { value } }) => onFocusCriancaDadoEmissaoNfBoleto(value)}
                                  />
                                  <FormFeedback>
                                    {validationErrors.criancaCpf}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col xs={12} sm={4}>
                                <InputMask
                                  mask="99.999.999-**"
                                  value={criancaRg}
                                  onChange={({ target: { value } }) => setCriancaRg(value)}
                                >
                                  {(inputProps) => (
                                    <FormGroup>
                                      <Label for="crianca-rg">RG</Label>
                                      <Input
                                        id="crianca-rg"
                                        type="text"
                                        placeholder="RG"
                                        invalid={!!validationErrors.criancaRg}
                                        {...inputProps}
                                      />
                                      <FormFeedback>
                                        {validationErrors.criancaRg}
                                      </FormFeedback>
                                    </FormGroup>
                                  )}
                                </InputMask>
                              </Col>
                              <Col xs={12} sm={4}>
                                <DatePickerMaskedInput
                                  label="Nascimento"
                                  placeholderText="Nascimento"
                                  selected={criancaNascimento}
                                  invalidMessage={validationErrors.criancaNascimento}
                                  yearsRange={range(
                                    1900,
                                    getYear(new Date()) + 1,
                                    1,
                                  )}
                                  maxDate={new Date()}
                                  openToDate={subYears(new Date(), 8)}
                                  onChange={(date) => setCriancaNascimento(date)}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Card>
                                  <CardHeader tag="h6">
                                    Dados para emissão da nota fiscal e boleto
                                  </CardHeader>
                                  <CardBody>
                                    <Row>
                                      <Col>
                                        <p className="text-danger">
                                          {validationErrors.criancaDadoEmissaoNFBoleto}
                                        </p>
                                        {responsaveis.map((r, index) => (
                                          <FormGroup key={`${r.cpf}-${index}`} className="mt-3" check>
                                            <Input
                                              type="radio"
                                              checked={criancaDadoEmissaoNFBoleto === r.cpf}
                                              onChange={() => setCriancaDadoEmissaoNFBoleto(r.cpf)}
                                            />
                                            <Label check>{`CPF ${r.cpf} responsável ${index + 1}`}</Label>
                                          </FormGroup>
                                        ))}
                                        {criancasDadoEmissaoNFBoleto.map((c, index) => (
                                          <FormGroup key={`${c.cpf}-${index}`} className="mt-4" check>
                                            <Input
                                              type="radio"
                                              checked={criancaDadoEmissaoNFBoleto === c.cpf}
                                              onChange={() => setCriancaDadoEmissaoNFBoleto(c.cpf)}
                                            />
                                            <Label check>{`CPF ${c.cpf} criança ${index + 1}`}</Label>
                                          </FormGroup>
                                        ))}
                                        <Row className="mt-4">
                                          <Col tag="div" className="d-flex flex-wrap align-items-center">
                                            <FormGroup check>
                                              <Input
                                                type="radio"
                                                checked={criancaDadoEmissaoNFBoleto === 'CNPJPLANOSAUDE'}
                                                onChange={() => {
                                                  setCriancaDadoEmissaoNFBoletoValue('');
                                                  setCriancaDadoEmissaoNFBoleto('CNPJPLANOSAUDE');
                                                }}
                                              />
                                              <Label className="text-nowrap" check>CNPJ do plano de saúde</Label>
                                            </FormGroup>
                                            {criancaDadoEmissaoNFBoleto === 'CNPJPLANOSAUDE' && (
                                              <>
                                                <CustomMaskedInput
                                                  id="cnpjplanosaude"
                                                  type="text"
                                                  className="mx-2"
                                                  placeholder="CNPJ"
                                                  format="##.###.###/####-##"
                                                  style={{ width: '100%', maxWidth: '300px' }}
                                                  value={criancaDadoEmissaoNFBoletoValue?.split('#-#')?.[0] || ''}
                                                  withFormGroup={false}
                                                  onValueChange={({ value: v }) => setCriancaDadoEmissaoNFBoletoValue((prevState) => (prevState.includes('#-#') ? `${prevState.split('#-#')[0]}${v}#-#${prevState.split('#-#')[1]}` : `${v}`))}
                                                />
                                                <Input
                                                  id="cnpjplanosaude-nome"
                                                  type="text"
                                                  className="mx-2"
                                                  placeholder="Nome do Plano de Saúde"
                                                  style={{ width: '100%', maxWidth: '300px' }}
                                                  value={criancaDadoEmissaoNFBoletoValue?.split('#-#')?.[1] || ''}
                                                  onChange={({ target: { value } }) => setCriancaDadoEmissaoNFBoletoValue((prevState) => (prevState.includes('#-#') ? `${prevState.split('#-#')[0]}#-#${value}` : `${prevState}#-#${value}`))}
                                                />
                                              </>
                                            )}
                                          </Col>
                                        </Row>
                                        <Row className="mt-4">
                                          <Col tag="div" className="d-flex flex-wrap align-items-center">
                                            <FormGroup check>
                                              <Input
                                                type="checkbox"
                                                checked={criancaReembolso}
                                                onChange={() => setCriancaReembolso((prevState) => !prevState)}
                                              />
                                              <Label className="text-nowrap" check>Reembolso</Label>
                                            </FormGroup>
                                            {criancaReembolso && (
                                              <>
                                                <CustomMaskedInput
                                                  id="reembolsoplanosaude"
                                                  type="text"
                                                  className="mx-2"
                                                  placeholder="CNPJ"
                                                  format="##.###.###/####-##"
                                                  style={{ width: '100%', maxWidth: '300px' }}
                                                  value={criancaReembolsoValue?.split('#-#')?.[0] || ''}
                                                  withFormGroup={false}
                                                  onValueChange={({ value: v }) => setCriancaReembolsoValue((prevState) => (prevState.includes('#-#') ? `${prevState.split('#-#')[0]}${v}#-#${prevState.split('#-#')[1]}` : `${v}`))}
                                                />
                                                <Input
                                                  id="reembolsoplanosaude-nome"
                                                  type="text"
                                                  className="mx-2"
                                                  placeholder="Nome do Plano de Saúde"
                                                  style={{ width: '100%', maxWidth: '300px' }}
                                                  value={criancaReembolsoValue?.split('#-#')?.[1] || ''}
                                                  onChange={({ target: { value } }) => setCriancaReembolsoValue((prevState) => (prevState.includes('#-#') ? `${prevState.split('#-#')[0]}#-#${value}` : `${prevState}#-#${value}`))}
                                                />
                                              </>
                                            )}
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Endereco
                                  id="crianca-endereco"
                                  ref={criancaEnderecoRef}
                                  validationErrors={validationErrors}
                                  onValidationErrors={setValidationErrors}
                                />
                              </Col>
                            </Row>
                            <Row className="mt-2 mb-4">
                              <Col xs={12} sm={6}>
                                <Button
                                  color="success"
                                  onClick={handleAddCrianca}
                                  block
                                >
                                  {criancaCreatedAt ? (
                                    <>
                                      Alterar
                                      <i className="ml-2 fa-regular fa-pen-to-square text-white" />
                                    </>
                                  ) : (
                                    <>
                                      Adicionar
                                      <i className="ml-2 fa-solid fa-circle-plus text-white" />
                                    </>
                                  )}
                                </Button>
                              </Col>
                              <Col xs={12} sm={6}>
                                <Button
                                  color="danger"
                                  onClick={clearDadosCrianca}
                                  block
                                >
                                  Cancelar
                                  <i className="ml-2 fa-solid fa-circle-xmark text-white" />
                                </Button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Collapse>
                      <Row>
                        <Col>
                          {criancas.map((c, index) => (
                            <Row className="mt-2" key={`${c.cpf}-${index}`}>
                              <Col xs={12} sm={5}>
                                {c.nome}
                              </Col>
                              <Col xs={12} sm={2}>
                                {format(
                                  new Date(c.nascimento),
                                  'dd/MM/yyyy',
                                  { locale: ptBR },
                                )}
                              </Col>
                              <Col xs={12} sm={3}>
                                <PatternFormat
                                  format="###.###.###-##"
                                  value={c.cpf}
                                  displayType="text"
                                  renderText={(formattedValue) => formattedValue}
                                />
                              </Col>
                              <Col xs={12} sm={2}>
                                <Button
                                  className="mr-2"
                                  color="primary"
                                  onClick={() => handleUpdateCrianca(c)}
                                >
                                  <i className="fa-solid fa-edit text-white" />
                                </Button>
                                <Button
                                  color="danger"
                                  onClick={() => handleRemoveCrianca(c)}
                                >
                                  <i className="fa-solid fa-trash-can text-white" />
                                </Button>
                              </Col>
                            </Row>
                          ))}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Button color="primary" id="toggler-valores-atividades" block>
                  Area para definir os valores das atividades
                  <ValidationErrorsCount
                    count={validationErrorsTabelaValoresCount}
                  />
                </Button>
                <UncontrolledCollapse toggler="#toggler-valores-atividades">
                  <Card>
                    <CardBody>
                      <p className="text-danger">
                        {validationErrors.tabelaValores}
                      </p>
                      <TabelaValores
                        ref={tabelaValoresRef}
                        onSave={setNewTabelaValor}
                      />
                      <Row>
                        <Col xs={9}>
                          <Select
                            placeholder="Selecione uma Tabela de Valores"
                            value={tabelaValor}
                            options={tabelaValoresList}
                            invalidMessage={validationErrors.tabelaValor}
                            isLoading={loadingTabelaValores}
                            onChange={setTabelaValor}
                          />
                        </Col>
                        <Col xs={1} className="pl-0">
                          <Button
                            color="success"
                            onClick={handleAddTabelaValores}
                          >
                            <i className="fa-solid fa-circle-plus text-white" />
                          </Button>
                        </Col>
                        <Col xs={2}>
                          <Button
                            id="new-tabela-valores"
                            className="text-white"
                            color="success"
                            onClick={() => tabelaValoresRef.current.show(null, false)}
                            block
                          >
                            <i className="fa-solid fa-circle-plus text-white mr-2" />
                            Cadastrar nova Tabela de Valores
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {tabelaValores.length ? (
                            tabelaValores
                              .sort((a, b) => (a.label > b.label ? 1 : -1))
                              .map((t, index) => (
                                <Row
                                  className="mb-2"
                                  key={`tabelaValores-${index}`}
                                >
                                  <Col xs={10}>{t.label}</Col>
                                  <Col xs={2}>
                                    <Button
                                      color="danger"
                                      onClick={() => handleRemoveTabelaValores(t)}
                                      block
                                    >
                                      <i className="fa-solid fa-trash-can text-white" />
                                    </Button>
                                  </Col>
                                </Row>
                              ))
                          ) : (
                            <p className="text-center">
                              Adicione uma tabela de valores
                            </p>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </Col>
            </Row>
            {id && (
            <Row className="mt-3">
              <Col>
                <Button
                  color="primary"
                  id="toggler-documentos-anexados"
                  block
                >
                  Documento(s) anexado(s)
                </Button>
                <UncontrolledCollapse toggler="#toggler-documentos-anexados">
                  <Card>
                    <CardBody>
                      <UploadFiles
                        accept={{
                          'image/jpeg': [],
                          'image/png': [],
                          'application/pdf': [],
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
                          'application/vnd.ms-excel.sheet.binary.macroEnabled.12': [],
                          'application/vnd.ms-excel': [],
                          'application/vnd.ms-excel.sheet.macroEnabled.12': [],
                          'application/msword': [],
                          'application/vnd.ms-word.document.macroEnabled.12': [],
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.template': [],
                          'text/csv': [],
                        }}
                        height="150px"
                        url="/clienteDocumentoAnexos"
                        parentId={id}
                        initialFiles={documentosAnexados}
                      />
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </Col>
            </Row>
            )}
            <Row className="mt-3">
              <Col>
                <Button color="primary" id="toggler-observacoes" block>
                  Observações
                </Button>
                <UncontrolledCollapse toggler="#toggler-observacoes">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xs={10}>
                          <FormGroup>
                            <Label for="observacao">Observaçao</Label>
                            <Input
                              id="observacao"
                              type="textarea"
                              placeholder="Observação"
                              value={observacao}
                              invalid={!!validationErrors.observacao}
                              onChange={({ target: { value } }) => setObservacao(value)}
                            />
                            <FormFeedback>
                              {validationErrors.observacao}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col
                          xs={2}
                          style={{
                            paddingTop: '2rem',
                          }}
                        >
                          <Button
                            color="success"
                            onClick={handleAddObservacoes}
                            block
                          >
                            <i className="fa-solid fa-circle-plus text-white" />
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {observacoes.length ? (
                            observacoes.map((o, index) => (
                              <Row
                                key={`observacao-${index}`}
                                className="mt-2"
                              >
                                <Col xs={6}>{o.observacao}</Col>
                                <Col xs={2}>
                                  {format(
                                    o.createdAt,
                                    'dd/MM/yyyy HH:mm:ss',
                                    { locale: ptBR },
                                  )}
                                </Col>
                                <Col xs={2}>{o.createdByUser.name}</Col>
                                <Col xs={2}>
                                  <Button
                                    className="mr-2"
                                    color="primary"
                                    onClick={() => handleUpdateObservacao(o, index)}
                                  >
                                    <i className="fa-solid fa-edit text-white" />
                                  </Button>
                                  <Button
                                    color="danger"
                                    onClick={() => handleRemoveObservacao(index)}
                                  >
                                    <i className="fa-solid fa-trash-can text-white" />
                                  </Button>
                                </Col>
                              </Row>
                            ))
                          ) : (
                            <div className="d-flex justify-content-center">
                              Sem Observações
                            </div>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </Col>
            </Row>
            {solicitationDeleteAccount && (
              <Row className="mt-3">
                <Col>
                  <Alert color="danger">
                    <Row>
                      <Col>
                        <strong>{`Cliente com email ${solicitationDeleteAccount.email} solicitou exclusão da conta`}</strong>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <i className="fa-solid fa-hand text-danger mr-3" style={{ fontSize: '1.75rem' }} />
                        {solicitationDeleteAccount?.description ? `Motivo - ${solicitationDeleteAccount.description}` : 'Motivo não informado'}
                      </Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
            )}
            <Row className="mt-3">
              <Col xs={12} sm={6}>
                <DatePickerMaskedInput
                  label="Data da Assinatura do Contrato"
                  placeholderText="Data da Assinatura do Contrato"
                  selected={dataAssinaturaContrato}
                  invalidMessage={validationErrors.dataAssinaturaContrato}
                  onChange={(date) => setDataAssinaturaContrato(date)}
                />
              </Col>
              <Col xs={12} sm={6}>
                <DatePickerMaskedInput
                  label="Data de Encerramento do Contrato"
                  placeholderText="Data de Encerramento do Contrato"
                  selected={dataEncerramentoContrato}
                  invalidMessage={validationErrors.dataEncerramentoContrato}
                  onChange={(date) => setDataEncerramentoContrato(date)}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <Button color="success" onClick={handleSave} block>
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Cliente;
