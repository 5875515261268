import React, { useState, forwardRef, useImperativeHandle } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';

function CustomModal(_props, ref) {
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState({});

  useImperativeHandle(ref, () => ({
    show: (data) => {
      setModalData(data);
      setShow(true);
    },
    close: () => setShow(false),
  }));

  const { title, content: ModalContent, actions: ModalActions } = modalData;

  return (
    <Modal
      isOpen={show}
      onClose={() => setShow(false)}
      centered
    >
      <ModalHeader>
        <strong>{title}</strong>
        <Button
          className="close position-absolute top-4 right-4 py-1 px-2"
          onClick={() => setShow(false)}
        >
          <i className="fas fa-times" />
        </Button>
      </ModalHeader>
      <ModalBody>
        {ModalContent && <ModalContent />}
      </ModalBody>
      <ModalFooter>
        {ModalActions && <ModalActions />}
      </ModalFooter>
    </Modal>
  );
}

export default forwardRef(CustomModal);
