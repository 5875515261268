import React from 'react';

function Divider({ border, color, width }) {
  return (
    <hr
      style={{
        border: `${border || '1'}px solid var(--${color || 'dark'})`,
        maxWidth: width || '200px',
        width: width || '100%',
        backgroundColor: `var(--${color})` || 'var(--dark)',
      }}
    />
  );
}

export default Divider;
