/* eslint-disable max-len */
import React, { useEffect, useState, useCallback, useMemo, useRef, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';

import CustomMaskedInput from '../../components/CustomMaskedInput';
import CustomSelect from '../../components/CustomSelect';
import Notification from '../../components/Notification';
import TableManager from '../../components/TableManager';
import { AppContext } from '../../contexts/app';
import { useManager } from '../../hooks/manager';
import api from '../../services/api';
import { schema } from './schemaValidations';

function Fornecedores() {
  const notificationRef = useRef();
  const { loading, setLoading } = useContext(AppContext);
  const { load, destroy, update, store } = useManager();
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState();
  const [grupos, setGrupos] = useState([]);
  const { control, setValue, reset, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    load('/fornecedores', 1, []);
  }, [load]);

  useEffect(() => {
    (async () => {
      try {
        const { data = [] } = await api.get('/grupos?search=[{"field":"tipo","value":"fornecedor"}]&limit=0');
        // console.log(data);
        setGrupos(data.map((d) => ({
          value: d._id,
          label: d.nome,
        })));
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const resetForm = useCallback(() => {
    reset({
      nome: '',
      nomeFantasia: '',
      cnpj: '',
      grupo: '',
    });
  }, []);

  const handleUpdate = useCallback((data) => {
    console.log(data);
    setId(data._id);
    setValue('nome', data.nome);
    setValue('nomeFantasia', data.nomeFantasia);
    setValue('cnpj', data.cnpj);
    setValue('grupo', data.grupo ? { value: data.grupo._id, label: data.grupo.nome } : null);
    setShowModal(true);
  }, []);

  const handleCreate = useCallback(() => {
    setId(null);
    resetForm();
    setShowModal(true);
  }, []);

  const handleCreatePdf = useCallback((selectedRows = []) => {
    (async () => {
      try {
        setLoading({
          loading: true,
          message: 'Aguarde... Estamos gerando o arquivo PDF... ',
        });

        // await fetch api
        const res = await api.get(`/fornecedorPDFCreatorReportLists?fornecedoresIds=${JSON.stringify(selectedRows.map(({ _id }) => _id))}`, { responseType: 'blob' });
        const fileName = res.headers['content-disposition']
          .replace('attachment; filename=', '')
          .replace('"', '');

        const fileURL = window.URL.createObjectURL(res.data);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.target = '_blank';
        alink.rel = 'noopener noreferrer';
        alink.download = fileName;
        alink.click();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao realizar ao gerar relatório',
          color: 'danger',
        });
      }
    })();
  }, [setLoading]);

  const handleCreateCsv = useCallback((selectedRows) => {
    (async () => {
      try {
        setLoading({
          loading: true,
          message: 'Aguarde... Estamos gerando o arquivo CSV... ',
        });

        // await fetch api
        const res = await api.get(`/fornecedorCSVCreatorReportLists?fornecedoresIds=${JSON.stringify(selectedRows.map(({ _id }) => _id))}`, { responseType: 'blob' });
        const fileName = res.headers['content-disposition']
          .replace('attachment; filename=', '')
          .replace('"', '');

        const fileURL = window.URL.createObjectURL(res.data);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.target = '_blank';
        alink.rel = 'noopener noreferrer';
        alink.download = fileName;
        alink.click();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao realizar ao gerar relatório',
          color: 'danger',
        });
      }
    })();
  }, [setLoading]);

  const onSubmit = useCallback((formValues) => {
    try {
      const { nome, nomeFantasia, cnpj, grupo } = formValues;
      const data = {
        nome,
        nomeFantasia,
        cnpj,
        grupo: grupo.value,
      };

      if (id) {
        update(id, '/fornecedores', data, () => {
          setLoading(false);
          setShowModal(false);
          resetForm();
        }, () => setLoading(false));
        return;
      }

      store('/fornecedores', data, () => {
        setLoading(false);
        setShowModal(false);
        resetForm();
      }, () => setLoading(false));
    } catch (err) {
      setLoading(false);
      console.log(err);

      if (err && err.response && err.response.data) {
        const { message } = err.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });

        return;
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível salvar',
        color: 'danger',
      });
    }
  }, [reset, id]);

  const grupoFormatter = useCallback((row) => {
    // console.log(row);
    if (row?.grupo?.nome) {
      return (
        <div
          className={classNames('w-100 badge', { 'text-white': !!row?.grupo?.color || row?.grupo?.color !== '#ffffff' })}
          style={{ backgroundColor: row?.grupo?.color ? `${row.grupo.color}` : 'var(--white)' }}
        >
          <i className="fa-solid fa-circle mr-2" />
          {row.grupo.nome}
        </div>
      );
    }

    return (
      <Badge className="w-100" tag="div">
        <i className="fa-solid fa-exclamation-circle mr-2" />
        Sem grupo
      </Badge>
    );
  }, []);

  const cnpjFormatter = useCallback((row) => (
    <PatternFormat
      format="##.###.###/####-##"
      value={row.cnpj}
      displayType="text"
      renderText={(formattedValue) => formattedValue}
    />
  ), []);

  const columns = useMemo(() => [
    {
      id: 'grupo',
      dataField: 'grupo.nome',
      text: 'Grupo',
      formatter: grupoFormatter,
    },
    {
      dataField: 'nome',
      text: 'Razão Social',
      textFilter: true,
      width: '100%',
    },
    {
      dataField: 'nomeFantasia',
      text: 'Nome Fantasia',
      textFilter: true,
      width: '300px',
    },
    {
      dataField: 'cnpj',
      text: 'CNPJ',
      textFilter: true,
      formatter: cnpjFormatter,
      width: '180px',
    },
  ]);

  return (
    <Container fluid>
      <Row className="mt-5">
        <Col>
          <Notification ref={notificationRef} />
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h3>Fornecedores</h3>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col xs={12} sm={5} md={3} className="mb-3 mb-xl-0">
                  <Button block color="success" onClick={handleCreate}>
                    Cadastrar
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableManager
            initialColumns={columns}
            onUpdate={(row) => handleUpdate(row)}
            onDelete={(row) => destroy(row._id, '/fornecedores')}
            onCreatePdf={handleCreatePdf}
            onCreateCsv={handleCreateCsv}
            selectable
            enableDeletedItemsButton
          />
        </Col>
      </Row>
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        size="lg"
        centered
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>
            <strong>Fornecedor</strong>
            <Button
              className="close position-absolute top-4 right-4 py-1 px-2"
              onClick={() => setShowModal(false)}
            >
              <i className="fas fa-times" />
            </Button>
          </ModalHeader>
          <ModalBody>
            <Controller
              name="grupo"
              control={control}
              render={({ field }) => (
                <CustomSelect
                  label="Grupo"
                  placeholder="Selecione um grupo"
                  // invalidMessage={errors?.grupo?.value?.message || errors?.grupo?.message}
                  {...field}
                  options={grupos}
                />
              )}
            />
            <Controller
              name="nome"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <FormGroup>
                  <Label for="nome">Razão Social</Label>
                  <Input
                    id="nome"
                    type="text"
                    placeholder="Razão Social"
                    name={name}
                    value={value}
                    onChange={onChange}
                    invalid={!!errors?.nome?.message}
                  />
                  <FormFeedback>{errors?.nome?.message}</FormFeedback>
                </FormGroup>
              )}
            />
            <Controller
              name="nomeFantasia"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <FormGroup>
                  <Label for="nomeFantasia">Nome Fantasia</Label>
                  <Input
                    id="nomeFantasia"
                    type="text"
                    placeholder="Nome Fantasia"
                    name={name}
                    value={value}
                    onChange={onChange}
                    invalid={!!errors?.nomeFantasia?.message}
                  />
                  <FormFeedback>{errors?.nomeFantasia?.message}</FormFeedback>
                </FormGroup>
              )}
            />
            <Controller
              name="cnpj"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <CustomMaskedInput
                  id="cnpj"
                  label="CNPJ"
                  placeholder="Informe um CNPJ"
                  format="##.###.###/####-##"
                  name={name}
                  value={value}
                  onValueChange={({ value: v }) => onChange(v)}
                  invalidMessage={errors?.cnpj?.message}
                />
              )}
            />
          </ModalBody>
          <ModalFooter tag="div" className="d-flex justify-content-between">
            <Button color="warning" onClick={() => resetForm()} disabled={loading}>Limpar</Button>
            <div className="d-flex">
              <Button type="submit" className="mr-2" color="success" disabled={loading}>Salvar</Button>
              <Button color="danger" onClick={() => setShowModal(false)} disabled={loading}>Cancelar</Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </Container>
  );
}

export default Fornecedores;
