import brasilApi from './apiBrasil';
import brasilAbertoApi from './apiBrasilAberto';
import viaCepApi from './apiViaCep';

export async function consultaCep(value) {
  try {
    // Verifica se campo cep possui valor informado.
    if (value !== '') {
      // Expressão regular para validar o CEP.
      const validacep = /^[0-9]{8}$/;

      // Valida o formato do CEP.
      if (validacep.test(value)) {
        try {
          const { data: { street, neighborhood, location: locationData, state, city } } = await brasilApi.get(`/cep/v1/${value}`);
          console.log('🚀 ~ consultaCep ~ by brasil api ~ street, neighborhood, location: locationData, state, city:', street, neighborhood, locationData, state, city);
          return {
            street,
            neighborhood,
            location: locationData,
            state,
            city,
          };
        } catch (brasilApiError) {
          console.log('🚀 ~ consultaCep ~ by brasil api ~ brasilApiError:', brasilApiError);

          try {
            const { data: { logradouro, bairro, localidade, uf } } = await viaCepApi.get(`/${value}/json`);
            console.log('🚀 ~ consultaCep ~ by via cep ~ logradouro, bairro, localidade, uf:', logradouro, bairro, localidade, uf);
            return {
              street: logradouro,
              neighborhood: bairro,
              state: uf,
              city: localidade,
            };
          } catch (viaCepError) {
            console.log('🚀 ~ consultaCep ~ by via cep api ~ viaCepError:', viaCepError);

            try {
              const { data: { result: { street, district, city, stateShortname } } } = await brasilAbertoApi.get(`/zipcode/${value}`);
              return {
                street,
                neighborhood: district,
                state: stateShortname,
                city,
              };
            } catch (brasilAbertoApiError) {
              console.log('🚀 ~ consultaCep ~ by brasil aberto api ~ brasilAbertoApiError:', brasilAbertoApiError);
              return null;
            }
          }
        }
      }
    }

    return null;
  } catch (error) {
    console.log('🚀 ~ consultaCep ~ error:', error);
    return null;
  }
}
