import React, { useEffect, useState, useCallback, useMemo, useRef, useContext } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import * as Yup from 'yup';

import CustomColorPicker from '../../components/CustomColorPicker';
import Notification from '../../components/Notification';
import TableManager from '../../components/TableManager';
import { AppContext } from '../../contexts/app';
import { useManager } from '../../hooks/manager';
import api from '../../services/api';

function Grupos() {
  const notificationRef = useRef();
  const { setLoading } = useContext(AppContext);
  const [validationErrors, setValidationErrors] = useState({});
  const { load, destroy, update, store } = useManager();
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState({});
  const [nome, setNome] = useState('');
  const [tipo, setTipo] = useState('');
  const [color, setColor] = useState('#0082c8');

  useEffect(() => {
    load('/grupos', 1, []);
  }, [load]);

  const handleUpdate = useCallback((data) => {
    setValidationErrors({});
    setId(data._id);
    setNome(data.nome);
    setTipo(data?.tipo || '');
    setColor(data?.color || '#0082c8');
    setShowModal(true);
  }, []);

  const handleCreate = useCallback(() => {
    setValidationErrors({});
    setId(null);
    setNome('');
    setTipo('');
    setColor('#0082c8');
    setShowModal(true);
  }, []);

  const handleCreateCsv = useCallback((_selectedRows = []) => {
    (async () => {
      try {
        setLoading({
          loading: true,
          message: 'Aguarde... Estamos gerando o arquivo CSV... ',
        });

        // await fetch api
        const res = await api.get(`/grupoCSVCreatorReportLists?gruposIds=${JSON.stringify(_selectedRows.map(({ _id }) => _id))}`, { responseType: 'blob' });
        const fileName = res.headers['content-disposition']
          .replace('attachment; filename=', '')
          .replace('"', '');

        const fileURL = window.URL.createObjectURL(res.data);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.target = '_blank';
        alink.rel = 'noopener noreferrer';
        alink.download = fileName;
        alink.click();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao realizar ao gerar relatório',
          color: 'danger',
        });
      }
    })();
  }, [setLoading]);

  const handleSave = async () => {
    try {
      const data = {
        nome,
        tipo,
        color,
      };

      const schema = Yup.object().shape({
        nome: Yup.string().required('Informe o nome do grupo'),
        tipo: Yup.string().required('Informe o tipo do grupo'),
        color: Yup.string().required('Selecione uma cor'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (id) {
        update(id, '/grupos', data, () => {
          setLoading(false);
          setShowModal(false);
        }, () => setLoading(false));
        return;
      }

      store('/grupos', data, () => {
        setLoading(false);
        setShowModal(false);
      }, () => setLoading(false));
    } catch (err) {
      setLoading(false);
      console.log(err);
      setValidationErrors({});
      // Validation failed
      if (err instanceof Yup.ValidationError) {
        notificationRef.current.notify({
          message: 'Por favor, preencha todos os dados necessários para salvar',
          color: 'warning',
        });
        const ve = {};
        err.inner.forEach((e) => {
          ve[e.path] = e.message;
        });
        setValidationErrors(ve);
        return;
      }

      if (err && err.response && err.response.data) {
        const { message } = err.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });

        return;
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível salvar',
        color: 'danger',
      });
    }
  };

  const tipoFilter = useMemo(
    () => [
      {
        value: 'cliente',
        label: 'Cliente',
      },
      {
        value: 'fornecedor',
        label: 'Fornecedor',
      },
      {
        value: 'profissional',
        label: 'Profissional',
      },
    ],
    [],
  );

  const colorFormatter = useCallback(
    (row) => (row?.color ? <i className="fa-solid fa-circle" style={{ color: row.color }} /> : ''),
    [],
  );

  const columns = useMemo(() => [
    {
      dataField: 'nome',
      text: 'Nome',
      textFilter: true,
      width: '100%',
    },
    {
      dataField: 'tipo',
      text: 'Tipo',
      selectFilter: tipoFilter,
    },
    {
      dataField: 'color',
      text: 'Cor do Grupo',
      formatter: colorFormatter,
    },
  ]);

  return (
    <Container fluid>
      <Row className="mt-5">
        <Col>
          <Notification ref={notificationRef} />
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h3>Grupos</h3>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col>
                  <Row>
                    <Col xs={12} sm={5} md={3} className="mb-3 mb-xl-0">
                      <Button block color="success" onClick={handleCreate}>
                        Cadastrar
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableManager
            initialColumns={columns}
            onCreateCsv={handleCreateCsv}
            onUpdate={(row) => handleUpdate(row)}
            onDelete={(row) => destroy(row._id, '/grupos')}
            selectable
          />
        </Col>
      </Row>
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        centered
      >
        <ModalHeader>
          <strong>Grupo</strong>
          <Button
            className="close position-absolute top-4 right-4 py-1 px-2"
            onClick={() => setShowModal(false)}
          >
            <i className="fas fa-times" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Input
                type="text"
                placeholder="Nome"
                value={nome}
                invalid={!!validationErrors.nome}
                onChange={({ target: { value } }) => setNome(value)}
              />
              <FormFeedback>{validationErrors.nome}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Input
                type="select"
                placeholder="Tipo"
                value={tipo}
                invalid={!!validationErrors.tipo}
                onChange={({ target: { value } }) => setTipo(value)}
              >
                <option value="">Selecione</option>
                <option value="cliente">Cliente</option>
                <option value="fornecedor">Fornecedor</option>
                <option value="profissional">Profissional</option>
              </Input>
              <FormFeedback>{validationErrors.tipo}</FormFeedback>
            </FormGroup>
            <CustomColorPicker
              color={color}
              onChange={(c) => setColor(c.hex)}
              invalidMessage={validationErrors.color}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleSave}>Salvar</Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}

export default Grupos;
