import React from 'react';

function ValidationErrorsCount({ count = 0 }) {
  if (count === 0) {
    return null;
  }

  return (
    <span className="ml-2 p-1 bg-white rounded" style={{ fontSize: '0.75rem' }}>
      <i className="fa-solid fa-asterisk text-danger mr-1" />
      <span className="text-danger font-weight-700">
        {count}
      </span>
    </span>
  );
}

export default ValidationErrorsCount;
