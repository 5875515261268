/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
import React, { useState, useCallback, useEffect } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';

import classNames from 'classnames';
import { addDays, addMonths, format, getDate, getDay, getDaysInMonth, getMonth, getYear, parseISO, subDays, subMonths } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { range } from 'lodash';

import apiBrasil from '../../services/apiBrasil';

import './styles.css';

function Calendar({
  initialDate = new Date(),
  // initialSelectedDates = [],
  selectedDates = [],
  yearsRange = range(getYear(new Date()), getYear(new Date()) + 100, 1),
  renderDayContents = (dateValue) => format(dateValue, 'dd', { locale: ptBR }),
  renderDayMenu = () => null,
  onMonthChange = () => null,
  onYearChange = () => null,
  onDaysOfCalendarChange = () => null,
  onSelectedDatesChange = () => null,
}) {
  const [date, setDate] = useState(initialDate);
  const [days, setDays] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [selectedHolidays, setSelectedHolidays] = useState([]);
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const weeks = [
    'Dom',
    'Seg',
    'Ter',
    'Qua',
    'Qui',
    'Sex',
    'Sáb',
  ];

  useEffect(() => {
    const daysInMonth = getDaysInMonth(date);
    const daysOfMonthArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
      daysOfMonthArray.push(new Date(getYear(date), getMonth(date), i));
    }
    const lastDaysOfPrevMonth = [];
    for (let i = getDay(daysOfMonthArray[0]); i >= 1; i--) {
      lastDaysOfPrevMonth.push(subDays(daysOfMonthArray[0], i));
    }
    const firstDaysOfNextMonth = [];
    for (
      let i = 1;
      i <= 6 - getDay(daysOfMonthArray[daysOfMonthArray.length - 1]);
      i++
    ) {
      firstDaysOfNextMonth.push(
        addDays(daysOfMonthArray[daysOfMonthArray.length - 1], i),
      );
    }
    setDays({
      lastDaysOfPrevMonth,
      daysOfMonth: daysOfMonthArray,
      firstDaysOfNextMonth,
    });

    onDaysOfCalendarChange([
      ...lastDaysOfPrevMonth,
      ...daysOfMonthArray,
      ...firstDaysOfNextMonth,
    ]);
  }, [date]);

  useEffect(() => {
    const holidaysFilter = [];
    if (days.lastDaysOfPrevMonth) {
      for (const dateValue of days.lastDaysOfPrevMonth) {
        const holiday = holidays.find(
          (d) => parseInt(d.date.split('-')[0], 10) === getYear(dateValue)
            && parseInt(d.date.split('-')[1], 10) - 1 === getMonth(dateValue)
            && parseInt(d.date.split('-')[2], 10) === getDate(dateValue),
        );

        if (holiday) {
          holidaysFilter.push(holiday);
        }
      }
    }

    if (days.daysOfMonth) {
      for (const dateValue of days.daysOfMonth) {
        const holiday = holidays.find(
          (d) => parseInt(d.date.split('-')[0], 10) === getYear(dateValue)
            && parseInt(d.date.split('-')[1], 10) - 1 === getMonth(dateValue)
            && parseInt(d.date.split('-')[2], 10) === getDate(dateValue),
        );

        if (holiday) {
          holidaysFilter.push(holiday);
        }
      }
    }

    if (days.firstDaysOfNextMonth) {
      for (const dateValue of days.firstDaysOfNextMonth) {
        const holiday = holidays.find(
          (d) => parseInt(d.date.split('-')[0], 10) === getYear(dateValue)
            && parseInt(d.date.split('-')[1], 10) - 1 === getMonth(dateValue)
            && parseInt(d.date.split('-')[2], 10) === getDate(dateValue),
        );

        if (holiday) {
          holidaysFilter.push(holiday);
        }
      }
    }

    setSelectedHolidays(holidaysFilter);
  }, [days, holidays]);

  const populateHolidays = useCallback((numberYear) => {
    (async () => {
      try {
        const { data = [] } = await apiBrasil.get(`/feriados/v1/${numberYear}`);
        setHolidays(data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    if ((getYear(new Date()) !== getYear(initialDate)) || !holidays.length) {
      populateHolidays(getYear(initialDate));
    }
  }, [initialDate, holidays]);

  const handleDecreaseMonth = useCallback(() => {
    const newDate = subMonths(date, 1);
    setDate((prevDate) => {
      if (getMonth(prevDate) !== getMonth(newDate)) {
        onMonthChange(newDate);
      }
      if (getYear(prevDate) !== getYear(newDate)) {
        populateHolidays(getYear(newDate));
      }
      return newDate;
    });
  }, [date]);

  const handleIncreaseMonth = useCallback(() => {
    const newDate = addMonths(date, 1);
    setDate((prevDate) => {
      if (getMonth(prevDate) !== getMonth(newDate)) {
        onMonthChange(newDate);
      }
      if (getYear(prevDate) !== getYear(newDate)) {
        populateHolidays(getYear(newDate));
      }
      return newDate;
    });
  }, [date]);

  const handleMonthChange = useCallback(
    (numberMonth) => {
      const newDate = new Date(getYear(date), numberMonth, getDate(date));
      setDate((prevDate) => {
        if (getMonth(prevDate) !== getMonth(newDate)) {
          onMonthChange(newDate);
        }
        return newDate;
      });
    },
    [date],
  );

  const handleYearChange = useCallback(
    (numberYear) => {
      const newDate = new Date(numberYear, getMonth(date), getDate(date));
      setDate((prevDate) => {
        if (getYear(prevDate) !== getYear(newDate)) {
          onYearChange(newDate);
        }
        return newDate;
      });
      populateHolidays(numberYear);
    },
    [date],
  );

  const handleSelectedDates = useCallback(
    (dateValue) => {
      onSelectedDatesChange((prevDates = []) => {
        const selectedDate = prevDates.find(
          (d) => getYear(d) === getYear(dateValue)
            && getMonth(d) === getMonth(dateValue)
            && getDate(d) === getDate(dateValue),
        );
        // console.log('🚀 ~ file: index.js:236 ~ setSelectedDates ~ selectedDate:', selectedDate);

        if (selectedDate) {
          const selectedDatesFilter = prevDates.filter(
            (d) => !(
              getYear(d) === getYear(dateValue)
                && getMonth(d) === getMonth(dateValue)
                && getDate(d) === getDate(dateValue)
            ),
          );

          return selectedDatesFilter;
        }

        return [...prevDates, dateValue];
      });
    },
    [],
  );

  const isSelectedDate = useCallback((dateValue) => {
    const selectedDate = selectedDates.find(
      (d) => getYear(d) === getYear(dateValue)
        && getMonth(d) === getMonth(dateValue)
        && getDate(d) === getDate(dateValue),
    );
    return (
      selectedDate
      && getYear(dateValue) === getYear(selectedDate)
      && getMonth(dateValue) === getMonth(selectedDate)
      && getDate(dateValue) === getDate(selectedDate)
    );
  }, [selectedDates]);

  const isHolidayDate = useCallback((dateValue) => {
    const holiday = holidays.find(
      (d) => parseInt(d.date.split('-')[0], 10) === getYear(dateValue)
        && parseInt(d.date.split('-')[1], 10) - 1 === getMonth(dateValue)
        && parseInt(d.date.split('-')[2], 10) === getDate(dateValue),
    );

    return (
      holiday
      && getYear(dateValue) === parseInt(holiday.date.split('-')[0], 10)
      && getMonth(dateValue) === parseInt(holiday.date.split('-')[1], 10) - 1
      && getDate(dateValue) === parseInt(holiday.date.split('-')[2], 10)
    );
  }, [holidays]);

  return (
    <div
      style={{
        width: '100%',
        overflowX: 'auto',
      }}
    >
      <div className="calendar-month-view-container">
        <div className="calendar-month-view-header">
          <Row>
            <Col xs={1}>
              <Button color="link" onClick={handleDecreaseMonth}>
                <i className="fas fa-arrow-circle-left" />
              </Button>
            </Col>
            <Col xs={6}>
              <FormGroup>
                <Input
                  className="w-100 p-2"
                  type="select"
                  value={months[getMonth(date)]}
                  onChange={({ target: { value } }) => handleMonthChange(months.indexOf(value))}
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <Input
                  className="w-100 p-2"
                  type="select"
                  value={getYear(date)}
                  onChange={({ target: { value } }) => handleYearChange(value)}
                >
                  {yearsRange.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col xs={1}>
              <Button color="link" onClick={handleIncreaseMonth}>
                <i className="fas fa-arrow-circle-right" />
              </Button>
            </Col>
          </Row>
        </div>
        <div className="calendar-month-view-body">
          <div>
            <div className="calendar-month-view-weeks-container">
              {weeks.map((w) => (
                <div key={w} className="calendar-month-view-week-container">
                  <div className="calendar-month-view-week">{w}</div>
                </div>
              ))}
            </div>
            <div className="calendar-month-view-days-container">
              {days?.lastDaysOfPrevMonth?.map((d) => (
                <div key={d} className="calendar-month-view-day-container">
                  <div
                    id={`popover-calendar-${getDate(d)}-${getMonth(
                      d,
                    )}-${getYear(d)}`}
                    className={classNames({
                      'calendar-month-view-day': true,
                      'last-days-of-prev-month': true,
                      today:
                        getYear(d) === getYear(new Date())
                        && getMonth(d) === getMonth(new Date())
                        && getDate(d) === getDate(new Date()),
                      holiday: isHolidayDate(d),
                      selected: isSelectedDate(d),
                    })}
                    role="presentation"
                    onClick={() => handleSelectedDates(d)}
                  >
                    {renderDayMenu(d)}
                    {renderDayContents(d)}
                  </div>
                </div>
              ))}
              {days?.daysOfMonth?.map((d) => (
                <div key={d} className="calendar-month-view-day-container">
                  <div
                    id={`popover-calendar-${getDate(d)}-${getMonth(
                      d,
                    )}-${getYear(d)}`}
                    color="primary"
                    className={classNames({
                      'calendar-month-view-day': true,
                      today:
                        getYear(d) === getYear(new Date())
                        && getMonth(d) === getMonth(new Date())
                        && getDate(d) === getDate(new Date()),
                      holiday: isHolidayDate(d),
                      selected: isSelectedDate(d),
                    })}
                    role="presentation"
                    onClick={() => handleSelectedDates(d)}
                  >
                    {renderDayMenu(d)}
                    {renderDayContents(d)}
                  </div>
                </div>
              ))}
              {days?.firstDaysOfNextMonth?.map((d) => (
                <div key={d} className="calendar-month-view-day-container">
                  <div
                    id={`popover-calendar-${getDate(d)}-${getMonth(
                      d,
                    )}-${getYear(d)}`}
                    className={classNames({
                      'calendar-month-view-day': true,
                      'first-days-of-next-month': true,
                      today:
                        getYear(d) === getYear(new Date())
                        && getMonth(d) === getMonth(new Date())
                        && getDate(d) === getDate(new Date()),
                      holiday: isHolidayDate(d),
                      selected: isSelectedDate(d),
                    })}
                    role="presentation"
                    onClick={() => handleSelectedDates(d)}
                  >
                    {renderDayMenu(d)}
                    {renderDayContents(d)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {selectedHolidays.map(
        (h) => h.date && (
        <div key={h.date} className="text-danger ml-4">
            {format(parseISO(h.date), 'dd', { locale: ptBR })}
            {' - '}
            {h.name}
        </div>
        ),
      )}
    </div>
  );
}

export default Calendar;
