import React, { forwardRef } from 'react';
import ColorPicker from 'react-pick-color';
import { Label } from 'reactstrap';

function CustomColorPicker({
  label,
  text,
  color,
  loading,
  invalidMessage,
  onChange,
  ...rest
}, ref) {
  return (
    <div className="mb-3">
      {label ? <Label>{label}</Label> : null}
      <ColorPicker
        ref={ref}
        color={color}
        onChange={onChange}
        {...rest}
      />
      {invalidMessage && (
        <div className="text-danger invalid-feedback d-inline">
          {invalidMessage}
        </div>
      )}
      {text && (
        <div className="form-text">
          <small className="form-text text-muted">{text}</small>
        </div>
      )}
    </div>
  );
}

export default forwardRef(CustomColorPicker);
