import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  Fade,
  CardFooter,
  Button,
} from 'reactstrap';

import classNames from 'classnames';

import GoToTop from '../../components/GoToTop';
import { AppContext } from '../../contexts/app';
import api from '../../services/api';
import { numberToCurrency } from '../../utils/functions';

export default function DashboardAdmin() {
  const navigate = useNavigate();
  const { setLoading } = useContext(AppContext);
  const [datas, setDatas] = useState({
    clientes: 0,
    profissionais: 0,
    fornecedores: 0,
    agendamentos: {
      abertos: {
        qty: 0,
        value: 0,
      },
      concluidos: {
        qty: 0,
        value: 0,
      },
      cancelados: {
        qty: 0,
        value: 0,
      },
    },
  });

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await api.get('dashboards');
        setDatas(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('🚀 ~ file: index.js ~ error:', error);
      }
    })();

    return () => null;
  }, []);

  const {
    totalContaPagar = 0,
    totalContaReceber = 0,
    totalSituacao = {},
    totalCancelado = 0,
  } = datas;

  return (
    <Container fluid>
      <GoToTop />
      <Row>
        <Col>
          <Card className="shadow">
            <CardHeader tag="h3">
              Cadastros
            </CardHeader>
            <CardBody>
              <Row>
                <Col xs={12} lg={4}>
                  <Card color="success">
                    <CardHeader tag="h4" className="text-white text-truncate">
                      Cliente(s)
                    </CardHeader>
                    <CardBody tag="div" className="d-flex flex-wrap align-items-center">
                      <i className="fas fa-user text-white mr-3" style={{ fontSize: '1.75rem' }} />
                      <Fade>
                        <span className="text-white" style={{ fontSize: '2.25rem' }}>{datas.clientes}</span>
                      </Fade>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} lg={4}>
                  <Card color="danger">
                    <CardHeader tag="h4" className="text-white text-truncate">
                      Profissional(s)
                    </CardHeader>
                    <CardBody tag="div" className="bg-pink d-flex flex-wrap align-items-center">
                      <i className="fas fa-user-tie text-white mr-3" style={{ fontSize: '1.75rem' }} />
                      <Fade>
                        <span className="text-white" style={{ fontSize: '2.25rem' }}>{datas.profissionais}</span>
                      </Fade>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} lg={4}>
                  <Card color="warning">
                    <CardHeader tag="h4" className="text-white text-truncate">
                      Fornecedor(s)
                    </CardHeader>
                    <CardBody tag="div" className="d-flex flex-wrap align-items-center">
                      <i className="fa-solid fa-building-user text-white mr-3" style={{ fontSize: '1.75rem' }} />
                      <Fade>
                        <span className="text-white" style={{ fontSize: '2.25rem' }}>{datas.fornecedores}</span>
                      </Fade>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="shadow">
            <CardHeader tag="h3">
              Agendamentos
            </CardHeader>
            <CardBody>
              <Row>
                <Col xs={12} lg={4}>
                  <Card color="info">
                    <CardHeader tag="h4" className="text-white text-truncate">
                      Em Aberto(s)
                    </CardHeader>
                    <CardBody tag="div">
                      <div className="d-flex flex-wrap align-items-center">
                        <i className="fa-solid fa-stopwatch-20 text-white mr-3" style={{ fontSize: '1.75rem' }} />
                        <Fade>
                          <span className="text-white" style={{ fontSize: '2.25rem' }}>{datas.agendamentos.abertos.qty}</span>
                        </Fade>
                      </div>
                      <div className="d-flex flex-wrap align-items-center">
                        <Fade>
                          <span className="text-white" style={{ fontSize: '2.25rem' }}>{numberToCurrency(datas.agendamentos.abertos.value)}</span>
                        </Fade>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} lg={4}>
                  <Card color="success">
                    <CardHeader tag="h4" className="text-white text-truncate">
                      Concluido(s)
                    </CardHeader>
                    <CardBody tag="div">
                      <div className="d-flex flex-wrap align-items-center">
                        <i className="fa-solid fa-stopwatch-20 text-white mr-3" style={{ fontSize: '1.75rem' }} />
                        <Fade>
                          <span className="text-white" style={{ fontSize: '2.25rem' }}>{datas.agendamentos.concluidos.qty}</span>
                        </Fade>
                      </div>
                      <div className="d-flex flex-wrap align-items-center">
                        <Fade>
                          <span className="text-white" style={{ fontSize: '2.25rem' }}>{numberToCurrency(datas.agendamentos.concluidos.value)}</span>
                        </Fade>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} lg={4}>
                  <Card color="danger">
                    <CardHeader tag="h4" className="text-white text-truncate">
                      Cancelados(s)
                    </CardHeader>
                    <CardBody tag="div" className="bg-pink">
                      <div className="d-flex flex-wrap align-items-center">
                        <i className="fa-solid fa-stopwatch-20 text-white mr-3" style={{ fontSize: '1.75rem' }} />
                        <Fade>
                          <span className="text-white" style={{ fontSize: '2.25rem' }}>{datas.agendamentos.cancelados.qty}</span>
                        </Fade>
                      </div>
                      <div className="d-flex flex-wrap align-items-center">
                        <Fade>
                          <span className="text-white" style={{ fontSize: '2.25rem' }}>{numberToCurrency(datas.agendamentos.cancelados.value)}</span>
                        </Fade>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="shadow">
            <CardHeader tag="h3">
              Financeiro
            </CardHeader>
            <CardBody>
              <Row>
                <Col xs={12} md={6} lg={4}>
                  <Card color="danger">
                    <CardHeader tag="h4" className="text-white text-truncate">
                      Contas a Pagar
                    </CardHeader>
                    <CardBody className="bg-pink">
                      <div className="d-flex flex-wrap align-items-center">
                        <i className="fa-solid fa-money-bill-transfer text-white mr-3" style={{ fontSize: '1.75rem' }} />
                        <Fade>
                          <span className="text-white" style={{ fontSize: '2.25rem' }}>{numberToCurrency(totalContaPagar)}</span>
                        </Fade>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <Button color="link" className="text-white text-decoration-none" onClick={() => navigate('/conta-pagar')} block>
                        Criar Conta a Pagar
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <Card color="success">
                    <CardHeader tag="h4" className="text-white text-truncate">
                      Contas a Receber
                    </CardHeader>
                    <CardBody>
                      <div className="d-flex flex-wrap align-items-center">
                        <i className="fa-solid fa-hand-holding-dollar text-white mr-3" style={{ fontSize: '1.75rem' }} />
                        <Fade>
                          <span className="text-white" style={{ fontSize: '2.25rem' }}>{numberToCurrency(totalContaReceber)}</span>
                        </Fade>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <Button color="link" className="text-white text-decoration-none" onClick={() => navigate('/conta-receber')} block>
                        Criar Conta a Receber
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
                <Col xs={12} md={12} lg={4}>
                  <Card color="info">
                    <CardHeader tag="h4" className="text-white text-truncate">
                      Saldo
                    </CardHeader>
                    <CardBody>
                      <div className="d-flex flex-wrap align-items-center">
                        <i className={classNames('fa-solid fa-sack-dollar mr-3', { 'text-white': (totalContaReceber - totalContaPagar >= 0), 'text-danger': (totalContaReceber - totalContaPagar < 0) })} style={{ fontSize: '1.75rem' }} />
                        <Fade>
                          <span className={classNames({ 'text-white': (totalContaReceber - totalContaPagar >= 0), 'text-danger': (totalContaReceber - totalContaPagar < 0) })} style={{ fontSize: '2.25rem' }}>{numberToCurrency(totalContaReceber - totalContaPagar)}</span>
                        </Fade>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <Button color="link" className="text-white text-decoration-none" style={{ cursor: 'default' }} onClick={() => null} block>
                        Saldo
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardHeader tag="h4">
                      Situação das Contas
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col xs={12} sm={6}>
                          <Card color="info">
                            <CardHeader tag="h4" className="text-white text-truncate">
                              Recebidas (acumulativo)
                            </CardHeader>
                            <CardBody tag="div" className="d-flex flex-wrap align-items-center">
                              <i className="fa-solid fa-hand-holding-dollar text-white mr-3" style={{ fontSize: '1.75rem' }} />
                              <Fade>
                                <span className="text-white" style={{ fontSize: '2.25rem' }}>{numberToCurrency(totalSituacao?.rebido || 0)}</span>
                              </Fade>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Card color="success">
                            <CardHeader tag="h4" className="text-white text-truncate">
                              Pagas (acumulativo)
                            </CardHeader>
                            <CardBody tag="div" className="d-flex flex-wrap align-items-center">
                              <i className="fa-solid fa-circle-check text-white mr-3" style={{ fontSize: '1.75rem' }} />
                              <Fade>
                                <span className="text-white" style={{ fontSize: '2.25rem' }}>{numberToCurrency(totalSituacao?.pago || 0)}</span>
                              </Fade>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Card color="danger">
                            <CardHeader tag="h4" className="text-white text-truncate">
                              Vencidas (acumulativo)
                            </CardHeader>
                            <CardBody tag="div" className="bg-pink d-flex flex-wrap align-items-center">
                              <i className="fa-solid fa-circle-xmark text-white mr-3" style={{ fontSize: '1.75rem' }} />
                              <Fade>
                                <span className="text-white" style={{ fontSize: '2.25rem' }}>{numberToCurrency(totalSituacao?.vencido || 0)}</span>
                              </Fade>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Card color="danger">
                            <CardHeader tag="h4" className="text-white text-truncate">
                              Canceladas (acumulativo)
                            </CardHeader>
                            <CardBody tag="div" className="bg-pink d-flex flex-wrap align-items-center">
                              <i className="fa-solid fa-calendar-xmark text-white mr-3" style={{ fontSize: '1.75rem' }} />
                              <Fade>
                                <span className="text-white" style={{ fontSize: '2.25rem' }}>{numberToCurrency(totalCancelado)}</span>
                              </Fade>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
