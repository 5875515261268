import React, { useEffect, useState, useCallback, useRef, useContext, useMemo } from 'react';
import { PatternFormat } from 'react-number-format';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import * as Yup from 'yup';

import ImageFileInput from '../../components/ImageFileInput';
import Notification from '../../components/Notification';
import TableManager from '../../components/TableManager';
import { AppContext } from '../../contexts/app';
import { useManager } from '../../hooks/manager';
import api from '../../services/api';

function Admins() {
  const notificationRef = useRef();
  const { setLoading } = useContext(AppContext);
  const [validationErrors, setValidationErrors] = useState({});
  const { load, destroy, update, store } = useManager();
  const [showModal, setShowModal] = useState(false);
  const [showModalPassword, setShowModalPassword] = useState(false);
  const [id, setId] = useState({});
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [image, setImage] = useState('');

  useEffect(() => {
    load('/admins', 1, []);
  }, [load]);

  const handleUpdate = useCallback((data) => {
    setValidationErrors({});
    setId(data._id);
    setName(data.name);
    setCpf(data.cpf);
    setEmail(data.email);
    setPassword('');
    setImage(data.image ? data.image_url : null);
    setShowModal(true);
  }, []);

  const handleUpdatePassword = useCallback((data) => {
    setValidationErrors({});
    setId(data._id);
    setEmail(data.email);
    setPassword('');
    setPasswordConfirmation('');
    setShowModalPassword(true);
  }, []);

  const handleCreate = useCallback(() => {
    setValidationErrors({});
    setId(null);
    setName('');
    setCpf('');
    setEmail('');
    setPassword('');
    setPasswordConfirmation('');
    setImage('');
    setShowModal(true);
  }, []);

  const handleCreateCsv = useCallback((_selectedRows = []) => {
    (async () => {
      try {
        setLoading({
          loading: true,
          message: 'Aguarde... Estamos gerando o arquivo CSV... ',
        });

        // await fetch api
        const res = await api.get(`/adminCSVCreatorReportLists?adminsIds=${JSON.stringify(_selectedRows.map(({ _id }) => _id))}`, { responseType: 'blob' });
        const fileName = res.headers['content-disposition']
          .replace('attachment; filename=', '')
          .replace('"', '');

        const fileURL = window.URL.createObjectURL(res.data);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.target = '_blank';
        alink.rel = 'noopener noreferrer';
        alink.download = fileName;
        alink.click();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao realizar ao gerar relatório',
          color: 'danger',
        });
      }
    })();
  }, [setLoading]);

  const handleSave = async () => {
    try {
      const data = {
        name,
        cpf,
        email,
        password,
        passwordConfirmation,
      };

      let schema;

      if (!id) {
        schema = Yup.object().shape({
          name: Yup.string().required('Informe o nome do administrador'),
          cpf: Yup.string().required('Informe o CPF do administrador'),
          email: Yup.string()
            .email('E-mail inválido')
            .required('Informe um email'),
          password: Yup.string().required('Informe uma senha'),
          passwordConfirmation: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'As senhas precisam ser iguais',
          ),
        });
      } else {
        schema = Yup.object().shape({
          name: Yup.string().required('Informe o nome do administrador'),
          cpf: Yup.string().required('Informe o CPF do administrador'),
          email: Yup.string()
            .email('E-mail inválido')
            .required('Informe um email'),
        });
      }

      await schema.validate(data, {
        abortEarly: false,
      });

      const formData = new FormData();

      formData.append('name', name);
      formData.append('cpf', cpf);
      formData.append('email', email);

      if (password) {
        formData.append('password', password);
      }

      if (image instanceof File) {
        formData.append('image', image);
      }

      if (id) {
        update(id, '/admins', formData, () => {
          setShowModal(false);
        });
        return;
      }

      store('/admins', formData, () => {
        setShowModal(false);
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
      setValidationErrors({});
      // Validation failed
      if (err instanceof Yup.ValidationError) {
        notificationRef.current.notify({
          message:
            'Por favor, preencha todos os dados necessários para salvar',
          color: 'warning',
        });
        const ve = {};
        err.inner.forEach((e) => {
          ve[e.path] = e.message;
        });
        setValidationErrors(ve);
        return;
      }

      if (err && err.response && err.response.data) {
        const { message } = err.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });

        return;
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível salvar',
        color: 'danger',
      });
    }
  };

  const handleSavePassword = async () => {
    try {
      const data = {
        password,
        passwordConfirmation,
      };

      const schema = Yup.object().shape({
        password: Yup.string().required('Informe a nova senha'),
        passwordConfirmation: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'As senhas precisam ser iguais',
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.put(`/admins/${id}/passwords`, data);
      const { message } = response.data;

      notificationRef.current.notify({
        message,
      });

      setShowModalPassword(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      setValidationErrors({});
      // Validation failed
      if (err instanceof Yup.ValidationError) {
        notificationRef.current.notify({
          message:
            'Por favor, preencha todos os dados necessários para salvar',
          color: 'warning',
        });
        const ve = {};
        err.inner.forEach((e) => {
          ve[e.path] = e.message;
        });
        setValidationErrors(ve);
        return;
      }

      if (err && err.response && err.response.data) {
        const { message } = err.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });

        return;
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível salvar',
        color: 'danger',
      });
    }
  };

  const imageFormatter = useCallback((row) => (
    row.image ? (
      <img src={row.image_url} alt={row.image_url} width="30px" />
    ) : (
      <span>Sem Imagem</span>
    )
  ), []);

  const columns = useMemo(() => [
    {
      dataField: 'image_url',
      text: 'Imagem',
      formatter: imageFormatter,
    },
    {
      dataField: 'name',
      text: 'Nome',
      textFilter: true,
    },
    {
      dataField: 'email',
      text: 'Email',
      textFilter: true,
    },
    {
      dataField: 'cpf',
      text: 'CPF',
      textFilter: true,
    },
  ], []);

  const actions = useMemo(() => [
    {
      name: 'password',
      tooltip: 'Alterar Senha',
      color: 'warning',
      className: 'mt-2 mr-2 text-white',
      icon: 'fas fa-key',
      onClick: (row) => handleUpdatePassword(row),
    },
  ], []);

  return (
    <Container fluid>
      <Notification ref={notificationRef} />
      <Row className="mt-5">
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h3>Administradores</h3>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col>
                  <Row>
                    <Col xs={12} sm={5} md={3} className="mb-3 mb-xl-0">
                      <Button block color="success" onClick={handleCreate}>
                        Cadastrar
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableManager
            initialColumns={columns}
            actions={actions}
            onCreateCsv={handleCreateCsv}
            onUpdate={(row) => handleUpdate(row)}
            onDelete={(row) => destroy(row._id, '/admins')}
            enableDeletedItemsButton
            selectable
          />
        </Col>
      </Row>
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        centered
      >
        <ModalHeader>
          <strong>Administrador</strong>
          <Button
            className="close position-absolute top-4 right-4 py-1 px-2"
            onClick={() => setShowModal(false)}
          >
            <i className="fas fa-times" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <Form>
            <ImageFileInput
              previewUrl={image}
              onChange={(file) => setImage(file)}
            />
            <FormGroup>
              <Input
                type="text"
                placeholder="Nome"
                value={name}
                invalid={!!validationErrors.name}
                onChange={({ target: { value } }) => setName(value)}
              />
              <FormFeedback>{validationErrors.name}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <PatternFormat
                customInput={Input}
                format="###.###.###-##"
                mask="_"
                valueIsNumericString
                placeholder="CPF"
                value={cpf}
                invalid={!!validationErrors.cpf}
                onValueChange={({ value }) => setCpf(value)}
              />
              <FormFeedback>{validationErrors.cpf}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                placeholder="Email"
                value={email}
                invalid={!!validationErrors.email}
                onChange={({ target: { value } }) => setEmail(value)}
              />
              <FormFeedback>{validationErrors.email}</FormFeedback>
            </FormGroup>
            {!id ? (
              <>
                <FormGroup>
                  <Input
                    type="password"
                    placeholder="Senha"
                    value={password}
                    invalid={!!validationErrors.password}
                    onChange={({ target: { value } }) => setPassword(value)}
                  />
                  <FormFeedback>{validationErrors.password}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Input
                    type="password"
                    placeholder="Confirme a senha"
                    value={passwordConfirmation}
                    invalid={!!validationErrors.passwordConfirmation}
                    onChange={({ target: { value } }) => setPasswordConfirmation(value)}
                  />
                  <FormFeedback>
                    {validationErrors.passwordConfirmation}
                  </FormFeedback>
                </FormGroup>
              </>
            ) : null}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleSave}>Salvar</Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={showModalPassword}
        toggle={() => setShowModalPassword(!showModalPassword)}
        centered
      >
        <ModalHeader>
          <strong>{`Alterar Senha do Admin ${email}`}</strong>
          <Button
            className="close position-absolute top-4 right-4 py-1 px-2"
            onClick={() => setShowModalPassword(false)}
          >
            <i className="fas fa-times" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <InputGroup
                className={`input-group-merge ${validationErrors.password ? 'invalid' : ''
                }`}
              >
                <Input
                  type={passwordType}
                  placeholder="Nova Senha"
                  value={password}
                  invalid={!!validationErrors.password}
                  onChange={({ target: { value } }) => setPassword(value)}
                />
                <InputGroupText onClick={() => setPasswordType((prevState) => (prevState === 'password' ? 'text' : 'password'))}>
                  <i
                    className={`fas ${passwordType === 'password'
                      ? 'fa-eye-slash'
                      : 'fa-eye'
                    }`}
                  />
                </InputGroupText>
                <FormFeedback>{validationErrors.password}</FormFeedback>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup
                className={`input-group-merge ${validationErrors.password ? 'invalid' : ''
                }`}
              >
                <Input
                  type={passwordType}
                  placeholder="Confirme a senha"
                  value={passwordConfirmation}
                  invalid={!!validationErrors.passwordConfirmation}
                  onChange={({ target: { value } }) => setPasswordConfirmation(value)}
                />
                <InputGroupText onClick={() => setPasswordType((prevState) => (prevState === 'password' ? 'text' : 'password'))}>
                  <i
                    className={`fas ${passwordType === 'password'
                      ? 'fa-eye-slash'
                      : 'fa-eye'
                    }`}
                  />
                </InputGroupText>
                <FormFeedback>
                  {validationErrors.passwordConfirmation}
                </FormFeedback>
              </InputGroup>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleSavePassword}>Salvar</Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}

export default Admins;
