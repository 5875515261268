import * as Yup from 'yup';

export const schema = Yup.object().shape({
  /* profissional: Yup.object().shape({
    value: Yup.string().when('contaPagarPara', {
      is: (value) => {
        console.log(value);
        return true;
      },
      then: Yup.string().required('Selecione um Profissional'),
    }),
  }), */
  /* fornecedor: Yup.object().shape({
    value: Yup.string().when('contaPagarPara', {
      is: (value) => {
        console.log(value);
        return true;
      },
      then: Yup.string().required('Selecione um fornecedor'),
    }),
  }), */
  dataVencimento: Yup.date().required('Seleciona uma data vencimento'),
  valor: Yup.string().required('Informe um valor'),
});
