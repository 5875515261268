/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useMemo, useRef, useContext, useCallback } from 'react';
import InputMask from 'react-input-mask';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { useNavigate, useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Alert, Button, Card, CardBody, Col, Collapse, Container, Form, FormFeedback, FormGroup, FormText, Input, Label, Row, Table, UncontrolledCollapse } from 'reactstrap';

import classNames from 'classnames';
import { format, getHours, getMinutes, getYear, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { range } from 'lodash';
import { validateCPF, validateCNPJ, validatePhone } from 'validations-br';
import * as Yup from 'yup';

import CustomButton from '../../components/CustomButton';
import CustomMaskedInput from '../../components/CustomMaskedInput';
import CustomSelect from '../../components/CustomSelect';
import DatePickerMaskedInput from '../../components/DatePickerMaskedInput';
import Endereco from '../../components/Endereco';
import TipoDeAtendimento from '../../components/Especialidade';
import Notification from '../../components/Notification';
import Select from '../../components/Select';
import TabelaValores from '../../components/TabelaValorEspecialista';
import TimePickerMaskedInput from '../../components/TimePickerMaskedInput';
import UploadFiles from '../../components/UploadFiles';
import ValidationErrorsCount from '../../components/ValidationErrorCount';
import { AppContext } from '../../contexts/app';
import { useAuth } from '../../hooks/auth';
import { useManager } from '../../hooks/manager';
import api from '../../services/api';
import { numberToCurrency } from '../../utils/functions';

function Profissional() {
  const navigate = useNavigate();
  const params = useParams();
  const notificationRef = useRef();
  const enderecoRef = useRef();
  const enderecoEstagioInsEnsRef = useRef();
  const enderecoEstagioConRef = useRef();
  const enderecoEstagioComEstRef = useRef();
  const especialidadeRef = useRef();
  const tabelaValoresRef = useRef();
  const { loggedUser } = useAuth();
  const { update, store } = useManager();
  const { setLoading, loading } = useContext(AppContext);
  const [loadingTabelaValores, setLoadingTabelaValores] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [validationErrorsDadosEstagioCount, setValidationErrorsDadosEstagioCount] = useState(0);
  const [validationErrorsContatosCount, setValidationErrorsContatosCount] = useState(0);
  const [validationErrorsEnderecoCount, setValidationErrorsEnderecoCount] = useState(0);
  const [validationErrorsAdmissaoCount, setValidationErrorsAdmissaoCount] = useState(0);
  const [validationErrorsDadosPessoaisCount, setValidationErrorsDadosPessoaisCount] = useState(0);
  const [validationErrorsEspecialidadesCount, setValidationErrorsEspecialidadesCount] = useState(0);
  const [validationErrorsTabelaValoresCount, setValidationErrorsTabelaValoresCount] = useState(0);
  const [dadosPessoaisOpen, setDadosPessoaisOpen] = useState(true);
  const [tipo, setTipo] = useState('interno');
  const [regime, setRegime] = useState('CLT');
  const [tipoContrato, setTipoContrato] = useState('FUN_CLT');
  const [id, setId] = useState();
  const [grupos, setGrupos] = useState([]);
  const [grupo, setGrupo] = useState('');
  const [image, setImage] = useState('');
  const [name, setName] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [cpf, setCpf] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [rg, setRg] = useState('');
  const [rgEmissor, setRgEmissor] = useState('');
  const [rgEmissao, setRgEmissao] = useState('');
  const [tituloEleitor, setTituloEleitor] = useState('');
  const [tituloEleitorZona, setTituloEleitorZona] = useState('');
  const [tituloEleitorSecao, setTituloEleitorSecao] = useState('');
  const [filiacaoPai, setFiliacaoPai] = useState('');
  const [filiacaoMae, setFiliacaoMae] = useState('');
  const [nascimento, setNascimento] = useState('');
  const [ctps, setCtps] = useState('');
  const [ctpsSerie, setCtpsSerie] = useState('');
  const [ctpsEmissao, setCtpsEmissao] = useState('');
  const [ctpsPis, setCtpsPis] = useState('');
  const [orgaoEmissorClasse, setOrgaoEmissorClasse] = useState('');
  const [cnh, setCnh] = useState('');
  const [cnhCategoria, setCnhCategoria] = useState('');
  const [cnhVencimento, setCnhVencimento] = useState('');
  const [reservista, setReservista] = useState('');
  const [estadoCivil, setEstadoCivil] = useState('SOLTEIRO');
  const [conjuge, setConjuge] = useState('');
  const [corDeclarada, setCorDeclarada] = useState('NÃO INFORMADA');
  const [escolaridade, setEscolaridade] = useState('');
  const [naturalidade, setNaturalidade] = useState('');
  const [genero, setGenero] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [residenciaPropria, setResidenciaPropria] = useState(true);
  const [residenciaCompradaComRecursoFGTS, setResidenciaCompradaComRecursoFGTS] = useState(false);
  const [filhoNome, setFilhoNome] = useState('');
  const [filhoNascimento, setFilhoNascimento] = useState('');
  const [filhoCpf, setFilhoCpf] = useState('');
  const [filhos, setFilhos] = useState([]);
  const [admissaoData, setAdmissaoData] = useState('');
  const [admissaoContratoExperiencia, setAdmissaoContratoExperiencia] = useState(true);
  const [admissaoPrazo, setAdmissaoPrazo] = useState('');
  const [admissaoDiasProrrogacao, setAdmissaoDiasProrrogacao] = useState('');
  const [funcao, setFuncao] = useState('');
  const [salario, setSalario] = useState('');
  const [horarioTrabalhoDiaSemana, setHorarioTrabalhoDiaSemana] = useState(1);
  const [horarioTrabalhoisDiaUtil, setHorarioTrabalhoisDiaUtil] = useState(true);
  const [horarioTrabalhoInicio, setHorarioTrabalhoInicio] = useState('');
  const [horarioTrabalhoTermino, setHorarioTrabalhoTermino] = useState('');
  const [horarioTrabalhoIntervaloInicio, setHorarioTrabalhoIntervaloInicio] = useState('');
  const [horarioTrabalhoIntervaloTermino, setHorarioTrabalhoIntervaloTermino] = useState('');
  const [horarioTrabalhos, setHorarioTrabalhos] = useState([]);
  const [observacao, setObservacao] = useState('');
  const [observacoes, setObservacoes] = useState([]);
  const [newEspecialidade, setNewEspecialidade] = useState('');
  const [especialidade, setEspecialidade] = useState('');
  const [especialidades, setEspecialidades] = useState([]);
  const [especialidadesList, setEspecialidadesList] = useState([]);
  const [newTabelaValor, setNewTabelaValor] = useState('');
  const [tabelaValor, setTabelaValor] = useState('');
  const [tabelaValores, setTabelaValores] = useState([]);
  const [tabelaValoresList, setTabelaValoresList] = useState([]);
  const [documentosAnexados, setDocumentosAnexados] = useState([]);
  const [solicitationDeleteAccount, setSolicitationDeleteAccount] = useState();
  const [dataAssinaturaContrato, setDataAssinaturaContrato] = useState('');
  const [dataEncerramentoContrato, setDataEncerramentoContrato] = useState('');
  const [estagio, setEstagio] = useState({ tipo: 'EST' });
  const [representantes, setRepresentantes] = useState([]);
  const [supervisores, setSupervisores] = useState([]);

  const weeks = useMemo(() => [
    'Dom',
    'Seg',
    'Ter',
    'Qua',
    'Qui',
    'Sex',
    'Sáb',
  ], []);

  useEffect(() => {
    setValidationErrors({});
    setValidationErrorsDadosPessoaisCount(0);
    setValidationErrorsDadosEstagioCount(0);
    setValidationErrorsContatosCount(0);
    setValidationErrorsEnderecoCount(0);
    setValidationErrorsAdmissaoCount(0);
    setValidationErrorsEspecialidadesCount(0);
    setValidationErrorsTabelaValoresCount(0);
  }, [tipoContrato]);

  useEffect(() => {
    if (!funcao) {
      setEspecialidades([]);
    }
  }, [funcao]);

  useEffect(() => {
    if (!salario) {
      setTabelaValores([]);
    }
  }, [salario]);

  useEffect(() => {
    (async () => {
      try {
        const { data = [] } = await api.get('/grupos?search=[{"field":"tipo","value":"profissional"}]&limit=0');
        // console.log(data);
        setGrupos(data.map((d) => ({
          value: d._id,
          label: d.nome,
        })));
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoadingTabelaValores(true);
        const { data = [] } = await api.get('/tabelaValoresEspecialistas?limit=0');
        // console.log(data);
        setTabelaValoresList(
          data.map(({ _id: value, nome: label, valores }) => ({
            value,
            label,
            valores,
          })),
        );
        setTabelaValores((prevTabelaValores) => prevTabelaValores.filter((tv) => !!data.find((d) => tv.value === d._id)));
        setLoadingTabelaValores(false);
      } catch (error) {
        setLoadingTabelaValores(true);
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { data = [] } = await api.get('/profissionais?search=[{"field":"isSupervisor","value":"true"}]&limit=0');
        // console.log(data);
        setSupervisores(data.map((d) => ({
          value: d._id,
          label: d.name,
        })));

        setRepresentantes(data.map((d) => ({
          value: d._id,
          label: d.name,
        })));
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (tabelaValores.length) {
          const options = [];

          tabelaValores.forEach(({ valores = [] }) => valores.forEach(({ especialidade: e, valor }) => {
            options.push({
              value: e._id,
              nome: e.nome,
              valor,
              label: `${e.nome} - valor ${numberToCurrency(valor)}`,
            });
          }));

          setEspecialidadesList(options);
          return;
        }
        setTabelaValor('');
        setEspecialidade('');
        setEspecialidadesList([]);
        setEspecialidades([]);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [tabelaValores]);

  useEffect(() => {
    if (newEspecialidade) {
      setEspecialidades((prevEspecialidades) => [{ value: newEspecialidade._id, label: newEspecialidade.nome }, ...prevEspecialidades]);
      setNewEspecialidade('');
    }
  }, [newEspecialidade]);

  useEffect(() => {
    if (newTabelaValor) {
      setTabelaValores((prevTabelaValores) => [{ value: newTabelaValor._id, label: newTabelaValor.nome }, ...prevTabelaValores]);
      setNewTabelaValor('');
    }
  }, [newTabelaValor]);

  useEffect(() => {
    (async () => {
      try {
        if (params?.id) {
          setLoading({
            loading: true,
            message: 'Aguarde... Carregando informações do profissional... ',
          });
          const { data } = await api.get(`/profissionais/${params.id}`);
          // console.log(data);
          setValidationErrors({});
          setId(data?._id);
          setDadosPessoaisOpen(false);
          setTipo(data?.tipo || '');
          setTipoContrato(data?.tipoContrato || '');
          setRegime(data?.regime || '');
          setGrupo(data?.grupo ? { value: data.grupo._id, label: data.grupo.nome } : '');
          setImage(data?.image ? data.image_url : '');
          setName(data?.name || '');
          setRazaoSocial(data?.razaoSocial || '');
          setCpf(data?.cpf || '');
          setCnpj(data?.cnpj || '');
          setRg(data?.rg?.numero || '');
          setRgEmissor(data?.rg?.emissor || '');
          setRgEmissao(data?.rg?.emissao ? parseISO(data?.rg?.emissao) : '');
          setFiliacaoPai(data?.filiacao?.pai || '');
          setFiliacaoMae(data?.filiacao?.mae || '');
          setNascimento(data?.nascimento ? parseISO(data?.nascimento) : '');
          setTituloEleitor(data?.tituloEleitor?.numero || '');
          setTituloEleitorZona(data?.tituloEleitor?.zona || '');
          setTituloEleitorSecao(data?.tituloEleitor?.secao || '');
          setCtps(data?.ctps?.numero || '');
          setCtpsSerie(data?.ctps?.serie || '');
          setCtpsEmissao(data?.ctps?.emissao ? parseISO(data.ctps.emissao) : '');
          setCtpsPis(data?.ctps?.pis || '');
          setOrgaoEmissorClasse(data.orgaoEmissorClasse || '');
          setCnh(data?.cnh?.numero || '');
          setCnhCategoria(data?.cnh?.categoria || '');
          setCnhVencimento(data?.cnh?.vencimento ? parseISO(data.cnh.vencimento) : '');
          setReservista(data?.reservista || '');
          setEstadoCivil(data.estadoCivil || 'SOLTEIRO');
          setConjuge(data.conjuge || '');
          setCorDeclarada(data.corDeclarada || 'NÃO INFORMADA');
          setEscolaridade(data.escolaridade || '');
          setNaturalidade(data.naturalidade || '');
          setGenero(data.genero || '');
          setEstagio(data?.estagio ? {
            ...data.estagio,
            concedente: {
              ...data.estagio.concedente,
              representante: data?.estagio?.concedente?.representante ? { value: data?.estagio?.concedente?.representante._id, label: data?.estagio?.concedente?.representante.name } : '',
              supervisor: data?.estagio?.concedente?.supervisor ? { value: data?.estagio?.concedente?.supervisor._id, label: data?.estagio?.concedente?.supervisor.name } : '',
            },
            estagiario: {
              ...data.estagio.estagiario,
              cursoInicio: data?.estagio?.estagiario?.cursoInicio ? new Date(data.estagio.estagiario.cursoInicio) : null,
              cursoTermino: data?.estagio?.estagiario?.cursoTermino ? new Date(data.estagio.estagiario.cursoTermino) : null,
            },
            companhiaEstagios: {
              ...data.estagio.companhiaEstagios,
              representante: data?.estagio?.companhiaEstagios?.representante ? { value: data?.estagio?.companhiaEstagios?.representante._id, label: data?.estagio?.companhiaEstagios?.representante.name } : '',
            },
          } : {});
          if (data?.tipoContrato === 'FUN_EST_CLT') {
            enderecoEstagioInsEnsRef?.current?.setData(data?.estagio?.instituicaoEnsino?.endereco);
            enderecoEstagioConRef?.current?.setData(data?.estagio?.concedente?.endereco);
            enderecoEstagioComEstRef?.current?.setData(data?.estagio?.companhiaEstagios?.endereco);
          }
          setEmail(data.email);
          setWhatsapp(data.whatsapp || '');
          enderecoRef.current.setData(data?.endereco);
          setResidenciaPropria(data?.residencia?.propria || true);
          setResidenciaCompradaComRecursoFGTS(data?.residencia?.compradaComRecursoFGTS || false);
          setFilhos(data?.filhos || []);
          setAdmissaoData(data?.admissao?.data ? parseISO(data.admissao.data) : '');
          setAdmissaoContratoExperiencia(data?.admissao?.contratoExperiencia || true);
          setAdmissaoPrazo(data?.admissao?.prazo || '');
          setAdmissaoDiasProrrogacao(data?.admissao?.diasProrrogacao || '');
          setFuncao(data?.funcao || '');
          setSalario(data?.salario || '');
          setHorarioTrabalhos(data?.horarioTrabalhos || []);
          setObservacoes(data?.observacoes?.map((o) => ({ ...o, createdAt: parseISO(o.createdAt) })) || []);
          setEspecialidades(data?.especialidades?.map((e) => ({ label: e.nome, nome: e.nome, value: e._id, valor: data?.tabelaValores?.[0]?.valores?.find((v) => v.especialidade._id === e._id)?.valor })) || []);
          setTabelaValores(data?.tabelaValores?.map((t) => ({ ...t, label: t.nome, value: t._id })) || []);
          setDocumentosAnexados(data?.documentosAnexados || []);
          setSolicitationDeleteAccount(data?.solicitationDeleteAccount);
          setDataAssinaturaContrato(data?.dataAssinaturaContrato ? parseISO(data?.dataAssinaturaContrato) : '');
          setDataEncerramentoContrato(data?.dataEncerramentoContrato ? parseISO(data?.dataEncerramentoContrato) : '');
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, [params?.id]);

  const handleAddFilhos = useCallback(() => {
    (async () => {
      try {
        const dataToValidation = {
          filhoNome,
          filhoNascimento,
          filhoCpf,
        };

        const schema = Yup.object().shape({
          filhoNome: Yup.string().required(
            'Informe o nome completo do filho(a)',
          ),
          filhoNascimento: Yup.string()
            .required('Informe o nascimento'),
          filhoCpf: Yup.string()
            .test('is-valid-cpf', 'CPF inválido', (value) => validateCPF(value))
            .required('Informe'),
        });

        await schema.validate(dataToValidation, {
          abortEarly: false,
        });

        setFilhos((prevFilhos = []) => {
          if (prevFilhos.find((f) => f.cpf === filhoCpf)) {
            notificationRef.current.notify({
              message: 'Filho(a) já adicionado com esse CPF',
              color: 'warning',
            });
            return prevFilhos;
          }

          setFilhoNome('');
          setFilhoNascimento('');
          setFilhoCpf('');
          setValidationErrors((prevState) => ({
            ...prevState,
            filhoNome: '',
            filhoNascimento: '',
            filhoCpf: '',
          }));

          return [
            ...prevFilhos,
            {
              nome: filhoNome,
              nascimento: filhoNascimento,
              cpf: filhoCpf,
            },
          ];
        });
      } catch (err) {
        console.log(err);
        setValidationErrors((prevState) => ({
          ...prevState,
          filhoNome: '',
          filhoNascimento: '',
          filhoCpf: '',
        }));
        if (err instanceof Yup.ValidationError) {
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors((prevState) => ({ ...prevState, ...ve }));
        }
      }
    })();
  }, [filhoNome, filhoNascimento, filhoCpf, validateCPF]);

  const handleRemoveFilho = useCallback((filho) => {
    setFilhos((prevFilhos = []) => (prevFilhos.filter((f) => f.cpf !== filho.cpf)));
  }, []);

  const handleUpdateFilho = useCallback((filho) => {
    setFilhoNome(filho.nome);
    setFilhoNascimento(new Date(filho.nascimento));
    setFilhoCpf(filho.cpf);
    setFilhos((prevFilhos = []) => (prevFilhos.filter((f) => f.cpf !== filho.cpf)));
  }, []);

  const handleAddTabelaValores = useCallback((tabelaValorSelected) => {
    (async () => {
      try {
        const data = {
          tabelaValor: tabelaValorSelected?.value ? tabelaValorSelected.value : '',
          tabelaValores,
        };

        // console.log(data);
        const schema = Yup.object().shape({
          tabelaValor: Yup.string().required('Selecione uma tabela de valores'),
          tabelaValores: Yup.array().length(0, 'É permitido escolher apenas uma tabela de valores, 👍!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setTabelaValor('');
        setTabelaValoresList((prevTabelaValores = []) => prevTabelaValores.filter((e) => e.value !== tabelaValorSelected.value));
        setTabelaValores((prevTabelaValores = []) => [...prevTabelaValores, tabelaValorSelected]);
        setValidationErrors((prevState) => ({
          ...prevState,
          tabelaValor: '',
          tabelaValores: '',
        }));
        setValidationErrorsTabelaValoresCount(0);
      } catch (err) {
        console.log(err);
        setValidationErrors((prevState) => ({
          ...prevState,
          tabelaValor: '',
          tabelaValores: '',
        }));

        if (err instanceof Yup.ValidationError) {
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;

            if (e.path === 'tabelaValores') {
              setTabelaValor('');
            }
          });
          setValidationErrors((prevState) => ({ ...prevState, ...ve }));
        }
      }
    })();
  }, [tabelaValores]);

  const handleRemoveTabelaValores = useCallback((tabelaValorData) => {
    setTabelaValoresList((prevTabelaValores = []) => (prevTabelaValores.find((tv) => tv.value === tabelaValorData.value) ? prevTabelaValores : [...prevTabelaValores, tabelaValorData].sort((a, b) => (a.label > b.label ? 1 : -1))));
    setTabelaValores((prevTabelaValores = []) => prevTabelaValores.filter((e) => e.value !== tabelaValorData.value));
  }, []);

  const handleAddEspecialidades = useCallback((especialidadeSelected) => {
    (async () => {
      try {
        const data = {
          especialidade: especialidadeSelected?.value ? especialidadeSelected.value : '',
        };

        const schema = Yup.object().shape({
          especialidade: Yup.string().required('Selecione uma especialidade'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setEspecialidade('');
        setEspecialidadesList((prevEspecialidades = []) => prevEspecialidades.filter((e) => e.value !== especialidadeSelected.value));
        setEspecialidades((prevEspecialidades = []) => [...prevEspecialidades, especialidadeSelected]);
        setValidationErrors((prevState) => ({
          ...prevState,
          especialidade: '',
          especialidades: '',
        }));
        setValidationErrorsEspecialidadesCount(0);
      } catch (err) {
        console.log(err);
        setValidationErrors((prevState) => ({
          ...prevState,
          especialidade: '',
        }));

        if (err instanceof Yup.ValidationError) {
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors((prevState) => ({ ...prevState, ...ve }));
        }
      }
    })();
  }, []);

  const handleRemoveEspecialidades = useCallback((especialidadeData) => {
    setEspecialidadesList((prevEspecialidades = []) => [...prevEspecialidades, especialidadeData].sort((a, b) => (a.label > b.label ? 1 : -1)));
    setEspecialidades((prevEspecialidades = []) => prevEspecialidades.filter((e) => e.value !== especialidadeData.value));
    setValidationErrors((prevState) => ({
      ...prevState,
      tabelaValor: '',
      tabelaValores: '',
    }));
  }, []);

  const handleAddHorarioTrabalho = useCallback(() => {
    (async () => {
      try {
        const dataToValidation = {
          horarioTrabalhoDiaSemana,
          horarioTrabalhoisDiaUtil,
          horarioTrabalhoInicio,
          horarioTrabalhoTermino,
          horarioTrabalhoIntervaloInicio,
          horarioTrabalhoIntervaloTermino,
        };

        const schema = Yup.object().shape({
          horarioTrabalhoDiaSemana: Yup.string().required('Informe esse campo'),
          horarioTrabalhoisDiaUtil: Yup.string().required('Informe esse campo'),
          horarioTrabalhoInicio: Yup.string().required('Informe esse campo'),
          horarioTrabalhoTermino: Yup.string().required('Informe esse campo'),
          horarioTrabalhoIntervaloInicio: Yup.string().required('Informe esse campo'),
          horarioTrabalhoIntervaloTermino: Yup.string().required('Informe esse campo'),
        });

        await schema.validate(dataToValidation, {
          abortEarly: false,
        });

        setHorarioTrabalhos((prevHorarioTrabalho = []) => [
          ...prevHorarioTrabalho,
          {
            diaSemana: horarioTrabalhoDiaSemana,
            isDiaUtil: horarioTrabalhoisDiaUtil,
            inicio: new Date(1990, 0, 1, getHours(horarioTrabalhoInicio), getMinutes(horarioTrabalhoInicio)).toISOString(),
            termino: new Date(1990, 0, 1, getHours(horarioTrabalhoTermino), getMinutes(horarioTrabalhoTermino)).toISOString(),
            intervalo: {
              inicio: new Date(1990, 0, 1, getHours(horarioTrabalhoIntervaloInicio), getMinutes(horarioTrabalhoIntervaloInicio)).toISOString(),
              termino: new Date(1990, 0, 1, getHours(horarioTrabalhoIntervaloTermino), getMinutes(horarioTrabalhoIntervaloTermino)).toISOString(),
            },
          },
        ]);

        setHorarioTrabalhoisDiaUtil(true);
        setValidationErrors((prevState) => ({
          ...prevState,
          horarioTrabalhoInicio: '',
          horarioTrabalhoTermino: '',
          horarioTrabalhoIntervaloInicio: '',
          horarioTrabalhoIntervaloTermino: '',
        }));
      } catch (err) {
        console.log(err);
        setValidationErrors((prevState) => ({
          ...prevState,
          horarioTrabalhoDiaSemana: '',
          horarioTrabalhoInicio: '',
          horarioTrabalhoTermino: '',
          horarioTrabalhoIntervaloInicio: '',
          horarioTrabalhoIntervaloTermino: '',
        }));
        if (err instanceof Yup.ValidationError) {
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors((prevState) => ({ ...prevState, ...ve }));
        }
      }
    })();
  }, [
    horarioTrabalhoDiaSemana,
    horarioTrabalhoisDiaUtil,
    horarioTrabalhoInicio,
    horarioTrabalhoTermino,
    horarioTrabalhoIntervaloInicio,
    horarioTrabalhoIntervaloTermino,
  ]);

  const handleRemoveHorarioTrabalho = useCallback((index) => {
    setHorarioTrabalhos((prevHorarioTrabalhos = []) => (prevHorarioTrabalhos.filter((h, i) => i !== index)));
  }, []);

  const handleUpdateHorarioTrabalho = useCallback((horarioTrabalho, index) => {
    setHorarioTrabalhoDiaSemana(horarioTrabalho.diaSemana);
    setHorarioTrabalhoisDiaUtil(horarioTrabalho.isDiaUtil);
    setHorarioTrabalhoInicio(parseISO(horarioTrabalho.inicio));
    setHorarioTrabalhoTermino(parseISO(horarioTrabalho.termino));
    setHorarioTrabalhoIntervaloInicio(parseISO(horarioTrabalho.intervalo.inicio));
    setHorarioTrabalhoIntervaloTermino(parseISO(horarioTrabalho.intervalo.termino));
    setHorarioTrabalhos((prevHorarioTrabalhos = []) => (prevHorarioTrabalhos.filter((h, i) => i !== index)));
  }, []);

  const handleAddObservacoes = useCallback(() => {
    (async () => {
      try {
        const dataToValidation = {
          observacao,
        };

        const schema = Yup.object().shape({
          observacao: Yup.string().required('Informe esse campo'),
        });

        await schema.validate(dataToValidation, {
          abortEarly: false,
        });

        setObservacoes((prevObservacoes = []) => [
          ...prevObservacoes,
          {
            observacao,
            createdAt: new Date(),
            createdByUser: {
              _id: loggedUser._id,
              name: loggedUser.name,
            },
          },
        ]);

        setObservacao('');
        setValidationErrors((prevState) => ({
          ...prevState,
          observacao: '',
        }));
      } catch (err) {
        console.log(err);
        setValidationErrors((prevState) => ({
          ...prevState,
          observacao: '',
        }));
        if (err instanceof Yup.ValidationError) {
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors((prevState) => ({ ...prevState, ...ve }));
        }
      }
    })();
  }, [observacao, loggedUser]);

  const handleRemoveObservacao = useCallback((index) => {
    setObservacoes((prevObservacoes = []) => (prevObservacoes.filter((h, i) => i !== index)));
  }, []);

  const handleUpdateObservacao = useCallback((o, index) => {
    setObservacao(o.observacao);
    setObservacoes((prevObservacoes = []) => (prevObservacoes.filter((h, i) => i !== index)));
  }, []);

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      const endereco = enderecoRef.current.getData();
      // console.log(endereco);
      const dataToValidation = {
        grupo: grupo?.value ? grupo.value : '',
        name,
        razaoSocial,
        cpf,
        cnpj,
        rg: rg?.replace(/[^\d]+/g, '') || '',
        rgEmissor,
        rgEmissao,
        tituloEleitor,
        tituloEleitorZona,
        tituloEleitorSecao,
        filiacaoPai,
        filiacaoMae,
        nascimento,
        ctps,
        ctpsSerie,
        ctpsEmissao,
        ctpsPis,
        cnh,
        orgaoEmissorClasse,
        estadoCivil,
        conjuge,
        corDeclarada,
        escolaridade,
        naturalidade,
        genero,
        estagio,
        email,
        password,
        passwordConfirmation,
        whatsapp,
        cep: endereco?.cep || '',
        logradouro: endereco?.logradouro || '',
        numero: endereco?.numero || '',
        bairro: endereco?.bairro || '',
        cidade: endereco?.cidade?.value || '',
        estado: endereco?.estado?.sigla || '',
        admissaoData,
        funcao,
        salario,
        especialidades,
        tabelaValores,
      };

      console.log(dataToValidation);

      const schemaValidation = {
        name: Yup.string().required('Informe o nome completo do especialista'),
        cpf: Yup.string()
          .test('is-valid-cpf', 'CPF inválido', (value) => validateCPF(value))
          .required('Informe este campo'),
        /* rg: Yup.string().required('Informe este campo'),
        rgEmissor: Yup.string().required('Informe este campo'),
        rgEmissao: Yup.string().required('Informe este campo'),
        nascimento: Yup.string().required('Informe este campo'),
        cnh: Yup.string().test(
          'is-valid-cnh',
          'Número de CNH inválido',
          (value) => {
            // console.log(value, validateCNH(value));
            if (!value) {
              return true;
            }
            return validateCNH(value);
          },
        ), */
        /* orgaoEmissorClasse: Yup.string().required('Informe este campo'),
        estadoCivil: Yup.string().required('Informe este campo'),
        // conjuge: Yup.string().required('Informe este campo'),
        corDeclarada: Yup.string().required('Informe este campo'),
        escolaridade: Yup.string().required('Informe este campo'),
        naturalidade: Yup.string().required('Informe este campo'),
        genero: Yup.string().required('Informe este campo'), */
        email: Yup.string()
          .email('E-mail inválido')
          .required('Informe um email para contato e login'),
        whatsapp: Yup.string()
          .test('is-valid-phone', 'Número de telefone inválido', (value) => validatePhone(value)),
        // .required('Informe este campo'),
        /* cep: Yup.string().required('Informe este campo'),
        logradouro: Yup.string().required('Informe este campo'),
        numero: Yup.string().required('Informe este campo'),
        bairro: Yup.string().required('Informe este campo'),
        cidade: Yup.string().required('Informe este campo'),
        estado: Yup.string().required('Informe este campo'), */
      };

      if (regime === 'PJ') {
        Object.assign(schemaValidation, {
          // razaoSocial: Yup.string().required('Informe o nome da razão social completa do especialista'),
          cnpj: Yup.string()
            .test('is-valid-cnpj', 'CNPJ inválido', (value) => validateCNPJ(value)),
        });
      }

      if (!id || (id && passwordConfirmation)) {
        Object.assign(schemaValidation, {
          password: Yup.string().required('Informe uma senha'),
          passwordConfirmation: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'As senhas precisam ser iguais',
          ),
        });
      }

      /* if (tipoContrato === 'FUN_CLT' || tipoContrato === 'FUN_ESP_CLT') {
        Object.assign(schemaValidation, {
          tituloEleitor: Yup.string().required('Informe este campo'),
          tituloEleitorZona: Yup.string().required('Informe este campo'),
          tituloEleitorSecao: Yup.string().required('Informe este campo'),
          // filiacaoPai: Yup.string().required('Informe este campo'),
          // filiacaoMae: Yup.string().required('Informe este campo'),
          ctps: Yup.string().required('Informe este campo'),
          ctpsSerie: Yup.string().required('Informe este campo'),
          ctpsEmissao: Yup.string().required('Informe este campo'),
          ctpsPis: Yup.string()
            .test('is-valid-pis', 'PIS inválido', (value) => validatePIS(value))
            .required('Informe este campo'),
          admissaoData: Yup.string().required('Informe este campo'),
        });

        if (tipoContrato === 'FUN_CLT') {
          Object.assign(schemaValidation, {
            funcao: Yup.string().required('Informe este campo'),
            salario: Yup.string().required('Informe este campo'),
          });
        }
      } */

      /* if (tipoContrato === 'ESP_PJ' || (tipoContrato === 'FUN_ESP_CLT' && !dataToValidation.salario && !dataToValidation.funcao)) {
        Object.assign(schemaValidation, {
          especialidades: Yup.array().min(1, 'Selecione pelo menos uma especialidade'),
          tabelaValores: Yup.array().min(1, 'Selecione pelo menos uma tabela de valores para especialista'),
        });
      } */

      /* if (tipoContrato === 'FUN_EST_CLT') {
        Object.assign(schemaValidation, {
          estagio: Yup.object().shape({
            instituicaoEnsino: Yup.object().shape({
              razaoSocial: Yup.string().required('Informe uma razão social'),
              cnpj: Yup.string().required('Informe o CNPJ da instituição de ensino'),
              nomeFantasia: Yup.string().required('Informe um nome fantasia'),
              numeroConvenio: Yup.string().required('Informe o número do convênio'),
            }),
            concedente: Yup.object().shape({
              razaoSocial: Yup.string().required('Informe uma razão social'),
              cnpj: Yup.string().required('Informe um CNPJ'),
              nomeFantasia: Yup.string().required('Informe um nome fantasia'),
              representante: Yup.object().shape({
                value: Yup.string().required('Informe um representante'),
              }),
              supervisor: Yup.object().shape({
                value: Yup.string().required('Informe um supervisor'),
              }),
              numeroConvenio: Yup.string().required('Informe o número do convênio'),
            }),
            estagiario: Yup.object().shape({
              curso: Yup.string().required('Informe o curso'),
              nivel: Yup.string().required('Informe o nível'),
              numeroMatricula: Yup.string().required('Informe o número da matrícula'),
              semestre: Yup.string().required('Informe o semestre'),
              codigoCompanhiaEstagio: Yup.string().required('Informe o código companhia de estágio'),
              cursoTermino: Yup.date().nullable(true).required('Seleciona uma data de término do curso'),
            }),
            companhiaEstagios: Yup.object().shape({
              razaoSocial: Yup.string().required('Informe uma razão social'),
              cnpj: Yup.string().required('Informe um CNPJ'),
              nomeFantasia: Yup.string().required('Informe um nome fantasia'),
              representante: Yup.object().shape({
                value: Yup.string().required('Informe um representante'),
              }),
            }),
          }),
        });
      } */

      const schema = Yup.object().shape(schemaValidation);

      await schema.validate(dataToValidation, {
        abortEarly: false,
      });

      setLoading(true);

      const data = {
        tipo,
        tipoContrato,
        regime,
        isSupervisor: tipo === 'supervisor',
        grupo: grupo?.value ? grupo.value : '',
        name,
        razaoSocial,
        cpf,
        cnpj,
        rg: {
          numero: rg?.replace(/[^\d]+/g, '') || '',
          emissor: rgEmissor,
          emissao: rgEmissao,
        },
        tituloEleitor: {
          numero: tituloEleitor,
          zona: tituloEleitorZona,
          secao: tituloEleitorSecao,
        },
        filiacao: {
          pai: filiacaoPai,
          mae: filiacaoMae,
        },
        nascimento,
        ctps: {
          numero: ctps,
          serie: ctpsSerie,
          emissao: ctpsEmissao,
          pis: ctpsPis,
        },
        orgaoEmissorClasse,
        cnh: {
          numero: cnh,
          categoria: cnhCategoria,
          vencimento: cnhVencimento,
        },
        reservista,
        estadoCivil,
        conjuge,
        corDeclarada,
        escolaridade,
        naturalidade,
        genero,
        email,
        password,
        whatsapp,
        endereco,
        residencia: {
          propria: residenciaPropria,
          compradaComRecursoFGTS: residenciaCompradaComRecursoFGTS,
        },
        filhos,
        admissao: {
          data: admissaoData,
          contratoExperiencia: admissaoContratoExperiencia,
          prazo: admissaoPrazo,
          diasProrrogacao: admissaoDiasProrrogacao,
        },
        funcao,
        salario,
        horarioTrabalhos,
        observacoes: observacoes.map((o) => ({ ...o, createdByUser: o.createdByUser._id })),
        especialidades: especialidades.map((e) => e.value),
        tabelaValores: tabelaValores.map((t) => t.value),
        dataAssinaturaContrato,
        dataEncerramentoContrato,
      };

      if (tipoContrato === 'FUN_EST_CLT') {
        Object.assign(data, {
          estagio: {
            ...estagio,
            instituicaoEnsino: {
              ...estagio.instituicaoEnsino,
              endereco: enderecoEstagioInsEnsRef?.current?.getData() || null,
            },
            concedente: {
              ...estagio.concedente,
              endereco: enderecoEstagioConRef?.current?.getData() || null,
            },
            companhiaEstagios: {
              ...estagio.companhiaEstagios,
              endereco: enderecoEstagioComEstRef?.current?.getData() || null,
            },
          },
        });
      }

      if (id) {
        update(id, '/profissionais', data, () => {
          setLoading(false);
          navigate('/profissionais');
        }, () => setLoading(false), false);
        return;
      }

      store('/profissionais', data, () => {
        setId(data._id);
        setLoading(false);
        navigate('/profissionais');
      }, () => setLoading(false), false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      setValidationErrors({});
      setValidationErrorsDadosPessoaisCount(0);
      setValidationErrorsDadosEstagioCount(0);
      setValidationErrorsContatosCount(0);
      setValidationErrorsEnderecoCount(0);
      setValidationErrorsAdmissaoCount(0);
      setValidationErrorsEspecialidadesCount(0);
      setValidationErrorsTabelaValoresCount(0);

      // Validation failed
      if (err instanceof Yup.ValidationError) {
        notificationRef.current.notify({
          message: 'Por favor, preencha todos os dados necessários para salvar',
          color: 'warning',
        });
        const ve = {};
        err.inner.forEach((e) => {
          ve[e.path] = e.message;
        });
        console.log('🚀 ~ file: index.js ~ handleSave ~ ve:', ve);
        // console.log(Object.keys(ve).length);
        Object.keys(ve).forEach((path) => {
          if (path === 'email'
            || path === 'password'
            || path === 'passwordConfirmation'
              || path === 'whatsapp') {
            setValidationErrorsContatosCount((prevState = 0) => prevState + 1);
          }

          if (path === 'cep'
            || path === 'logradouro'
            || path === 'numero'
            || path === 'bairro'
            || path === 'estado'
              || path === 'cidade') {
            setValidationErrorsEnderecoCount((prevState = 0) => prevState + 1);
          }

          if (path === 'admissaoData' || path === 'salario' || path === 'funcao') {
            setValidationErrorsAdmissaoCount((prevState = 0) => prevState + 1);
          }

          if (path === 'name'
              || path === 'razaoSocial'
              || path === 'cpf'
              || path === 'cnpj'
              || path === 'rg'
              || path === 'rgEmissor'
              || path === 'rgEmissao'
              || path === 'tituloEleitor'
              || path === 'tituloEleitorZona'
              || path === 'tituloEleitorSecao'
              || path === 'filiacaoPai'
              || path === 'filiacaoMae'
              || path === 'nascimento'
              || path === 'ctps'
              || path === 'ctpsSerie'
              || path === 'ctpsEmissao'
              || path === 'ctpsPis'
              || path === 'cnh'
              || path === 'orgaoEmissorClasse'
              || path === 'estadoCivil'
              || path === 'conjuge'
              || path === 'corDeclarada'
              || path === 'escolaridade'
              || path === 'naturalidade'
              || path === 'genero'
          ) {
            setValidationErrorsDadosPessoaisCount((prevState = 0) => prevState + 1);
          }

          if (path.startsWith('estagio')) {
            setValidationErrorsDadosEstagioCount((prevState = 0) => prevState + 1);
          }

          if (path === 'especialidades') {
            setValidationErrorsEspecialidadesCount((prevState = 0) => prevState + 1);
          }

          if (path === 'tabelaValores') {
            setValidationErrorsTabelaValoresCount((prevState = 0) => prevState + 1);
          }
        });
        setValidationErrors((prevState) => ({ ...prevState, ...ve }));
        return;
      }

      if (err && err.response && err.response.data) {
        const { message } = err.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });

        return;
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível salvar',
        color: 'danger',
      });
    }
  };

  const renderTipoLabel = useCallback(() => {
    if (id) {
      if (tipo === 'interno') {
        return (
          <h3>{`Funcionário(a) Interno ${name}`}</h3>
        );
      }

      if (tipo === 'estagiario') {
        return (
          <h3>{`Estagiário(a) ${name}`}</h3>
        );
      }

      if (tipo === 'estagiario-pj') {
        return (
          <h3>{`Estagiário(a) PJ ${name}`}</h3>
        );
      }

      if (tipo === 'estagiario-optimiza') {
        return (
          <h3>{`Estagiário(a) Optimiza ${name}`}</h3>
        );
      }

      if (tipo === 'especialista') {
        return (
          <h3>{`Especialista ${name}`}</h3>
        );
      }

      if (tipo === 'especialista-pj') {
        return (
          <h3>{`Especialista PJ ${name}`}</h3>
        );
      }

      if (tipo === 'supervisor') {
        return (
          <h3>{`Supervisor ${name}`}</h3>
        );
      }

      if (tipo === 'tecnico') {
        return (
          <h3>{`Técnico ${name}`}</h3>
        );
      }

      if (tipo === 'treinee') {
        return (
          <h3>{`Treinee ${name}`}</h3>
        );
      }

      if (tipo === 'terapeuta') {
        return (
          <h3>{`Terapeuta ${name}`}</h3>
        );
      }

      if (tipo === 'outros') {
        return (
          <h3>{`Outros ${name}`}</h3>
        );
      }

      return <h3>{`${name}`}</h3>;
    }

    if (tipo === 'interno') {
      return (
        <h3>{`Formulário de Admissão de Funcionário(a) Interno ${name}`}</h3>
      );
    }

    if (tipo === 'estagiario') {
      return (
        <h3>{`Formulário de Admissão de Estagiário(a) ${name}`}</h3>
      );
    }

    if (tipo === 'estagiario-pj') {
      return (
        <h3>{`Cadastro de Estagiário(a) PJ ${name}`}</h3>
      );
    }

    if (tipo === 'estagiario-optimiza') {
      return (
        <h3>{`Formulário de Admissão de Estagiário(a) Optimiza ${name}`}</h3>
      );
    }

    if (tipo === 'especialista') {
      return (
        <h3>{`Formulário de Admissão de Especialista ${name}`}</h3>
      );
    }

    if (tipo === 'especialista-pj') {
      return (
        <h3>{`Cadastro de Especialista PJ ${name}`}</h3>
      );
    }

    if (tipo === 'supervisor') {
      return (
        <h3>{`Formulário de Admissão de Supervisor ${name}`}</h3>
      );
    }

    if (tipo === 'tecnico') {
      return (
        <h3>{`Cadastro de Técnico ${name}`}</h3>
      );
    }

    if (tipo === 'treinee') {
      return (
        <h3>{`Formulário de Admissão de Treinee ${name}`}</h3>
      );
    }

    if (tipo === 'terapeuta') {
      return (
        <h3>{`Cadastro de Terapeuta ${name}`}</h3>
      );
    }

    if (tipo === 'outros') {
      return (
        <h3>{`Formulário de Admissão de Outros ${name}`}</h3>
      );
    }

    return <h3>{`${name}`}</h3>;
  }, [tipo, id, name]);

  return (
    <Container fluid>
      <Row className="my-5">
        <Col>
          <Notification ref={notificationRef} />
          <Card className="shadow">
            <CardBody>
              {renderTipoLabel()}
            </CardBody>
          </Card>
          {image && (
          <Card>
            <CardBody>
              <div
                style={{
                  width: '150px',
                  height: '150px',
                  backgroundSize: 'cover',
                  backgroundImage: `url(${image})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              />
            </CardBody>
          </Card>
          )}
          <Form onSubmit={handleSave}>
            <Row className="mt-3">
              <Col xs={12} sm={4} md={3}>
                <FormGroup check>
                  <Input
                    type="radio"
                    checked={tipo === 'interno'}
                    onChange={() => {
                      setTipo('interno');
                      setTipoContrato('FUN_CLT');
                      setRegime('CLT');
                    }}
                  />
                  <Label check>CLT Interno</Label>
                </FormGroup>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <FormGroup check>
                  <Input
                    type="radio"
                    checked={tipo === 'estagiario'}
                    onChange={() => {
                      setTipo('estagiario');
                      setTipoContrato('FUN_EST_CLT');
                      setRegime('CLT');
                    }}
                  />
                  <Label check>Estagiário</Label>
                </FormGroup>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <FormGroup check>
                  <Input
                    type="radio"
                    checked={tipo === 'estagiario-pj'}
                    onChange={() => {
                      setTipo('estagiario-pj');
                      setTipoContrato('EST_PJ');
                      setRegime('PJ');
                    }}
                  />
                  <Label check>Estagiário PJ</Label>
                </FormGroup>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <FormGroup check>
                  <Input
                    type="radio"
                    checked={tipo === 'estagiario-optimiza'}
                    onChange={() => {
                      setTipo('estagiario-optimiza');
                      setTipoContrato('EST_PJ');
                      setRegime('PJ');
                    }}
                  />
                  <Label check>Estagiário Optimiza</Label>
                </FormGroup>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <FormGroup check>
                  <Input
                    type="radio"
                    checked={tipo === 'especialista'}
                    onChange={() => {
                      setTipo('especialista');
                      setTipoContrato('FUN_ESP_CLT');
                      setRegime('CLT');
                    }}
                  />
                  <Label check>Especialista CLT</Label>
                </FormGroup>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <FormGroup check>
                  <Input
                    type="radio"
                    checked={tipo === 'especialista-pj'}
                    onChange={() => {
                      setTipo('especialista-pj');
                      setTipoContrato('ESP_PJ');
                      setRegime('PJ');
                    }}
                  />
                  <Label check>Especialista</Label>
                </FormGroup>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <FormGroup check>
                  <Input
                    type="radio"
                    checked={tipo === 'supervisor'}
                    onChange={() => {
                      setTipo('supervisor');
                      setTipoContrato('FUN_CLT');
                      setRegime('CLT');
                    }}
                  />
                  <Label check>Supervisor</Label>
                </FormGroup>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <FormGroup check>
                  <Input
                    type="radio"
                    checked={tipo === 'tecnico'}
                    onChange={() => {
                      setTipo('tecnico');
                      setTipoContrato('ESP_PJ');
                      setRegime('PJ');
                    }}
                  />
                  <Label check>Técnico</Label>
                </FormGroup>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <FormGroup check>
                  <Input
                    type="radio"
                    checked={tipo === 'treinee'}
                    onChange={() => {
                      setTipo('treinee');
                      setTipoContrato('FUN_CLT');
                      setRegime('CLT');
                    }}
                  />
                  <Label check>Treinee</Label>
                </FormGroup>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <FormGroup check>
                  <Input
                    type="radio"
                    checked={tipo === 'terapeuta'}
                    onChange={() => {
                      setTipo('terapeuta');
                      setTipoContrato('ESP_PJ');
                      setRegime('PJ');
                    }}
                  />
                  <Label check>Terapeuta</Label>
                </FormGroup>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <FormGroup check>
                  <Input
                    type="radio"
                    checked={tipo === 'outros'}
                    onChange={() => {
                      setTipo('outros');
                      setTipoContrato('OUT');
                      setRegime('OUT');
                    }}
                  />
                  <Label check>Outros</Label>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Button color="primary" id="toggler-grupo" block>
                  Grupo
                </Button>
                <UncontrolledCollapse toggler="#toggler-grupo">
                  <Card>
                    <CardBody>
                      <Row className="my-3">
                        <Col>
                          <CustomSelect
                            label="Grupo"
                            placeholder="Selecione um grupo"
                            value={grupo}
                            options={grupos}
                            onChange={setGrupo}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Button
                  color="primary"
                  onClick={() => setDadosPessoaisOpen((prevState) => !prevState)}
                  block
                >
                  Dados Pessoais
                  <ValidationErrorsCount
                    count={validationErrorsDadosPessoaisCount}
                  />
                </Button>
                <Collapse isOpen={dadosPessoaisOpen}>
                  <Card>
                    <CardBody>
                      <FormGroup>
                        <Label for="nome">Nome</Label>
                        <Input
                          id="nome"
                          type="text"
                          placeholder="Nome"
                          value={name}
                          invalid={!!validationErrors.name}
                          onChange={({ target: { value } }) => setName(value)}
                        />
                        <FormFeedback>{validationErrors.name}</FormFeedback>
                        <FormText>
                          {`${tipoContrato === 'FUN_CLT'
                            ? 'Nome Completo do Funcionário'
                            : tipoContrato === 'FUN_EST_CLT'
                              ? 'Nome Completo do Funcionário Estagiário'
                              : tipoContrato === 'FUN_ESP_CLT'
                                ? 'Nome Completo do Funcionário Especialista'
                                : tipoContrato === 'EST_PJ'
                                  ? 'Nome Completo do Estagiário'
                                  : tipoContrato === 'ESP_PJ'
                                    ? 'Nome Completo do Especialista'
                                    : 'Nome Completo'}`}
                        </FormText>
                      </FormGroup>
                      <FormGroup>
                        <Label for="razaoSocial">Razão Social</Label>
                        <Input
                          id="razaoSocial"
                          type="text"
                          placeholder="Razão Social"
                          name="razaoSocial"
                          value={razaoSocial}
                          onChange={({ target: { value } }) => setRazaoSocial(value)}
                          invalid={!!validationErrors?.razaoSocial}
                          disabled={regime !== 'PJ' && tipo !== 'supervisor'}
                        />
                        <FormFeedback>{validationErrors?.razaoSocial}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label for="cpf">CPF</Label>
                        <PatternFormat
                          customInput={Input}
                          id="cpf"
                          format="###.###.###-##"
                          mask="_"
                          invalid={!!validationErrors.cpf}
                          valueIsNumericString
                          type="text"
                          placeholder="CPF"
                          value={cpf}
                          onValueChange={({ value }) => setCpf(value)}
                        />
                        <FormFeedback>{validationErrors.cpf}</FormFeedback>
                      </FormGroup>
                      <CustomMaskedInput
                        id="cnpj"
                        label="CNPJ"
                        placeholder="Informe um CNPJ"
                        format="##.###.###/####-##"
                        name="cnpj"
                        value={cnpj}
                        onValueChange={({ value: v }) => setCnpj(v)}
                        invalidMessage={validationErrors?.cnpj}
                        disabled={regime !== 'PJ' && tipo !== 'supervisor'}
                      />
                      <Row>
                        <Col xs={12} sm={6}>
                          <InputMask
                            mask="99.999.999-**"
                            value={rg}
                            onChange={({ target: { value } }) => setRg(value)}
                          >
                            {(inputProps) => (
                              <FormGroup>
                                <Label for="rg">RG</Label>
                                <Input
                                  id="rg"
                                  type="text"
                                  placeholder="RG"
                                  invalid={!!validationErrors.rg}
                                  {...inputProps}
                                />
                                <FormFeedback>
                                  {validationErrors.rg}
                                </FormFeedback>
                              </FormGroup>
                            )}
                          </InputMask>
                        </Col>
                        <Col xs={12} sm={3}>
                          <FormGroup>
                            <Label for="rg-emissor">Emissor</Label>
                            <Input
                              id="rg-emissor"
                              type="text"
                              placeholder="Emissor"
                              value={rgEmissor}
                              invalid={!!validationErrors.rgEmissor}
                              onChange={({ target: { value } }) => setRgEmissor(value)}
                            />
                            <FormFeedback>
                              {validationErrors.rgEmissor}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={3}>
                          <DatePickerMaskedInput
                            label="Emissão"
                            placeholderText="Emissão"
                            selected={rgEmissao}
                            invalidMessage={validationErrors.rgEmissao}
                            yearsRange={range(
                              1900,
                              getYear(new Date()) + 1,
                              1,
                            )}
                            onChange={(date) => setRgEmissao(date)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={6}>
                          <FormGroup>
                            <Label for="titulo-eleitor">
                              Título de Eleitor
                            </Label>
                            <PatternFormat
                              customInput={Input}
                              id="titulo-eleitor"
                              format="####.####.####"
                              mask="_"
                              valueIsNumericString
                              type="text"
                              placeholder="Título de Eleitor"
                              invalid={!!validationErrors.tituloEleitor}
                              value={tituloEleitor}
                              onValueChange={({ value }) => setTituloEleitor(value)}
                              disabled={tipoContrato === 'ESP_PJ'}
                            />
                            <FormFeedback>
                              {validationErrors.tituloEleitor}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={3}>
                          <FormGroup>
                            <Label for="titulo-eleitor-zona">Zona</Label>
                            <PatternFormat
                              customInput={Input}
                              id="titulo-eleitor-zona"
                              format="###"
                              mask="_"
                              valueIsNumericString
                              type="text"
                              placeholder="Zona"
                              invalid={
                                      !!validationErrors.tituloEleitorZona
                                    }
                              value={tituloEleitorZona}
                              onValueChange={({ value }) => setTituloEleitorZona(value)}
                              disabled={tipoContrato === 'ESP_PJ'}
                            />
                            <FormFeedback>
                              {validationErrors.tituloEleitorZona}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={3}>
                          <FormGroup>
                            <Label for="titulo-eleitor-secao">
                              Seção
                            </Label>
                            <PatternFormat
                              customInput={Input}
                              id="titulo-eleitor-secao"
                              format="####"
                              mask="_"
                              valueIsNumericString
                              type="text"
                              placeholder="Seção"
                              invalid={!!validationErrors.tituloEleitorSecao}
                              value={tituloEleitorSecao}
                              onValueChange={({ value }) => setTituloEleitorSecao(value)}
                              disabled={tipoContrato === 'ESP_PJ'}
                            />
                            <FormFeedback>
                              {validationErrors.tituloEleitorSecao}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Label for="filiacao-pai">
                          Nome Completo do Pai
                        </Label>
                        <Input
                          id="filiacao-pai"
                          type="text"
                          placeholder="Nome Completo do Pai"
                          value={filiacaoPai}
                          invalid={!!validationErrors.filiacaoPai}
                          onChange={({ target: { value } }) => setFiliacaoPai(value)}
                          disabled={tipoContrato === 'ESP_PJ'}
                        />
                        <FormFeedback>
                          {validationErrors.filiacaoPai}
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label for="filiacao-mae">
                          Nome Completo da Mãe
                        </Label>
                        <Input
                          id="filiacao-mae"
                          type="text"
                          placeholder="Nome Completo da Mãe"
                          value={filiacaoMae}
                          invalid={!!validationErrors.filiacaoMae}
                          onChange={({ target: { value } }) => setFiliacaoMae(value)}
                          disabled={tipoContrato === 'ESP_PJ'}
                        />
                        <FormFeedback>
                          {validationErrors.filiacaoMae}
                        </FormFeedback>
                      </FormGroup>
                      <DatePickerMaskedInput
                        label="Nascimento"
                        placeholderText="Nascimento"
                        selected={nascimento}
                        invalidMessage={validationErrors.nascimento}
                        yearsRange={range(1900, getYear(new Date()) + 1, 1)}
                        maxDate={new Date()}
                        openToDate={new Date('2000/01/01')}
                        onChange={(date) => setNascimento(date)}
                      />
                      <Row>
                        <Col xs={12} sm={3}>
                          <FormGroup>
                            <Label for="ctps">CTPS</Label>
                            <PatternFormat
                              customInput={Input}
                              id="ctps"
                              format="#######"
                              mask="_"
                              valueIsNumericString
                              type="text"
                              placeholder="CTPS"
                              invalid={!!validationErrors.ctps}
                              value={ctps}
                              onValueChange={({ value }) => setCtps(value)}
                              disabled={tipoContrato === 'ESP_PJ'}
                            />
                            <FormFeedback>
                              {validationErrors.ctps}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={2}>
                          <FormGroup>
                            <Label for="ctps-serie">Série</Label>
                            <PatternFormat
                              customInput={Input}
                              id="ctps-serie"
                              format="####"
                              mask="_"
                              valueIsNumericString
                              type="text"
                              placeholder="Série"
                              invalid={!!validationErrors.ctpsSerie}
                              value={ctpsSerie}
                              onValueChange={({ value }) => setCtpsSerie(value)}
                              disabled={tipoContrato === 'ESP_PJ'}
                            />
                            <FormFeedback>
                              {validationErrors.ctpsSerie}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={3}>
                          <DatePickerMaskedInput
                            label="Emissão"
                            placeholderText="Emissão"
                            selected={ctpsEmissao}
                            invalidMessage={validationErrors.ctpsEmissao}
                            yearsRange={range(
                              1900,
                              getYear(new Date()) + 1,
                              1,
                            )}
                            onChange={(date) => setCtpsEmissao(date)}
                            disabled={tipoContrato === 'ESP_PJ'}
                          />
                        </Col>
                        <Col xs={12} sm={4}>
                          <FormGroup>
                            <Label for="ctps-pis">PIS</Label>
                            <PatternFormat
                              customInput={Input}
                              id="ctps-pis"
                              format="###.#####.##-#"
                              mask="_"
                              valueIsNumericString
                              type="text"
                              placeholder="PIS"
                              invalid={!!validationErrors.ctpsPis}
                              value={ctpsPis}
                              onValueChange={({ value }) => setCtpsPis(value)}
                              disabled={tipoContrato === 'ESP_PJ'}
                            />
                            <FormFeedback>
                              {validationErrors.ctpsPis}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Label for="orgao-emissor-classe">
                          Registro em órgão de Classe
                        </Label>
                        <Input
                          id="orgao-emissor-classe"
                          type="text"
                          placeholder="Registro em órgão de Classe"
                          value={orgaoEmissorClasse}
                          invalid={!!validationErrors.orgaoEmissorClasse}
                          onChange={({ target: { value } }) => setOrgaoEmissorClasse(value)}
                        />
                        <FormFeedback>
                          {validationErrors.orgaoEmissorClasse}
                        </FormFeedback>
                      </FormGroup>
                      <Row>
                        <Col xs={12} sm={6}>
                          <FormGroup>
                            <Label for="cnh">CNH</Label>
                            <PatternFormat
                              customInput={Input}
                              id="cnh"
                              format="###########"
                              mask="_"
                              valueIsNumericString
                              type="text"
                              invalid={!!validationErrors.cnh}
                              placeholder="CNH"
                              value={cnh}
                              onValueChange={({ value }) => setCnh(value)}
                            />
                            <FormFeedback>
                              {validationErrors.cnh}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={3}>
                          <FormGroup>
                            <Label for="cnh-categoria">Categoria</Label>
                            <Input
                              id="cnh-categoria"
                              type="text"
                              placeholder="Categoria"
                              value={cnhCategoria}
                              onChange={({ target: { value } }) => setCnhCategoria(value)}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={3}>
                          <DatePickerMaskedInput
                            label="Vencimento"
                            placeholderText="Vencimento"
                            selected={cnhVencimento}
                            onChange={(date) => setCnhVencimento(date)}
                          />
                        </Col>
                      </Row>
                      <FormGroup>
                        <Label for="reservista">
                          Certificado de Reservista
                        </Label>
                        <PatternFormat
                          customInput={Input}
                          id="reservista"
                          format="############"
                          mask="_"
                          valueIsNumericString
                          type="text"
                          placeholder="Certificado de Reservista"
                          value={reservista}
                          onValueChange={({ value }) => setReservista(value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="estado-civil">Estado Civil</Label>
                        <Input
                          id="estado-civil"
                          type="select"
                          placeholder="estado-civil"
                          value={estadoCivil}
                          invalid={!!validationErrors.estadoCivil}
                          onChange={({ target: { value } }) => setEstadoCivil(value)}
                        >
                          <option value="SOLTEIRO">SOLTEIRO</option>
                          <option value="CASADO">CASADO</option>
                          <option value="DIVORCIADO">DIVORCIADO</option>
                          <option value="VIÚVO">VIÚVO</option>
                          <option value="OUTRO">OUTRO</option>
                        </Input>
                        <FormFeedback>
                          {validationErrors.estadoCivil}
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label for="conjuge">
                          Nome Completo do(a) cônjuge
                        </Label>
                        <Input
                          id="conjuge"
                          type="text"
                          placeholder="Nome Completo do(a) cônjuge"
                          value={conjuge}
                          invalid={!!validationErrors.conjuge}
                          onChange={({ target: { value } }) => setConjuge(value)}
                        />
                        <FormFeedback>
                          {validationErrors.conjuge}
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label for="escolaridade">Escolaridade</Label>
                        <Input
                          id="escolaridade"
                          type="select"
                          placeholder="Escolaridade"
                          value={escolaridade}
                          invalid={!!validationErrors.escolaridade}
                          onChange={({ target: { value } }) => setEscolaridade(value)}
                        >
                          <option value="">NÃO INFORMADO</option>
                          <option value="FUNDAMENTAL INCOMPLETO">
                            FUNDAMENTAL INCOMPLETO
                          </option>
                          <option value="FUNDAMENTAL CURSANDO">
                            FUNDAMENTAL CURSANDO
                          </option>
                          <option value="FUNDAMENTAL COMPLETO">
                            FUNDAMENTAL COMPLETO
                          </option>
                          <option value="MÉDIO INCOMPLETO">
                            MÉDIO INCOMPLETO
                          </option>
                          <option value="MÉDIO CURSANDO">
                            MÉDIO CURSANDO
                          </option>
                          <option value="MÉDIO COMPLETO">
                            MÉDIO COMPLETO
                          </option>
                          <option value="SUPERIOR INCOMPLETO">
                            SUPERIOR INCOMPLETO
                          </option>
                          <option value="SUPERIOR CURSANDO">
                            SUPERIOR CURSANDO
                          </option>
                          <option value="SUPERIOR COMPLETO">
                            SUPERIOR COMPLETO
                          </option>
                          <option value="PÓS-GRADUAÇÃO">PÓS-GRADUAÇÃO</option>
                          <option value="MESTRADO">MESTRADO</option>
                          <option value="DOUTORADO">DOUTORADO</option>
                        </Input>
                        <FormFeedback>
                          {validationErrors.escolaridade}
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label for="naturalidade">Naturalidade</Label>
                        <Input
                          id="naturalidade"
                          type="text"
                          placeholder="Naturalidade"
                          value={naturalidade}
                          invalid={!!validationErrors.naturalidade}
                          onChange={({ target: { value } }) => setNaturalidade(value)}
                        />
                        <FormFeedback>
                          {validationErrors.naturalidade}
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label for="corDeclarada">Cor Declarada</Label>
                        <Input
                          id="corDeclarada"
                          type="select"
                          placeholder="corDeclarada"
                          value={corDeclarada}
                          invalid={!!validationErrors.corDeclarada}
                          onChange={({ target: { value } }) => setCorDeclarada(value)}
                        >
                          <option value="INDÍGENA">INDÍGENA</option>
                          <option value="BRANCA">BRANCA</option>
                          <option value="NEGRA">NEGRA</option>
                          <option value="PARDA">PARDA</option>
                          <option value="NÃO INFORMADA">NÃO INFORMADA</option>
                        </Input>
                        <FormFeedback>
                          {validationErrors.corDeclarada}
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label for="genero">Sexo</Label>
                        <Input
                          id="genero"
                          type="select"
                          placeholder="genero"
                          value={genero}
                          invalid={!!validationErrors.genero}
                          onChange={({ target: { value } }) => setGenero(value)}
                        >
                          <option value="">NÃO INFORMADO</option>
                          <option value="MASCULINO">MASCULINO</option>
                          <option value="FEMININO">FEMININO</option>
                          <option value="OUTROS">OUTROS</option>
                        </Input>
                        <FormFeedback>{validationErrors.genero}</FormFeedback>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Collapse>
              </Col>
            </Row>
            {tipoContrato === 'FUN_EST_CLT' && (
              <Row className="mt-3">
                <Col>
                  <Button color="primary" id="toggler-dados-estagio" block>
                    Dados do Estágio
                    <ValidationErrorsCount
                      count={validationErrorsDadosEstagioCount}
                    />
                  </Button>
                  <UncontrolledCollapse toggler="#toggler-dados-estagio">
                    <Card>
                      <CardBody>
                        <Tabs forceRenderTabPanel>
                          <TabList>
                            <Tab>Instituição de Ensino</Tab>
                            <Tab>Concedente</Tab>
                            <Tab>Estagiário</Tab>
                            <Tab>Companhia de Estágios</Tab>
                          </TabList>

                          <TabPanel>
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label for="estagio-ins-ens-razao-social">
                                        Razão Social
                                      </Label>
                                      <Input
                                        id="estagio-ins-ens-razao-social"
                                        type="text"
                                        placeholder="Razão Social"
                                        value={estagio?.instituicaoEnsino?.razaoSocial || ''}
                                        invalid={!!validationErrors['estagio.instituicaoEnsino.razaoSocial']}
                                        onChange={({ target: { value } }) => setEstagio((e) => ({ ...e, instituicaoEnsino: { ...e?.instituicaoEnsino, razaoSocial: value } }))}
                                      />
                                      <FormFeedback>
                                        {validationErrors['estagio.instituicaoEnsino.razaoSocial']}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} sm={6}>
                                    <FormGroup>
                                      <Label for="estagio-ins-ens-nome-fantasia">
                                        Nome Fantasia
                                      </Label>
                                      <Input
                                        id="estagio-ins-ens-nome-fantasia"
                                        type="text"
                                        placeholder="Nome Fantasia"
                                        value={estagio?.instituicaoEnsino?.nomeFantasia || ''}
                                        invalid={!!validationErrors['estagio.instituicaoEnsino.nomeFantasia']}
                                        onChange={({ target: { value } }) => setEstagio((e) => ({ ...e, instituicaoEnsino: { ...e?.instituicaoEnsino, nomeFantasia: value } }))}
                                      />
                                      <FormFeedback>
                                        {validationErrors['estagio.instituicaoEnsino.nomeFantasia']}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col xs={12} sm={6}>
                                    <FormGroup>
                                      <Label for="estagio-ins-ens-campus">
                                        Campus
                                      </Label>
                                      <Input
                                        id="estagio-ins-ens-campus"
                                        type="text"
                                        placeholder="Campus"
                                        value={estagio?.instituicaoEnsino?.campus || ''}
                                        invalid={!!validationErrors['estagio.instituicaoEnsino.campus']}
                                        onChange={({ target: { value } }) => setEstagio((e) => ({ ...e, instituicaoEnsino: { ...e?.instituicaoEnsino, campus: value } }))}
                                      />
                                      <FormFeedback>
                                        {validationErrors['estagio.instituicaoEnsino.campus']}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} sm={6}>
                                    <CustomMaskedInput
                                      id="estagio-ins-ens-cnpj"
                                      label="CNPJ"
                                      placeholder="Informe um CNPJ"
                                      format="##.###.###/####-##"
                                      value={estagio?.instituicaoEnsino?.cnpj || ''}
                                      onValueChange={({ value }) => setEstagio((e) => ({ ...e, instituicaoEnsino: { ...e?.instituicaoEnsino, cnpj: value } }))}
                                      invalidMessage={validationErrors['estagio.instituicaoEnsino.cnpj']}
                                    />
                                  </Col>
                                  <Col xs={12} sm={6}>
                                    <FormGroup>
                                      <Label for="estagio-ins-ens-ie">
                                        Inscrição Estadual
                                      </Label>
                                      <Input
                                        id="estagio-ins-ens-ie"
                                        type="number"
                                        placeholder="IE"
                                        value={estagio?.instituicaoEnsino?.ie || ''}
                                        invalid={!!validationErrors['estagio.instituicaoEnsino.ie']}
                                        onChange={({ target: { value } }) => setEstagio((e) => ({ ...e, instituicaoEnsino: { ...e?.instituicaoEnsino, ie: value } }))}
                                      />
                                      <FormFeedback>
                                        {validationErrors['estagio.instituicaoEnsino.ie']}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row className="flex-sm-row-reverse">
                                  <Col xs={12} sm={6}>
                                    <FormGroup>
                                      <Label for="estagio-ins-ens-im">
                                        Inscrição Municipal
                                      </Label>
                                      <Input
                                        id="estagio-ins-ens-im"
                                        type="number"
                                        placeholder="IM"
                                        value={estagio?.instituicaoEnsino?.im || ''}
                                        invalid={!!validationErrors['estagio.instituicaoEnsino.im']}
                                        onChange={({ target: { value } }) => setEstagio((e) => ({ ...e, instituicaoEnsino: { ...e?.instituicaoEnsino, im: value } }))}
                                      />
                                      <FormFeedback>
                                        {validationErrors['estagio.instituicaoEnsino.im']}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col xs={12} sm={6}>
                                    <CustomMaskedInput
                                      id="estagio-ins-ens-telefone"
                                      label="Telefone"
                                      placeholder="Informe um telefone"
                                      format="(##) #####-####"
                                      value={estagio?.instituicaoEnsino?.telefone || ''}
                                      onValueChange={({ value }) => setEstagio((e) => ({ ...e, instituicaoEnsino: { ...e?.instituicaoEnsino, telefone: value } }))}
                                      invalidMessage={validationErrors['estagio.instituicaoEnsino.telefone']}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} sm={6}>
                                    <FormGroup>
                                      <Label for="estagio-ins-ens-numero-convenio">
                                        Número Convênio
                                      </Label>
                                      <Input
                                        id="estagio-ins-ens-numero-convenio"
                                        type="text"
                                        placeholder="Número do Convênio"
                                        value={estagio?.instituicaoEnsino?.numeroConvenio || ''}
                                        invalid={!!validationErrors['estagio.instituicaoEnsino.numeroConvenio']}
                                        onChange={({ target: { value } }) => setEstagio((e) => ({ ...e, instituicaoEnsino: { ...e?.instituicaoEnsino, numeroConvenio: value } }))}
                                      />
                                      <FormFeedback>
                                        {validationErrors['estagio.instituicaoEnsino.numeroConvenio']}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <Endereco
                                      id="estagio-ins-ens-endereco"
                                      ref={enderecoEstagioInsEnsRef}
                                    />
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </TabPanel>
                          <TabPanel>
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label for="estagio-con-razao-social">
                                        Razão Social
                                      </Label>
                                      <Input
                                        id="estagio-con-razao-social"
                                        type="text"
                                        placeholder="Razão Social"
                                        value={estagio?.concedente?.razaoSocial || ''}
                                        invalid={!!validationErrors['estagio.concedente.razaoSocial']}
                                        onChange={({ target: { value } }) => setEstagio((e) => ({ ...e, concedente: { ...e?.concedente, razaoSocial: value } }))}
                                      />
                                      <FormFeedback>
                                        {validationErrors['estagio.concedente.razaoSocial']}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label for="estagio-con-nome-fantasia">
                                        Nome Fantasia
                                      </Label>
                                      <Input
                                        id="estagio-con-nome-fantasia"
                                        type="text"
                                        placeholder="Nome Fantasia"
                                        value={estagio?.concedente?.nomeFantasia || ''}
                                        invalid={!!validationErrors['estagio.concedente.nomeFantasia']}
                                        onChange={({ target: { value } }) => setEstagio((e) => ({ ...e, concedente: { ...e?.concedente, nomeFantasia: value } }))}
                                      />
                                      <FormFeedback>
                                        {validationErrors['estagio.concedente.nomeFantasia']}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} sm={6}>
                                    <CustomMaskedInput
                                      id="estagio-con-cnpj"
                                      label="CNPJ"
                                      placeholder="Informe um CNPJ"
                                      format="##.###.###/####-##"
                                      value={estagio?.concedente?.cnpj || ''}
                                      onValueChange={({ value }) => setEstagio((e) => ({ ...e, concedente: { ...e?.concedente, cnpj: value } }))}
                                      invalidMessage={validationErrors['estagio.concedente.cnpj']}
                                    />
                                  </Col>
                                  <Col xs={12} sm={6}>
                                    <FormGroup>
                                      <Label for="estagio-con-ie">
                                        Inscrição Estadual
                                      </Label>
                                      <Input
                                        id="estagio-con-ie"
                                        type="number"
                                        placeholder="IE"
                                        value={estagio?.concedente?.ie || ''}
                                        invalid={!!validationErrors['estagio.concedente.ie']}
                                        onChange={({ target: { value } }) => setEstagio((e) => ({ ...e, concedente: { ...e?.concedente, ie: value } }))}
                                      />
                                      <FormFeedback>
                                        {validationErrors['estagio.concedente.ie']}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row className="flex-sm-row-reverse">
                                  <Col xs={12} sm={6}>
                                    <FormGroup>
                                      <Label for="estagio-con-im">
                                        Inscrição Municipal
                                      </Label>
                                      <Input
                                        id="estagio-con-im"
                                        type="number"
                                        placeholder="IM"
                                        value={estagio?.concedente?.im || ''}
                                        invalid={!!validationErrors['estagio.concedente.im']}
                                        onChange={({ target: { value } }) => setEstagio((e) => ({ ...e, concedente: { ...e?.concedente, im: value } }))}
                                      />
                                      <FormFeedback>
                                        {validationErrors['estagio.concedente.im']}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col xs={12} sm={6}>
                                    <CustomMaskedInput
                                      id="estagio-con-telefone"
                                      label="Telefone"
                                      placeholder="Informe um telefone"
                                      format="(##) #####-####"
                                      value={estagio?.concedente?.telefone || ''}
                                      onValueChange={({ value }) => setEstagio((e) => ({ ...e, concedente: { ...e?.concedente, telefone: value } }))}
                                      invalidMessage={validationErrors['estagio.concedente.telefone']}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} sm={6}>
                                    <CustomSelect
                                      label="Representante"
                                      placeholder="Selecione um Representante"
                                      value={estagio?.concedente?.representante || ''}
                                      invalidMessage={validationErrors['estagio.concedente.representante.value']}
                                      options={representantes}
                                      onChange={(value) => setEstagio((e) => ({ ...e, concedente: { ...e?.concedente, representante: value } }))}
                                    />
                                  </Col>
                                  <Col xs={12} sm={6}>
                                    <CustomSelect
                                      label="Supervisor"
                                      placeholder="Selecione um Supervisor"
                                      value={estagio?.concedente?.supervisor || ''}
                                      invalidMessage={validationErrors['estagio.concedente.supervisor.value']}
                                      options={supervisores}
                                      onChange={(value) => setEstagio((e) => ({ ...e, concedente: { ...e?.concedente, supervisor: value } }))}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} sm={6}>
                                    <FormGroup>
                                      <Label for="estagio-ins-ens-numero-convenio">
                                        Número Convênio
                                      </Label>
                                      <Input
                                        id="estagio-ins-ens-numero-convenio"
                                        type="text"
                                        placeholder="Número do Convênio"
                                        value={estagio?.concedente?.numeroConvenio || ''}
                                        invalid={!!validationErrors['estagio.concedente.numeroConvenio']}
                                        onChange={({ target: { value } }) => setEstagio((e) => ({ ...e, concedente: { ...e?.concedente, numeroConvenio: value } }))}
                                      />
                                      <FormFeedback>
                                        {validationErrors['estagio.concedente.numeroConvenio']}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <Endereco
                                      id="estagio-con-endereco"
                                      ref={enderecoEstagioConRef}
                                    />
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </TabPanel>
                          <TabPanel>
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col xs={12} sm={8}>
                                    <FormGroup>
                                      <Label for="estagio-est-curso">
                                        Curso
                                      </Label>
                                      <Input
                                        id="estagio-est-curso"
                                        type="text"
                                        placeholder="Curso"
                                        value={estagio?.estagiario?.curso || ''}
                                        invalid={!!validationErrors['estagio.estagiario.curso']}
                                        onChange={({ target: { value } }) => setEstagio((e) => ({ ...e, estagiario: { ...e.estagiario, curso: value } }))}
                                      />
                                      <FormFeedback>
                                        {validationErrors['estagio.estagiario.curso']}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col xs={12} sm={4}>
                                    <FormGroup>
                                      <Label for="estagio-est-codigo-companhia-estagio">
                                        Código Companhia de Estágio
                                      </Label>
                                      <Input
                                        id="estagio-est-codigo-companhia-estagio"
                                        type="text"
                                        placeholder="Código Companhia de Estágio"
                                        value={estagio?.estagiario?.codigoCompanhiaEstagio || ''}
                                        invalid={!!validationErrors['estagio.estagiario.codigoCompanhiaEstagio']}
                                        onChange={({ target: { value } }) => setEstagio((e) => ({ ...e, estagiario: { ...e.estagiario, codigoCompanhiaEstagio: value } }))}
                                      />
                                      <FormFeedback>
                                        {validationErrors['estagio.estagiario.codigoCompanhiaEstagio']}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} sm={6}>
                                    <FormGroup>
                                      <Label for="estagio-est-nivel">Nível</Label>
                                      <Input
                                        id="estagio-est-nivel"
                                        type="select"
                                        placeholder="Nível"
                                        value={estagio?.estagiario?.nivel}
                                        invalid={!!validationErrors['estagio.estagiario.nivel']}
                                        onChange={({ target: { value } }) => setEstagio((e) => ({ ...e, estagiario: { ...e.estagiario, nivel: value } }))}
                                      >
                                        <option value="">SELECIONE</option>
                                        <option value="GRADUACAO-BACHARELADO">GRADUAÇÃO - BACHARELADO</option>
                                        <option value="GRADUACAO-LICENCIATURA">GRADUAÇÃO - LICENCIATURA</option>
                                        <option value="GRADUACAO-TECNOLOGO">GRADUAÇÃO - TECNOLÓGO</option>
                                        <option value="POS-GRADUACAO-ESPECIALIZACAO">PÓS-GRADUAÇÃO - ESPECIALIZAÇÃO</option>
                                        <option value="POS-GRADUACAO-MBA">PÓS-GRADUAÇÃO - MBA</option>
                                        <option value="POS-GRADUACAO-MESTRADO">PÓS-GRADUAÇÃO - MESTRADO</option>
                                        <option value="POS-GRADUACAO-DOUTORADO">PÓS-GRADUAÇÃO - DOUTORADO</option>
                                        <option value="POS-GRADUACAO-POS-DOUTORADO">PÓS-GRADUAÇÃO - PÓS-DOUTORADO</option>
                                      </Input>
                                      <FormFeedback>
                                        {validationErrors['estagio.estagiario.nivel']}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col xs={12} sm={4}>
                                    <FormGroup>
                                      <Label for="estagio-est-numero-matricula">
                                        Número da Matrícula
                                      </Label>
                                      <Input
                                        id="estagio-est-numero-matricula"
                                        type="text"
                                        placeholder="Número da Matrícula"
                                        value={estagio?.estagiario?.numeroMatricula || ''}
                                        invalid={!!validationErrors['estagio.estagiario.numeroMatricula']}
                                        min={1}
                                        onChange={({ target: { value } }) => setEstagio((e) => ({ ...e, estagiario: { ...e.estagiario, numeroMatricula: value } }))}
                                      />
                                      <FormFeedback>
                                        {validationErrors['estagio.estagiario.numeroMatricula']}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col xs={12} sm={2}>
                                    <FormGroup>
                                      <Label for="estagio-est-semestre">
                                        Semestre
                                      </Label>
                                      <Input
                                        id="estagio-est-semestre"
                                        type="number"
                                        placeholder="Semestre"
                                        value={estagio?.estagiario?.semestre || ''}
                                        invalid={!!validationErrors['estagio.estagiario.semestre']}
                                        onChange={({ target: { value } }) => setEstagio((e) => ({ ...e, estagiario: { ...e.estagiario, semestre: value } }))}
                                      />
                                      <FormFeedback>
                                        {validationErrors['estagio.estagiario.semestre']}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} sm={6}>
                                    <DatePickerMaskedInput
                                      label="Início do curso"
                                      placeholderText="Início do curso"
                                      selected={estagio?.estagiario?.cursoInicio || ''}
                                      invalidMessage={validationErrors['estagio.estagiario.cursoInicio']}
                                      maxDate={new Date()}
                                      onChange={(date) => setEstagio((e) => ({ ...e, estagiario: { ...e.estagiario, cursoInicio: date } }))}
                                    />
                                  </Col>
                                  <Col xs={12} sm={6}>
                                    <DatePickerMaskedInput
                                      label="Término do curso"
                                      placeholderText="Término do curso"
                                      selected={estagio?.estagiario?.cursoTermino || ''}
                                      invalidMessage={validationErrors['estagio.estagiario.cursoTermino']}
                                      onChange={(date) => setEstagio((e) => ({ ...e, estagiario: { ...e.estagiario, cursoTermino: date } }))}
                                    />
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </TabPanel>
                          <TabPanel>
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label for="estagio-mat-est-razao-social">
                                        Razão Social
                                      </Label>
                                      <Input
                                        id="estagio-mat-est-razao-social"
                                        type="text"
                                        placeholder="Razão Social"
                                        value={estagio?.companhiaEstagios?.razaoSocial || ''}
                                        invalid={!!validationErrors['estagio.companhiaEstagios.razaoSocial']}
                                        onChange={({ target: { value } }) => setEstagio((e) => ({ ...e, companhiaEstagios: { ...e.companhiaEstagios, razaoSocial: value } }))}
                                      />
                                      <FormFeedback>
                                        {validationErrors['estagio.companhiaEstagios.razaoSocial']}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label for="estagio-mat-est-nome-fantasia">
                                        Nome Fantasia
                                      </Label>
                                      <Input
                                        id="estagio-mat-est-nome-fantasia"
                                        type="text"
                                        placeholder="Nome Fantasia"
                                        value={estagio?.companhiaEstagios?.nomeFantasia || ''}
                                        invalid={!!validationErrors['estagio.companhiaEstagios.nomeFantasia']}
                                        onChange={({ target: { value } }) => setEstagio((e) => ({ ...e, companhiaEstagios: { ...e.companhiaEstagios, nomeFantasia: value } }))}
                                      />
                                      <FormFeedback>
                                        {validationErrors['estagio.companhiaEstagios.nomeFantasia']}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} sm={6}>
                                    <CustomMaskedInput
                                      id="estagio-mat-est-cnpj"
                                      label="CNPJ"
                                      placeholder="Informe um CNPJ"
                                      format="##.###.###/####-##"
                                      value={estagio?.companhiaEstagios?.cnpj || ''}
                                      onValueChange={({ value }) => setEstagio((e) => ({ ...e, companhiaEstagios: { ...e.companhiaEstagios, cnpj: value } }))}
                                      invalidMessage={validationErrors['estagio.companhiaEstagios.cnpj']}
                                    />
                                  </Col>
                                  <Col xs={12} sm={6}>
                                    <FormGroup>
                                      <Label for="estagio-mat-est-ie">
                                        Inscrição Estadual
                                      </Label>
                                      <Input
                                        id="estagio-mat-est-ie"
                                        type="number"
                                        placeholder="IE"
                                        value={estagio?.companhiaEstagios?.ie || ''}
                                        invalid={!!validationErrors['estagio.companhiaEstagios.ie']}
                                        onChange={({ target: { value } }) => setEstagio((e) => ({ ...e, companhiaEstagios: { ...e.companhiaEstagios, ie: value } }))}
                                      />
                                      <FormFeedback>
                                        {validationErrors['estagio.companhiaEstagios.ie']}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row className="flex-sm-row-reverse">
                                  <Col xs={12} sm={6}>
                                    <FormGroup>
                                      <Label for="estagio-mat-est-im">
                                        Inscrição Municipal
                                      </Label>
                                      <Input
                                        id="estagio-mat-est-im"
                                        type="number"
                                        placeholder="IM"
                                        value={estagio?.companhiaEstagios?.im || ''}
                                        invalid={!!validationErrors['estagio.companhiaEstagios.im']}
                                        onChange={({ target: { value } }) => setEstagio((e) => ({ ...e, companhiaEstagios: { ...e.companhiaEstagios, im: value } }))}
                                      />
                                      <FormFeedback>
                                        {validationErrors['estagio.companhiaEstagios.im']}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col xs={12} sm={6}>
                                    <CustomMaskedInput
                                      id="estagio-mat-est-telefone"
                                      label="Telefone"
                                      placeholder="Informe um telefone"
                                      format="(##) #####-####"
                                      value={estagio?.companhiaEstagios?.telefone || ''}
                                      onValueChange={({ value }) => setEstagio((e) => ({ ...e, companhiaEstagios: { ...e.companhiaEstagios, telefone: value } }))}
                                      invalidMessage={validationErrors['estagio.companhiaEstagios.telefone']}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} sm={6}>
                                    <CustomSelect
                                      label="Representante"
                                      placeholder="Selecione um Representante"
                                      value={estagio?.companhiaEstagios?.representante || ''}
                                      invalidMessage={validationErrors['estagio.companhiaEstagios.representante.value']}
                                      options={representantes}
                                      onChange={(value) => setEstagio((e) => ({ ...e, companhiaEstagios: { ...e.companhiaEstagios, representante: value } }))}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <Endereco
                                      id="estagio-mat-est-endereco"
                                      ref={enderecoEstagioComEstRef}
                                    />
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </TabPanel>
                        </Tabs>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </Col>
              </Row>
            )}
            <Row className="mt-3">
              <Col>
                <Button color="primary" id="toggler-contatos" block>
                  {`Contatos${
                    id || tipoContrato === 'FUN_CLT'
                      ? ''
                      : ' e Dados de Login'
                  }`}
                  <ValidationErrorsCount
                    count={validationErrorsContatosCount}
                  />
                </Button>
                <UncontrolledCollapse toggler="#toggler-contatos">
                  <Card>
                    <CardBody>
                      {id || tipoContrato === 'FUN_CLT' ? null : (
                        <Row className="mb-3">
                          <Col>
                            <h6>
                              {`Cadastro de Email e Senha para Login do${
                                tipoContrato === 'FUN_ESP_CLT'
                                  ? ' Funcionário Especialista'
                                  : ' Especialista'
                              }`}
                            </h6>
                            {/* habilitar quando disponível serviço de email */}
                            {/* <small>
                              {`O${
                                tipoContrato === 'FUN_ESP_CLT'
                                  ? ' Funcionário Especialista'
                                  : ' Especialista'
                              } receberá um email com os dados de login`}
                            </small> */}
                          </Col>
                        </Row>
                      )}
                      <FormGroup>
                        <Label for="email">
                          {id || tipoContrato === 'FUN_CLT'
                            ? 'Email'
                            : 'Email para Contato e Login'}
                        </Label>
                        <Input
                          id="email"
                          type="text"
                          placeholder="Email"
                          value={email}
                          invalid={!!validationErrors.email}
                          onChange={({ target: { value } }) => setEmail(value)}
                        />
                        <FormFeedback>{validationErrors.email}</FormFeedback>
                      </FormGroup>
                      {id ? null : (
                        <>
                          <FormGroup>
                            <Label for="password">Criar Nova Senha</Label>
                            <Input
                              id="password"
                              type="password"
                              placeholder="Senha"
                              value={password}
                              invalid={!!validationErrors.password}
                              onChange={({ target: { value } }) => setPassword(value)}
                            />
                            <FormFeedback>
                              {validationErrors.password}
                            </FormFeedback>
                          </FormGroup>
                          <FormGroup className="mb-5">
                            <Label for="password-confirmation">
                              Repetir Nova Senha
                            </Label>
                            <Input
                              id="password-confirmation"
                              type="password"
                              placeholder="Repetir Senha"
                              value={passwordConfirmation}
                              invalid={
                                  !!validationErrors.passwordConfirmation
                                }
                              onChange={({ target: { value } }) => setPasswordConfirmation(value)}
                            />
                            <FormFeedback>
                              {validationErrors.passwordConfirmation}
                            </FormFeedback>
                          </FormGroup>
                        </>
                      )}
                      <FormGroup>
                        <Label for="whatsapp">Whatsapp</Label>
                        <PatternFormat
                          customInput={Input}
                          id="whatsapp"
                          format="(##) #####-####"
                          mask="_"
                          valueIsNumericString
                          type="text"
                          invalid={!!validationErrors.whatsapp}
                          placeholder="Whatsapp"
                          value={whatsapp}
                          onValueChange={({ value }) => setWhatsapp(value)}
                        />
                        <FormFeedback>
                          {validationErrors.whatsapp}
                        </FormFeedback>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Button color="primary" id="toggler-endereco" block>
                  Endereço
                  <ValidationErrorsCount
                    count={validationErrorsEnderecoCount}
                  />
                </Button>
                <UncontrolledCollapse toggler="#toggler-endereco">
                  <Card>
                    <CardBody>
                      <Endereco
                        ref={enderecoRef}
                        validationErrors={validationErrors}
                        onValidationErrors={setValidationErrors}
                      />
                      <Row>
                        <Col xs={12} sm={6}>
                          <FormGroup check>
                            <Input
                              type="checkbox"
                              checked={residenciaPropria}
                              onChange={() => setResidenciaPropria(
                                (prevState) => !prevState,
                              )}
                            />
                            <Label check>Residência Própria</Label>
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          {residenciaPropria && (
                          <FormGroup check>
                            <Input
                              type="checkbox"
                              checked={residenciaCompradaComRecursoFGTS}
                              onChange={() => setResidenciaCompradaComRecursoFGTS(
                                (prevState) => !prevState,
                              )}
                            />
                            <Label check>
                              Comprada com recursos do FGTS
                            </Label>
                          </FormGroup>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Button color="primary" id="toggler-filhos" block>
                  Filho(s)
                </Button>
                <UncontrolledCollapse toggler="#toggler-filhos">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xs={12} sm={5}>
                          <FormGroup>
                            <Label for="filho-nome">
                              Nome Completo do Filho(a)
                            </Label>
                            <Input
                              id="filho-nome"
                              type="text"
                              placeholder="Nome Completo do Filho(a)"
                              value={filhoNome}
                              invalid={!!validationErrors.filhoNome}
                              onChange={({ target: { value } }) => setFilhoNome(value)}
                            />
                            <FormFeedback>
                              {validationErrors.filhoNome}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={2}>
                          <DatePickerMaskedInput
                            label="Nascimento"
                            placeholderText="Nascimento"
                            selected={filhoNascimento}
                            invalidMessage={validationErrors.filhoNascimento}
                            yearsRange={range(
                              1900,
                              getYear(new Date()) + 1,
                              1,
                            )}
                            maxDate={new Date()}
                            openToDate={new Date('2000/01/01')}
                            onChange={(date) => setFilhoNascimento(date)}
                          />
                        </Col>
                        <Col xs={12} sm={3}>
                          <FormGroup>
                            <Label for="filho-cpf">CPF</Label>
                            <PatternFormat
                              customInput={Input}
                              id="filho-cpf"
                              format="###.###.###-##"
                              mask="_"
                              invalid={!!validationErrors.filhoCpf}
                              valueIsNumericString
                              type="text"
                              placeholder="CPF"
                              value={filhoCpf}
                              onValueChange={({ value }) => setFilhoCpf(value)}
                            />
                            <FormFeedback>
                              {validationErrors.filhoCpf}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col
                          xs={12}
                          sm={2}
                          style={{
                            paddingTop: '2rem',
                          }}
                        >
                          <Button
                            color="success"
                            onClick={handleAddFilhos}
                            block
                          >
                            <i className="fa-solid fa-circle-plus text-white" />
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {filhos.map((f, index) => (
                            <Row className="mt-2" key={`${f.cpf}-${index}`}>
                              <Col xs={12} sm={5}>
                                {f.nome}
                              </Col>
                              <Col xs={12} sm={2}>
                                {format(
                                  new Date(f.nascimento),
                                  'dd/MM/yyyy',
                                  { locale: ptBR },
                                )}
                              </Col>
                              <Col xs={12} sm={3}>
                                <PatternFormat
                                  format="###.###.###-##"
                                  value={f.cpf}
                                  displayType="text"
                                  renderText={(formattedValue) => formattedValue}
                                />
                              </Col>
                              <Col xs={12} sm={2}>
                                <Button
                                  className="mr-2"
                                  color="primary"
                                  onClick={() => handleUpdateFilho(f)}
                                >
                                  <i className="fa-solid fa-edit text-white" />
                                </Button>
                                <Button
                                  color="danger"
                                  onClick={() => handleRemoveFilho(f)}
                                >
                                  <i className="fa-solid fa-trash-can text-white" />
                                </Button>
                              </Col>
                            </Row>
                          ))}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Button color="primary" id="toggler-admissao" block>
                  Dados da Admissão
                  <ValidationErrorsCount
                    count={validationErrorsAdmissaoCount}
                  />
                </Button>
                <UncontrolledCollapse toggler="#toggler-admissao">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xs={12} sm={4}>
                          <DatePickerMaskedInput
                            label="Data de Admissão"
                            placeholderText="Data de Admissão"
                            selected={admissaoData}
                            invalidMessage={validationErrors.admissaoData}
                            yearsRange={range(
                              1900,
                              getYear(new Date()) + 1,
                              1,
                            )}
                            maxDate={new Date()}
                            onChange={(date) => setAdmissaoData(date)}
                            disabled={tipoContrato === 'ESP_PJ'}
                          />
                        </Col>
                        <Col xs={12} sm={8}>
                          <Row>
                            <Col>Contrato de Experiência</Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={6}>
                              <FormGroup className="pt-2" check>
                                <Input
                                  type="radio"
                                  checked={admissaoContratoExperiencia}
                                  onChange={() => setAdmissaoContratoExperiencia(true)}
                                  disabled={tipoContrato === 'ESP_PJ'}
                                />
                                <Label check>Sim</Label>
                              </FormGroup>
                            </Col>
                            <Col xs={12} sm={6}>
                              <FormGroup className="pt-2" check>
                                <Input
                                  type="radio"
                                  checked={!admissaoContratoExperiencia}
                                  onChange={() => setAdmissaoContratoExperiencia(false)}
                                  disabled={tipoContrato === 'ESP_PJ'}
                                />
                                <Label check>Não</Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={6}>
                          <FormGroup>
                            <Label for="admissao-prazo">Prazo</Label>
                            <Input
                              id="admissao-prazo"
                              type="number"
                              placeholder="Prazo"
                              min={0}
                              value={admissaoPrazo}
                              onChange={({ target: { value } }) => setAdmissaoPrazo(value)}
                              disabled={tipoContrato === 'ESP_PJ'}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          <FormGroup>
                            <Label for="admissao-dias-prorrogacao">
                              Dias de Prorrogacao
                            </Label>
                            <Input
                              id="admissao-dias-prorrogacao"
                              type="number"
                              placeholder="Dias de Prorrogacao"
                              min={0}
                              value={admissaoDiasProrrogacao}
                              onChange={({ target: { value } }) => setAdmissaoDiasProrrogacao(value)}
                              disabled={tipoContrato === 'ESP_PJ'}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Label for="funcao">Função</Label>
                        <Input
                          id="funcao"
                          type="text"
                          placeholder="Função"
                          value={funcao}
                          invalid={!!validationErrors.funcao}
                          onChange={({ target: { value } }) => setFuncao(value)}
                          disabled={tipoContrato === 'ESP_PJ'}
                        />
                        <FormFeedback>
                          {validationErrors.funcao}
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label for="salario">Salário</Label>
                        <NumericFormat
                          id="salario"
                          customInput={Input}
                          placeholder="Salário"
                          decimalSeparator=","
                          thousandSeparator="."
                          decimalScale={2}
                          prefix="R$"
                          value={salario}
                          invalid={!!validationErrors.salario}
                          fixedDecimalScale
                          onValueChange={({ floatValue }) => setSalario(floatValue)}
                          disabled={tipoContrato === 'ESP_PJ'}
                        />
                        <FormFeedback>
                          {validationErrors.salario}
                        </FormFeedback>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Button color="primary" id="toggler-horario-trabalho" block>
                  Horário de Trabalho
                </Button>
                <UncontrolledCollapse toggler="#toggler-horario-trabalho">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          <Row>
                            <Col xs={8}>
                              <FormGroup>
                                <Label for="horario-trabalho-dia-semana">
                                  Dia da Semana
                                </Label>
                                <Input
                                  id="horario-trabalho-dia-semana"
                                  type="select"
                                  placeholder="Dia da Semana"
                                  value={horarioTrabalhoDiaSemana}
                                  invalid={
                                      !!validationErrors.horarioTrabalhoDiaSemana
                                    }
                                  onChange={({ target: { value } }) => setHorarioTrabalhoDiaSemana(
                                    parseInt(value, 10),
                                  )}
                                >
                                  {weeks.map((s) => (
                                    <option key={s} value={weeks.indexOf(s)}>
                                      {s}
                                    </option>
                                  ))}
                                </Input>
                                <FormFeedback>
                                  {validationErrors.horarioTrabalhoDiaSemana}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col xs={4} style={{ paddingTop: '2.25rem' }}>
                              <FormGroup check>
                                <Input
                                  type="checkbox"
                                  checked={horarioTrabalhoisDiaUtil}
                                  onChange={() => setHorarioTrabalhoisDiaUtil(
                                    (prevState) => !prevState,
                                  )}
                                />
                                <Label check>Considerar dia Útil</Label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}>
                              <TimePickerMaskedInput
                                label="Horário Início"
                                placeholderText="Horário Início"
                                selected={horarioTrabalhoInicio}
                                invalidMessage={
                                    validationErrors.horarioTrabalhoInicio
                                  }
                                openToDate={new Date('1900/01/01 08:00')}
                                onChange={(date) => setHorarioTrabalhoInicio(date)}
                              />
                            </Col>
                            <Col xs={6}>
                              <TimePickerMaskedInput
                                label="Horário Término"
                                placeholderText="Horário Término"
                                selected={horarioTrabalhoTermino}
                                invalidMessage={
                                    validationErrors.horarioTrabalhoTermino
                                  }
                                openToDate={new Date('1900/01/01 18:00')}
                                onChange={(date) => setHorarioTrabalhoTermino(date)}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}>
                              <TimePickerMaskedInput
                                label="Intervalo Início"
                                placeholderText="Intervalo Início"
                                selected={horarioTrabalhoIntervaloInicio}
                                invalidMessage={
                                    validationErrors.horarioTrabalhoIntervaloInicio
                                  }
                                openToDate={new Date('1900/01/01 12:00')}
                                onChange={(date) => setHorarioTrabalhoIntervaloInicio(date)}
                              />
                            </Col>
                            <Col xs={6}>
                              <TimePickerMaskedInput
                                label="Intervalo Término"
                                placeholderText="Intervalo Término"
                                selected={horarioTrabalhoIntervaloTermino}
                                invalidMessage={
                                    validationErrors.horarioTrabalhoIntervaloTermino
                                  }
                                openToDate={new Date('1900/01/01 13:00')}
                                onChange={(date) => setHorarioTrabalhoIntervaloTermino(date)}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Button
                                color="success"
                                onClick={handleAddHorarioTrabalho}
                                block
                              >
                                <i className="fa-solid fa-circle-plus text-white" />
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {horarioTrabalhos.length ? (
                            <Table className="mt-3" bordered responsive>
                              <thead>
                                <tr>
                                  <th rowSpan={2} className="text-center" style={{ verticalAlign: 'top' }}>
                                    Semana
                                  </th>
                                  <th rowSpan={2} className="text-center" style={{ verticalAlign: 'top' }}>
                                    Útil
                                  </th>
                                  <th rowSpan={2} className="text-center" style={{ verticalAlign: 'top' }}>
                                    Início
                                  </th>
                                  <th colSpan={2} className="text-center">
                                    Intervalo
                                  </th>
                                  <th rowSpan={2} className="text-center" style={{ verticalAlign: 'top' }}>
                                    Término
                                  </th>
                                  <th rowSpan={2} className="text-center" style={{ verticalAlign: 'top' }}>
                                    #
                                  </th>
                                </tr>
                                <tr>
                                  <th className="text-center">Hora Início</th>
                                  <th className="text-center">
                                    Hora Termino
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {horarioTrabalhos
                                  .sort((a, b) => (a.diaSemana > b.diaSemana ? 1 : -1))
                                  .map((ht, index) => (
                                    <tr key={`${ht.diaSemana}-${index}`}>
                                      <td className="text-center">
                                        {weeks[ht.diaSemana]}
                                      </td>
                                      <td className="text-center">
                                        {ht.isDiaUtil ? (
                                          <i className="fa-solid fa-circle-check text-success" />
                                        ) : (
                                          <i className="fa-solid fa-circle-xmark text-danger" />
                                        )}
                                      </td>
                                      <td className="text-center">
                                        {format(
                                          parseISO(ht.inicio),
                                          'HH:mm',
                                          {
                                            locale: ptBR,
                                          },
                                        )}
                                      </td>
                                      <td className="text-center">
                                        {format(
                                          parseISO(ht.intervalo.inicio),
                                          'HH:mm',
                                          { locale: ptBR },
                                        )}
                                      </td>
                                      <td className="text-center">
                                        {format(
                                          parseISO(ht.intervalo.termino),
                                          'HH:mm',
                                          { locale: ptBR },
                                        )}
                                      </td>
                                      <td className="text-center">
                                        {format(
                                          parseISO(ht.termino),
                                          'HH:mm',
                                          {
                                            locale: ptBR,
                                          },
                                        )}
                                      </td>
                                      <td className="text-center">
                                        <Button
                                          className="mr-2"
                                          color="primary"
                                          onClick={() => handleUpdateHorarioTrabalho(
                                            ht,
                                            index,
                                          )}
                                        >
                                          <i className="fa-solid fa-edit text-white" />
                                        </Button>
                                        <Button
                                          color="danger"
                                          onClick={() => handleRemoveHorarioTrabalho(index)}
                                        >
                                          <i className="fa-solid fa-trash-can text-white" />
                                        </Button>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </Table>
                          ) : null}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Button color="primary" id="toggler-valores-repasse" block>
                  Área para Definir os Valores de Repasse
                  <ValidationErrorsCount
                    count={validationErrorsTabelaValoresCount}
                  />
                </Button>
                <UncontrolledCollapse toggler="#toggler-valores-repasse">
                  <Card>
                    <CardBody>
                      <p className="text-danger">
                        {validationErrors.tabelaValores}
                      </p>
                      <TabelaValores
                        ref={tabelaValoresRef}
                        onSave={setNewTabelaValor}
                      />
                      <Row>
                        <Col xs={12} md={9}>
                          <Select
                            placeholder="Selecione uma Tabela de Valores"
                            value={tabelaValor}
                            options={tabelaValoresList}
                            invalidMessage={validationErrors.tabelaValor}
                            isLoading={loadingTabelaValores}
                            onChange={handleAddTabelaValores}
                          />
                        </Col>
                        <Col xs={12} md={3}>
                          <Button
                            color="success"
                            className="text-white"
                            onClick={() => tabelaValoresRef.current.show(null, false)}
                            block
                          >
                            <i className="fa-solid fa-circle-plus text-white mr-2" />
                            Cadastrar nova Tabela de Valores
                          </Button>
                        </Col>
                      </Row>
                      <Row className="px-3">
                        <Col>
                          {tabelaValores.length ? (
                            tabelaValores
                              .sort((a, b) => (a.label > b.label ? 1 : -1))
                              .map((t, index) => (
                                <Row
                                  className={classNames('py-2 align-items-center', { 'bg-info': index % 2 === 0 })}
                                  key={`tabelaValores-${index}`}
                                >
                                  <Col xs={10}>{t.label}</Col>
                                  <Col xs={2}>
                                    <Button
                                      color="danger"
                                      onClick={() => handleRemoveTabelaValores(t)}
                                      block
                                    >
                                      <i className="fa-solid fa-trash-can text-white" />
                                    </Button>
                                  </Col>
                                </Row>
                              ))
                          ) : (
                            <p className="text-center">
                              Adicione uma tabela de valores
                            </p>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Button color="primary" id="toggler-especialidades" block>
                  Tipo de Atendimentos
                  <ValidationErrorsCount
                    count={validationErrorsEspecialidadesCount}
                  />
                </Button>
                <UncontrolledCollapse toggler="#toggler-especialidades">
                  <Card>
                    <CardBody>
                      <p className="text-danger">
                        {validationErrors.especialidades}
                      </p>
                      <TipoDeAtendimento
                        ref={especialidadeRef}
                        onSave={setNewEspecialidade}
                      />
                      <Row>
                        <Col>
                          <Select
                            placeholder="Selecione um tipo de atendimento"
                            value={especialidade}
                            options={especialidadesList}
                            loadingMessage={() => 'Carregando tipo de atendimento de acordo com a tabela de valor selecionada'}
                            noOptionsMessage={() => (tabelaValores.length
                              ? 'Nenhuma tipo de atendimento encontrado de acordo com a tabela de valor selecionada'
                              : 'Selecione uma ou mais tipo de atendimento')}
                            invalidMessage={validationErrors.especialidade}
                            onChange={handleAddEspecialidades}
                          />
                        </Col>
                        {/* <Col xs={3}>
                              <Button
                                color="success"
                                className="text-white"
                                onClick={() => especialidadeRef.current.show(null, false)}
                                block
                              >
                                <i className="fa-solid fa-circle-plus text-white mr-2" />
                                Cadastrar nova especialidade
                              </Button>
                            </Col> */}
                      </Row>
                      <Row className="px-3">
                        <Col>
                          {especialidades.length ? (
                            <>
                              <Row className="py-3 align-items-center bg-primary">
                                <Col xs={12} md={8} className="text-white" tag="h5">Tipo de Atendimento</Col>
                                <Col xs={12} md={2} className="text-white" tag="h5">Valor/Hora</Col>
                              </Row>
                              {especialidades
                                .sort((a, b) => (a.label > b.label ? 1 : -1))
                                .map((e, index) => (
                                  <Row
                                    className={classNames('py-2 align-items-center', { 'bg-info': index % 2 !== 0 })}
                                    key={`especialidades-${index}`}
                                  >
                                    <Col xs={12} md={8}>{e.nome}</Col>
                                    <Col xs={12} md={2}>{numberToCurrency(e.valor)}</Col>
                                    <Col xs={12} md={2}>
                                      <Button
                                        color="danger"
                                        onClick={() => handleRemoveEspecialidades(e)}
                                        block
                                      >
                                        <i className="fa-solid fa-trash-can text-white" />
                                      </Button>
                                    </Col>
                                  </Row>
                                ))}
                            </>
                          ) : (
                            <p className="text-center">
                              Adicione uma ou mais tipo(s) de atendimento(s)
                            </p>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </Col>
            </Row>
            {id && (
            <Row className="mt-3">
              <Col>
                <Button
                  color="primary"
                  id="toggler-documentos-anexados"
                  block
                >
                  Documento(s) anexado(s)
                </Button>
                <UncontrolledCollapse toggler="#toggler-documentos-anexados">
                  <Card>
                    <CardBody>
                      <UploadFiles
                        accept={{
                          'image/jpeg': [],
                          'image/png': [],
                          'application/pdf': [],
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
                          'application/vnd.ms-excel.sheet.binary.macroEnabled.12': [],
                          'application/vnd.ms-excel': [],
                          'application/vnd.ms-excel.sheet.macroEnabled.12': [],
                          'application/msword': [],
                          'application/vnd.ms-word.document.macroEnabled.12': [],
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.template': [],
                          'text/csv': [],
                        }}
                        height="150px"
                        url="/profissionalDocumentoAnexos"
                        parentId={id}
                        initialFiles={documentosAnexados}
                      />
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </Col>
            </Row>
            )}
            <Row className="mt-3">
              <Col>
                <Button color="primary" id="toggler-observacoes" block>
                  Observações
                </Button>
                <UncontrolledCollapse toggler="#toggler-observacoes">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xs={10}>
                          <FormGroup>
                            <Label for="observacao">Observaçao</Label>
                            <Input
                              id="observacao"
                              type="textarea"
                              placeholder="Observação"
                              value={observacao}
                              invalid={!!validationErrors.observacao}
                              onChange={({ target: { value } }) => setObservacao(value)}
                            />
                            <FormFeedback>
                              {validationErrors.observacao}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col
                          xs={2}
                          style={{
                            paddingTop: '2rem',
                          }}
                        >
                          <Button
                            color="success"
                            onClick={handleAddObservacoes}
                            block
                          >
                            <i className="fa-solid fa-circle-plus text-white" />
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {observacoes.length ? (
                            observacoes.map((o, index) => (
                              <Row
                                key={`observacao-${index}`}
                                className="mt-2"
                              >
                                <Col xs={6}>{o.observacao}</Col>
                                <Col xs={2}>
                                  {format(
                                    o.createdAt,
                                    'dd/MM/yyyy HH:mm:ss',
                                    { locale: ptBR },
                                  )}
                                </Col>
                                <Col xs={2}>{o.createdByUser.name}</Col>
                                <Col xs={2}>
                                  <Button
                                    className="mr-2"
                                    color="primary"
                                    onClick={() => handleUpdateObservacao(o, index)}
                                  >
                                    <i className="fa-solid fa-edit text-white" />
                                  </Button>
                                  <Button
                                    color="danger"
                                    onClick={() => handleRemoveObservacao(index)}
                                  >
                                    <i className="fa-solid fa-trash-can text-white" />
                                  </Button>
                                </Col>
                              </Row>
                            ))
                          ) : (
                            <div className="d-flex justify-content-center">
                              Sem Observações
                            </div>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </Col>
            </Row>
            {solicitationDeleteAccount && (
              <Row className="mt-3">
                <Col>
                  <Alert color="danger">
                    <Row>
                      <Col>
                        <strong>Profissional solicitou exclusão da conta</strong>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <i className="fa-solid fa-hand text-danger mr-3" style={{ fontSize: '1.75rem' }} />
                        {solicitationDeleteAccount?.description ? `Motivo - ${solicitationDeleteAccount.description}` : 'Motivo não informado'}
                      </Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
            )}
            <Row className="mt-3">
              <Col xs={12} sm={6}>
                <DatePickerMaskedInput
                  label="Data da Assinatura do Contrato"
                  placeholderText="Data da Assinatura do Contrato"
                  selected={dataAssinaturaContrato}
                  invalidMessage={validationErrors.dataAssinaturaContrato}
                  onChange={(date) => setDataAssinaturaContrato(date)}
                />
              </Col>
              <Col xs={12} sm={6}>
                <DatePickerMaskedInput
                  label="Data de Encerramento do Contrato"
                  placeholderText="Data de Encerramento do Contrato"
                  selected={dataEncerramentoContrato}
                  invalidMessage={validationErrors.dataEncerramentoContrato}
                  onChange={(date) => setDataEncerramentoContrato(date)}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <CustomButton type="submit" color="success" loading={loading?.loading ? loading.loading : loading} block>
                  Salvar
                </CustomButton>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Profissional;
