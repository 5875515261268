import React, { useEffect, useMemo, useCallback, useRef, useContext } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Row,
} from 'reactstrap';

import Notification from '../../components/Notification';
import TabelaValorEspecialista from '../../components/TabelaValorEspecialista';
import TableManager from '../../components/TableManager';
import { AppContext } from '../../contexts/app';
import { useManager } from '../../hooks/manager';
import api from '../../services/api';

function TabelaValoresCliente() {
  const notificationRef = useRef();
  const tabelaValorEspecialistaRef = useRef();
  const { setLoading } = useContext(AppContext);
  const { load, destroy } = useManager();

  useEffect(() => {
    load('/tabelaValoresEspecialistas', 1, []);
  }, [load]);

  const handleUpdate = useCallback((data) => {
    tabelaValorEspecialistaRef.current.show(data);
  }, []);

  const handleCreate = useCallback(() => {
    tabelaValorEspecialistaRef.current.show();
  }, []);

  const handleCreateCsv = useCallback((_selectedRows = []) => {
    (async () => {
      try {
        setLoading({
          loading: true,
          message: 'Aguarde... Estamos gerando o arquivo CSV... ',
        });

        // await fetch api
        const res = await api.get(`/tabelaValorEspecialidadeCSVCreatorReportLists?tabelaValoresEspecialidadeIds=${JSON.stringify(_selectedRows.map(({ _id }) => _id))}`, { responseType: 'blob' });
        const fileName = res.headers['content-disposition']
          .replace('attachment; filename=', '')
          .replace('"', '');

        const fileURL = window.URL.createObjectURL(res.data);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.target = '_blank';
        alink.rel = 'noopener noreferrer';
        alink.download = fileName;
        alink.click();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao realizar ao gerar relatório',
          color: 'danger',
        });
      }
    })();
  }, [setLoading]);

  const columns = useMemo(() => [
    {
      dataField: 'nome',
      text: 'Nome',
      textFilter: true,
    },
  ]);

  return (
    <Container fluid>
      <Notification ref={notificationRef} />
      <Row className="mt-5">
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h3>Tabela de Valores para Especialistas</h3>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col>
                  <Row>
                    <Col xs={12} sm={5} md={3} className="mb-3 mb-xl-0">
                      <Button block color="success" onClick={handleCreate}>
                        Cadastrar
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableManager
            initialColumns={columns}
            onCreateCsv={handleCreateCsv}
            onUpdate={(row) => handleUpdate(row)}
            onDelete={(row) => destroy(row._id, '/tabelaValoresEspecialistas')}
            selectable
          />
        </Col>
      </Row>
      <TabelaValorEspecialista ref={tabelaValorEspecialistaRef} />
    </Container>
  );
}

export default TabelaValoresCliente;
