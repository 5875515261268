/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
import React, { useState, useEffect, useContext, useCallback, useRef, useImperativeHandle, forwardRef } from 'react';
import { PatternFormat } from 'react-number-format';
import { Badge, Button, Card, CardBody, CardHeader, Col, Container, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, UncontrolledCollapse, UncontrolledTooltip } from 'reactstrap';

import classNames from 'classnames';
import { differenceInSeconds, format, isAfter, parseISO, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import * as Yup from 'yup';

import { AppContext } from '../../../contexts/app';
import { useManager } from '../../../hooks/manager';
import useSecondsToDhms from '../../../hooks/useSecondsToDhms';
import api from '../../../services/api';
import { numberToCurrency } from '../../../utils/functions';
import AlertModal from '../../AlertModal';
import CustomModalEmitirFatura from '../CustomModalEmitirFatura';
import CustomModalFecharFatura from '../CustomModalFecharFatura';
import CustomModalMotivoCancelamento from '../CustomModalMotivoCancelamento';
import CustomModalPagarFatura from '../CustomModalPagarFatura';

function FinanceiroEdit(_props, ref) {
  const [show, setShow] = useState(false);
  const [searchData, setSearchData] = useState({});
  const [id, setId] = useState();
  const alertRef = useRef();
  const modalEmitirFatura = useRef();
  const modalFecharFatura = useRef();
  const modalPagarFatura = useRef();
  const modalMotivoCancelamento = useRef();
  const { setLoading, notificationRef } = useContext(AppContext);
  const { update, setDatas } = useManager();
  const { convertSecondsToDhms } = useSecondsToDhms();
  const [fatura, setFatura] = useState();
  const [observacao, setObservacao] = useState('');
  const [observacoes, setObservacoes] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const change = useRef();

  useImperativeHandle(ref, () => ({
    show: ({ _id }, _searchData, onChangeCallback) => {
      console.log('🚀 ~ useImperativeHandle ~ _searchData:', _searchData);
      console.log('🚀 ~ useImperativeHandle ~ onChangeCallback:', onChangeCallback);
      setSearchData(_searchData);
      setId(_id);
      setShow(true);
      change.current = onChangeCallback;
    },
    close: () => setShow(false),
  }));

  const loadFatura = useCallback(() => {
    if (id) {
      (async () => {
        try {
          setLoading(true);
          const { data } = await api.get(`/financeiros/${id}`);
          console.log(data);
          setFatura(data);
          setObservacoes(data.observacoes);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      })();
    }
  }, [id]);

  useEffect(() => {
    loadFatura();
  }, [id]);

  const renderResponsaveis = useCallback((responsaveis) => (
    <>
      <Row>
        <Col>
          <h5>Responsáveis</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          {responsaveis.map((r) => (
            <Row key={r.cpf}>
              <Col xs={6}>{r.nome}</Col>
              <Col xs={3}>
                {r.cpf && (
                <PatternFormat
                  format="###.###.###-##"
                  value={r.cpf}
                  displayType="text"
                  renderText={(formattedValue) => formattedValue}
                />
                )}
              </Col>
              <Col xs={3}>
                {r.whatsapp && (
                  <PatternFormat
                    format="(##) #####-####"
                    value={r.whatsapp}
                    displayType="text"
                    renderText={(formattedValue) => formattedValue}
                  />
                )}
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </>
  ), []);

  const renderTipo = useCallback(() => {
    if (fatura?.tipo === 'conta-pagar') {
      return (
        <Badge className="w-100 h-100" color="danger" tag="div">
          <i className="fa-solid fa-money-bill-transfer mr-2" />
          Contas a Pagar
        </Badge>
      );
    }

    if (fatura?.tipo === 'conta-receber') {
      return (
        <Badge className="w-100 h-100" color="success" tag="div">
          <i className="fa-solid fa-hand-holding-dollar mr-2" />
          Conta a Receber
        </Badge>
      );
    }

    return (
      <>
        <UncontrolledTooltip target={`tipo-${fatura._id}`}>
          Não Identificado
        </UncontrolledTooltip>
        <i
          id={`tipo-${fatura._id}`}
          className="fa-solid fa-circle-question text-danger mt-2"
        />
      </>
    );
  }, [fatura]);

  const renderContaDe = useCallback(() => {
    if (fatura?.contaDe === 'cliente') {
      return (
        <Badge className="w-100 h-100 bg-success" tag="div">
          <i className="fa-solid fa-user mr-2" />
          Tipo Cliente
        </Badge>
      );
    }

    if (fatura?.contaDe === 'fornecedor') {
      return (
        <Badge className="w-100 h-100 bg-warning" tag="div">
          <i className="fa-solid fa-building-user mr-2" />
          Tipo Fornecedor
        </Badge>
      );
    }

    if (fatura?.contaDe === 'profissional') {
      return (
        <Badge className="w-100 h-100 bg-danger" tag="div">
          <i className="fa-solid fa-user-tie mr-2" />
          Tipo Profissional
        </Badge>
      );
    }

    return (
      <>
        <UncontrolledTooltip target={`contaDe-${fatura._id}`}>
          Não Identificado
        </UncontrolledTooltip>
        <i
          id={`contaDe-${fatura._id}`}
          className="fa-solid fa-circle-question text-danger mt-2"
        />
      </>
    );
  }, [fatura]);

  const renderStatus = useCallback(() => {
    if (fatura?.status === 'aberto') {
      return (
        <Badge id={`status-${fatura._id}`} className="w-100 h-100" color="success" tag="div">
          <i className="fa-solid fa-circle mr-2" />
          Status Em Aberto
        </Badge>
      );
    }

    if (fatura?.status === 'fechado') {
      return (
        <Badge id={`status-${fatura._id}`} className="w-100 h-100" color="warning" tag="div">
          <i className="fa-solid fa-circle mr-2" />
          Status Fechado
        </Badge>
      );
    }

    if (fatura?.status === 'emitido') {
      return (
        <Badge id={`status-${fatura._id}`} className="w-100 h-100" color="danger" tag="div">
          <i className="fa-solid fa-circle mr-2" />
          Status Emitido
        </Badge>
      );
    }

    return (
      <>
        <UncontrolledTooltip target={`status-${fatura._id}`}>
          Não Identificado
        </UncontrolledTooltip>
        <i
          id={`status-${fatura._id}`}
          className="fa-solid fa-circle-question text-danger mt-2"
        />
      </>
    );
  }, [fatura]);

  const renderSituacao = useCallback(() => {
    if (fatura?.situacao === 'a-vencer') {
      return (
        <Badge id={`situacao-${fatura._id}`} className="w-100 h-100" color="info" tag="div">
          <i className="fa-solid fa-circle-info mr-2" />
          Situação A Vencer
        </Badge>
      );
    }

    if (fatura?.situacao === 'pago') {
      return (
        <Badge id={`situacao-${fatura._id}`} className="w-100 h-100" color="success" tag="div">
          <i className="fa-solid fa-circle-check mr-2" />
          Situação Pago
        </Badge>
      );
    }

    if (fatura?.situacao === 'vencido') {
      return (
        <Badge id={`situacao-${fatura._id}`} className="w-100 h-100" color="danger" tag="div">
          <i className="fa-solid fa-circle-xmark mr-2" />
          Situação Vencido
        </Badge>
      );
    }

    return (
      <>
        <UncontrolledTooltip target={`situacao-${fatura._id}`}>
          Não Identificado
        </UncontrolledTooltip>
        <i
          id={`situacao-${fatura._id}`}
          className="fa-solid fa-circle-question text-danger mt-2"
        />
      </>
    );
  }, [fatura]);

  const renderGrupo = useCallback(() => {
    // console.log(row);
    if (fatura?.clienteUserData?.grupo?.nome) {
      return (
        <div
          className={classNames('w-100 h-100 badge', { 'text-white': !!fatura?.clienteUserData?.grupo?.color || fatura?.clienteUserData?.grupo?.color !== '#ffffff' })}
          style={{ backgroundColor: fatura?.clienteUserData?.grupo?.color ? `${fatura.clienteUserData?.grupo.color}` : 'var(--white)' }}
        >
          <i className="fa-solid fa-circle mr-2" />
          {`Grupo ${fatura.clienteUserData?.grupo.nome}`}
        </div>
      );
    }
    if (fatura?.fornecedor?.grupo?.nome) {
      return (
        <div
          className={classNames('w-100 h-100 badge', { 'text-white': !!fatura?.fornecedor?.grupo?.color || fatura?.fornecedor?.grupo?.color !== '#ffffff' })}
          style={{ backgroundColor: fatura?.fornecedor?.grupo?.color ? `${fatura.fornecedor?.grupo.color}` : 'var(--white)' }}
        >
          <i className="fa-solid fa-circle mr-2" />
          {`Grupo ${fatura.fornecedor?.grupo.nome}`}
        </div>
      );
    }
    if (fatura?.profissional?.grupo?.nome) {
      return (
        <div
          className={classNames('w-100 h-100 badge', { 'text-white': !!fatura?.profissional?.grupo?.color || fatura?.profissional?.grupo?.color !== '#ffffff' })}
          style={{ backgroundColor: fatura?.profissional?.grupo?.color ? `${fatura.profissional?.grupo.color}` : 'var(--white)' }}
        >
          <i className="fa-solid fa-circle mr-2" />
          {`Grupo ${fatura.profissional?.grupo.nome}`}
        </div>
      );
    }

    return (
      <Badge className="w-100 h-100" tag="div">
        <i className="fa-solid fa-exclamation-circle mr-2" />
        Sem grupo
      </Badge>
    );
  }, [fatura]);

  const renderValor = useCallback(() => (
    <div
      className={classNames('w-100 h-100 badge text-white', { 'bg-danger': fatura?.tipo === 'conta-pagar', 'bg-success': fatura?.tipo === 'conta-receber' })}
    >
      <i className="fa-solid fa-money-bill-1-wave mr-2" />
      {fatura.situacao === 'pago' ? `Valor Pago ${numberToCurrency(fatura.valor || 0)}` : `Valor Atual da Fatura ${numberToCurrency(fatura.valor || 0)}`}
    </div>
  ), [fatura]);

  const renderFechamento = useCallback(() => (
    <div
      className={classNames('w-100 h-100 badge text-white', { 'bg-danger': !fatura?.dataVencimento, 'bg-info': fatura?.dataVencimento })}
    >
      <i className="fa-solid fa-calendar-day mr-2" />
      {fatura?.dataFechamento ? `Fechamento ${format(parseISO(fatura.dataFechamento), 'dd/MM/yyyy', { locale: ptBR })}` : 'Sem data de Fechamento'}
    </div>
  ), [fatura]);

  const renderVencimento = useCallback(() => (
    <div
      className={classNames('w-100 h-100 badge text-white', { 'bg-danger': !fatura?.dataVencimento, 'bg-info': fatura?.dataVencimento })}
    >
      <i className="fa-solid fa-calendar-day mr-2" />
      {fatura?.dataVencimento ? `Vencimento ${format(parseISO(fatura.dataVencimento), 'dd/MM/yyyy', { locale: ptBR })}` : 'Sem data de Vencimento'}
    </div>
  ), [fatura]);

  const renderEmissao = useCallback(() => (
    <div
      className={classNames('w-100 h-100 badge text-white', { 'bg-danger': !fatura?.dataEmissao, 'bg-info': fatura?.dataEmissao })}
    >
      <i className="fa-solid fa-calendar-day mr-2" />
      {fatura?.dataEmissao ? `Emitido em ${format(parseISO(fatura.dataEmissao), 'dd/MM/yyyy HH:mm', { locale: ptBR })}` : 'Sem data de Emissão'}
    </div>
  ), [fatura]);

  const renderPagamento = useCallback(() => (
    <div
      className={classNames('w-100 h-100 badge text-white', { 'bg-danger': !fatura?.dataPagamento, 'bg-info': fatura?.dataPagamento })}
    >
      <i className="fa-solid fa-calendar-day mr-2" />
      {fatura?.dataPagamento ? `Pago em ${format(parseISO(fatura.dataPagamento), 'dd/MM/yyyy HH:mm', { locale: ptBR })}` : 'Sem data de Pagamento'}
    </div>
  ), [fatura]);

  const renderFormaPagamento = useCallback(() => {
    if (fatura?.formaPagamento === 'dinheiro') {
      return (
        <Badge id={`formaPagamento-${fatura._id}`} className="w-100 h-100" color="success" tag="div">
          <i className="fa-solid fa-money-check mr-2" />
          Forma de Pagamento: Dinheiro
        </Badge>
      );
    }

    if (fatura?.formaPagamento === 'nicash') {
      return (
        <Badge id={`formaPagamento-${fatura._id}`} className="w-100 h-100" color="warning" tag="div">
          <i className="fa-solid fa-money-check mr-2" />
          Forma de Pagemento: NICASH
        </Badge>
      );
    }

    return null;
  }, [fatura]);

  const onAgendamentoCancelar = useCallback(({ motivoCancelamento, dataCancelamento }) => {
    setFatura((d) => ({ ...d, agendamento: { ...d.agendamento, status: 'cancelado', motivoCancelamento, dataCancelamento } }));
    setLoading(false);
    notificationRef.current.notify({
      message: 'Agendamento cancelado com sucesso',
    });
  }, []);

  const handleAgendamentoCancelamento = useCallback(() => {
    alertRef.current.show({
      title: 'Cancelar Atendimento',
      message: 'Deseja realmente cancelar o atendimento?',
      onConfirm: () => {
        alertRef.current.close();
        modalMotivoCancelamento.current.show({ _id: fatura.agendamento._id, onSave: onAgendamentoCancelar });
      },
    });
  }, [fatura]);

  const handleFecharFatura = useCallback(() => {
    modalFecharFatura.current.show({
      id: fatura._id,
      valor: fatura.valor || 0,
      ...searchData,
      onSave: () => loadFatura(),
    });
  }, [fatura, searchData]);

  const handleGerarFolhaPagamento = useCallback(() => {
    const { contaDe, cliente, profissional, dataVencimento } = fatura;
    modalEmitirFatura.current.show({
      selectedRows: [fatura],
      dataVencimento,
      subTitle: `Gerar Folha de Pagamento para ${contaDe === 'cliente' ? `o cliente ${cliente?.nome}` : `o profissional ${profissional?.name}`}`,
      onSave: () => loadFatura(),
    });
  }, [fatura]);

  const reabrirFatura = useCallback(() => {
    if (id) {
      (async () => {
        try {
          setLoading(true);
          const { searchArray, page, limit } = searchData;
          update(id, '/financeiros', { status: 'aberto' }, () => loadFatura(), null, true, searchArray, page, limit, true);
          alertRef.current.close();
          setLoading(false);
          notificationRef.current.notify({
            message: 'Fatura aberta com sucesso',
          });
        } catch (err) {
          setLoading(false);
          console.log(err);
          if (err && err.response && err.response.data) {
            const { message } = err.response.data;

            if (message) {
              notificationRef.current.notify({
                message,
                color: 'danger',
              });

              return;
            }
          }

          notificationRef.current.notify({
            message: 'Falha ao realizar Login Admin',
            color: 'danger',
          });
        }
      })();
    }
  }, [id, searchData]);

  const handleReabrirFatura = useCallback(() => {
    alertRef.current.show({
      title: 'Reabrir Fatura',
      message: 'Deseja realmente reabrir a fatura? Esta ação vai Inativar a folha de pagamento, apagar as datas de vencimento, fechamento, emissão e alterar a situação da fatura para a vencer.',
      onConfirm: reabrirFatura,
    });
  }, [reabrirFatura]);

  const handleDownloadFile = useCallback(() => {
    const { folhaPagamento, comprovante } = fatura;

    if (folhaPagamento?._id || comprovante?._id) {
      const { filename, name } = folhaPagamento || comprovante;

      (async () => {
        try {
          setLoading({
            loading: true,
            message: 'Baixando arquivo...',
          });

          const res = await api.get(`/download/${filename}/files`, { responseType: 'blob' });
          const fileURL = window.URL.createObjectURL(res.data);
          const alink = document.createElement('a');
          alink.href = fileURL;
          alink.target = '_blank';
          alink.rel = 'noopener noreferrer';
          alink.download = name;
          alink.click();

          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error(error);
        }
      })();
    }
  }, [fatura]);

  const handleMarkAsPaid = useCallback(() => {
    if (fatura?._id) {
      (async () => {
        try {
          setLoading({
            loading: true,
            message: 'Marcando fatura como paga...',
          });

          await api.put(`/financeiros/${fatura._id}/pago`);

          setLoading(false);
          notificationRef.current.notify({
            message: 'Fatura marcada como paga com sucesso',
          });

          console.log('🚀 ~ onChange:', change.current);
          if (typeof change.current === 'function') {
            change.current();
          }
          loadFatura();
        } catch (error) {
          setLoading(false);
          console.error(error);
          if (error && error.response && error.response.data) {
            const { message } = error.response.data;

            if (message) {
              notificationRef.current.notify({
                message,
                color: 'danger',
              });

              return;
            }
          }

          notificationRef.current.notify({
            message: 'Falha ao marcar fatura como paga',
            color: 'danger',
          });
        }
      })();
    }
  }, [fatura, change]);

  const renderStatusButtons = useCallback(() => {
    if (fatura?.situacao === 'pago') return null;

    const { status } = fatura;

    if (status === 'aberto') {
      return (
        <Button color="warning" onClick={handleFecharFatura} block>
          Fechar Fatura
        </Button>
      );
    }

    if (status === 'fechado') {
      return (
        <Button color="warning" onClick={handleGerarFolhaPagamento} block>
          Gerar Folha de Pagamento
        </Button>
      );
    }

    if (status === 'emitido') {
      return (
        <Button color="info" onClick={handleReabrirFatura} block>
          Reabrir Fatura
        </Button>
      );
    }

    return null;
  }, [fatura]);

  const handlePagarFatura = useCallback(() => {
    modalPagarFatura.current.show({
      id: fatura._id,
      ...searchData,
      onSave: () => loadFatura(),
    });
  }, [fatura, searchData]);

  const renderSituacaoButtons = useCallback(() => {
    if (fatura?.situacao === 'pago') return null;

    const { situacao, status, contaDe } = fatura;

    if (status === 'emitido' || situacao === 'vencido' || contaDe === 'fornecedor') {
      return (
        <Button color="success" onClick={handlePagarFatura} block>
          Pagar Fatura
        </Button>
      );
    }

    return (
      <Button color="info" onClick={handleMarkAsPaid} block>
        Marcar como Pago
      </Button>
    );
  }, [fatura]);

  const handleAddObservacoes = useCallback(() => {
    (async () => {
      try {
        const dataToValidation = {
          observacao,
        };

        const schema = Yup.object().shape({
          observacao: Yup.string().required('Informe esse campo'),
        });

        setLoading({
          loading: true,
          message: 'Salvando observação',
        });

        await schema.validate(dataToValidation, {
          abortEarly: false,
        });

        const { data } = await api.post(`/financeiroObservacoes/${id}`, { observacao });

        setObservacao('');
        setObservacoes(data?.observacoes || []);
        setDatas((prevDatas = []) => prevDatas.map((d) => (d._id === id ? { ...d, observacoes: data?.observacoes || [] } : d)));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        setValidationErrors((prevState) => ({
          ...prevState,
          observacao: '',
        }));
        if (err instanceof Yup.ValidationError) {
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors((prevState) => ({ ...prevState, ...ve }));
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao salvar observação',
          color: 'danger',
        });
      }
    })();
  }, [id, observacao]);

  const handleRemoveObservacao = useCallback((_id) => {
    (async () => {
      try {
        setLoading({
          loading: true,
          message: 'Excluindo observação',
        });

        await api.delete(`/financeiroObservacoes/${_id}`);

        setObservacao('');
        setObservacoes((prevObservacoes = []) => (prevObservacoes.filter((o) => o._id !== _id)));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao Inativar observação',
          color: 'danger',
        });
      }
    })();
  }, []);

  const {
    status,
    dataHoraInicio,
    dataCancelamento,
    motivoCancelamento,
    canceladoByAdmin,
    canceladoByProfissional,
    canceladoByCliente,
  } = fatura?.agendamento || {};

  const renderCanceladoBy = useCallback(() => {
    if (canceladoByAdmin?.name) {
      return (
        <Badge color="danger" className="text-wrap">
          <span>Cancelado</span>
          {` pelo Admin ${canceladoByAdmin.name} em ${format(new Date(dataCancelamento), 'dd/MM/yyyy HH:mm', { locale: ptBR })}`}
        </Badge>
      );
    }

    if (canceladoByProfissional?.name) {
      return (
        <Badge color="danger" className="text-wrap">
          <span>Cancelado</span>
          {` pelo Profissional ${canceladoByProfissional.name} em ${format(new Date(dataCancelamento), 'dd/MM/yyyy HH:mm', { locale: ptBR })}`}
        </Badge>
      );
    }

    if (canceladoByCliente?.nome) {
      return (
        <>
          <Badge color="danger" className="text-wrap">
            <span>Cancelado</span>
            {` pelo Cliente ${canceladoByCliente.nome} em ${format(new Date(dataCancelamento), 'dd/MM/yyyy HH:mm', { locale: ptBR })}`}
          </Badge>
          {isAfter(new Date(dataCancelamento), subDays(new Date(dataHoraInicio), 1)) && canceladoByCliente && (
            <>
              <UncontrolledTooltip target="#agendamento-cancel">
                O cancelamento foi realizado,
                porém como cliente solicitou o cancelamento com menos de 24 horas para o atendimento,
                ele foi informado que será realizado uma cobrança baseada nas horas agendadas
              </UncontrolledTooltip>
              <Badge id="agendamento-cancel" color="warning">Com Cobrança</Badge>
            </>
          )}
        </>
      );
    }

    return (
      <Badge>
        Não Identificado
      </Badge>
    );
  }, [canceladoByAdmin, canceladoByProfissional, canceladoByCliente, dataCancelamento]);

  return (
    <Modal
      isOpen={show}
      fullscreen
    >
      <ModalHeader>
        <strong>Editar Financeiro</strong>
        <Button
          className="close position-absolute top-4 right-4 py-1 px-2"
          onClick={() => setShow(false)}
        >
          <i className="fas fa-times" />
        </Button>
      </ModalHeader>
      <ModalBody>
        <Container fluid>
          <AlertModal ref={alertRef} />
          <CustomModalEmitirFatura ref={modalEmitirFatura} />
          <CustomModalFecharFatura ref={modalFecharFatura} />
          <CustomModalPagarFatura ref={modalPagarFatura} />
          <CustomModalMotivoCancelamento ref={modalMotivoCancelamento} />
          {fatura?._id && (
          <Row>
            <Col>
              <Row>
                <Col>
                  <Card>
                    <CardHeader tag="h4">
                      {fatura.tipo === 'conta-receber' ? `Conta a Receber ${fatura?.cliente?.nome || fatura?.profissional?.name}` : `Conta a Pagar ${fatura?.profissional?.name || fatura?.fornecedor?.nome || fatura.cliente.nome}`}
                    </CardHeader>
                    <CardBody>
                      {fatura.tipo === 'conta-pagar' && fatura?.agendamento?.cliente && (
                        <>
                          <Row>
                            <Col>
                              <h5>Paciente</h5>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col xs={6}>
                              {fatura.agendamento.cliente.nome}
                            </Col>
                            <Col xs={6}>
                              {fatura?.agendamento?.cliente?.cpf && (
                              <PatternFormat
                                format="###.###.###-##"
                                value={fatura?.agendamento?.cliente?.cpf}
                                displayType="text"
                                renderText={(formattedValue) => formattedValue}
                              />
                              )}
                            </Col>
                          </Row>
                          {fatura?.agendamento?.clienteUserData?.responsaveis?.length ? (
                            renderResponsaveis(fatura.agendamento.clienteUserData.responsaveis)
                          ) : null}
                        </>
                      )}
                      {fatura?.clienteUserData?.responsaveis?.length ? (
                        renderResponsaveis(fatura.clienteUserData.responsaveis)
                      ) : null}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {fatura.agendamento && (
                <Row>
                  <Col>
                    <Card>
                      <CardHeader tag="h5">
                        Detalhes do Agendamento
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col xs={12} sm={6}>
                            <Row>
                              <Col>
                                {`Profissional: ${fatura.agendamento.profissional.name}`}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                {`Sala de Atendimento: ${fatura.agendamento.salaAtendimento.nome}`}
                              </Col>
                            </Row>
                            {/* <Row>
                              <Col>
                                {`Tipo de Atendimento: ${fatura.agendamento.tipoAtendimento.nome}`}
                              </Col>
                            </Row> */}
                            <Row>
                              <Col>
                                {`Tipo de Atendimento: ${fatura.agendamento.especialidade.nome}`}
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th colSpan={5}>{`Status do Atendimento: ${fatura.agendamento.status === 'aberto' ? 'Em aberto' : fatura.agendamento.status === 'cancelado' ? 'Cancelado' : 'Concluido'}`}</th>
                                </tr>
                                <tr>
                                  <th>#</th>
                                  <th>Início</th>
                                  <th>Término</th>
                                  <th>Duração</th>
                                  <th>Valor</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="text-truncate">
                                    <UncontrolledTooltip target="agendamento">
                                      Agendamento
                                    </UncontrolledTooltip>
                                    <i id="agendamento" className="fa-solid fa-calendar-day mr-2 text-info" />
                                    Agendamento
                                  </td>
                                  <td>{`${format(new Date(fatura.agendamento.dataHoraInicio), 'dd/MM/yyyy HH:mm', { locale: ptBR })}`}</td>
                                  <td>{`${format(new Date(fatura.agendamento.dataHoraTermino), 'dd/MM/yyyy HH:mm', { locale: ptBR })}`}</td>
                                  <td>{`${convertSecondsToDhms(differenceInSeconds(new Date(fatura.agendamento.dataHoraTermino), new Date(fatura.agendamento.dataHoraInicio)))}`}</td>
                                  <td>{numberToCurrency(fatura.valorAgendamento)}</td>
                                </tr>
                                {fatura.agendamento.status === 'concluido' && fatura.agendamento.dataHoraInicioAtendimento && fatura.agendamento.dataHoraTerminoAtendimento && (
                                  <tr>
                                    <td className="text-truncate">
                                      <UncontrolledTooltip target="atendimento">
                                        Atendimento
                                      </UncontrolledTooltip>
                                      <i id="atendimento" className="fa-regular fa-calendar-check mr-2 text-success" />
                                      Atendimento
                                    </td>
                                    <td>{`${format(new Date(fatura.agendamento.dataHoraInicioAtendimento), 'dd/MM/yyyy HH:mm', { locale: ptBR })}`}</td>
                                    <td>{`${format(new Date(fatura.agendamento.dataHoraTerminoAtendimento), 'dd/MM/yyyy HH:mm', { locale: ptBR })}`}</td>
                                    <td>{`${convertSecondsToDhms(differenceInSeconds(new Date(fatura.agendamento.dataHoraTerminoAtendimento), new Date(fatura.agendamento.dataHoraInicioAtendimento)))}`}</td>
                                    <td>{numberToCurrency(fatura.valorAtendimento)}</td>
                                  </tr>
                                )}
                                {status === 'cancelado' && dataCancelamento && (
                                  <tr>
                                    <td className="text-truncate">
                                      <UncontrolledTooltip target="cancelamento">
                                        Cancelamento
                                      </UncontrolledTooltip>
                                      <i id="cancelamento" className="fa-solid fa-calendar-xmark mr-2 text-danger" />
                                      Cancelamento
                                    </td>
                                    <td colSpan={3} style={{ width: '100%', maxWidth: '150px' }}>
                                      {renderCanceladoBy()}
                                    </td>
                                  </tr>
                                )}
                                {status === 'cancelado' && motivoCancelamento && (
                                  <tr>
                                    <td className="text-truncate">
                                      <UncontrolledTooltip target="cancelamento-motivo">
                                        Motivo do cancelamento
                                      </UncontrolledTooltip>
                                      <i id="cancelamento-motivo" className="fa-solid fa-calendar-week mr-2 text-danger" />
                                      Motivo
                                    </td>
                                    <td colSpan={3} style={{ width: '100%', maxWidth: '150px' }}>
                                      <span className="text-wrap">
                                        {motivoCancelamento}
                                      </span>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                        {fatura?.agendamento?.status === 'aberto' && (
                        <Row className="mt-4">
                          <Col tag="div" className="d-flex justify-content-end">
                            <Button color="danger" onClick={handleAgendamentoCancelamento}>
                              Cancelar Agendamento
                            </Button>
                          </Col>
                        </Row>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <Card>
                    <CardHeader tag="h5">
                      Informações Financeiras
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col xs={12} sm={6} lg={4}>
                          <Row>
                            <Col>
                              <strong>Informações Gerais</strong>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} className="mb-3" style={{ height: '2rem' }}>
                              {renderTipo()}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} className="mb-3" style={{ height: '2rem' }}>
                              {renderContaDe()}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} className="mb-3" style={{ height: '2rem' }}>
                              {renderGrupo()}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} className="mb-3" style={{ height: '2rem' }}>
                              {renderValor()}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} className="mb-3" style={{ height: '2rem' }}>
                              {renderFormaPagamento()}
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} sm={6} lg={4}>
                          <Row>
                            <Col>
                              <strong>Status da Fatura</strong>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} className="mb-3" style={{ height: '2rem' }}>
                              {renderStatus()}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} className="mb-3" style={{ height: '2rem' }}>
                              {renderFechamento()}
                            </Col>
                            <Col xs={12} className="mb-3" style={{ height: '2rem' }}>
                              {renderVencimento()}
                            </Col>
                            <Col xs={12} className="mb-3" style={{ height: '2rem' }}>
                              {renderEmissao()}
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} sm={6} lg={4}>
                          <Row>
                            <Col>
                              <strong>Situação da Fatura</strong>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} className="mb-3" style={{ height: '2rem' }}>
                              {renderSituacao()}
                            </Col>
                            <Col xs={12} className="mb-3" style={{ height: '2rem' }}>
                              {renderPagamento()}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {fatura?.comprovante && (
                        <Row className="mt-5">
                          <Col xs={12} sm={6} md={4} className="mb-3">
                            <Button color="success" onClick={handleDownloadFile} block>
                              Download do Comprovante
                            </Button>
                          </Col>
                        </Row>
                      )}
                      <Row className="mt-5">
                        <Col>
                          <Button color="primary" id="toggler-observacoes" block>
                            Observações
                          </Button>
                          <UncontrolledCollapse toggler="#toggler-observacoes">
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col xs={10}>
                                    <FormGroup>
                                      <Label for="observacao">Observaçao</Label>
                                      <Input
                                        id="observacao"
                                        type="textarea"
                                        placeholder="Observação"
                                        value={observacao}
                                        invalid={!!validationErrors.observacao}
                                        onChange={({ target: { value } }) => setObservacao(value)}
                                      />
                                      <FormFeedback>
                                        {validationErrors.observacao}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col
                                    xs={2}
                                    style={{
                                      paddingTop: '2rem',
                                    }}
                                  >
                                    <Button
                                      color="success"
                                      onClick={handleAddObservacoes}
                                      block
                                    >
                                      <i className="fa-solid fa-circle-plus text-white" />
                                    </Button>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    {observacoes.length ? (
                                      observacoes.map((o) => (
                                        <Row
                                          key={`observacao-${o._id}`}
                                          className="mt-2"
                                        >
                                          <Col xs={6}>{o.observacao}</Col>
                                          <Col xs={2}>
                                            {format(
                                              parseISO(o.createdAt),
                                              'dd/MM/yyyy HH:mm:ss',
                                              { locale: ptBR },
                                            )}
                                          </Col>
                                          <Col xs={2}>{o?.createdByUser?.name || ''}</Col>
                                          <Col xs={2}>
                                            {o?.createdByUser?.name && (
                                              <Button
                                                color="danger"
                                                onClick={() => handleRemoveObservacao(o._id)}
                                              >
                                                <i className="fa-solid fa-trash-can text-white" />
                                              </Button>
                                            )}
                                          </Col>
                                        </Row>
                                      ))
                                    ) : (
                                      <div className="d-flex justify-content-center">
                                        Sem Observações
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </UncontrolledCollapse>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardHeader tag="h5">
                      Alterações Financeiras
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col xs={12} sm={6} md={4} className="mb-3">
                          {renderStatusButtons()}
                        </Col>
                        {fatura?.folhaPagamento?._id && (
                          <Col xs={12} sm={6} md={4} className="mb-3">
                            <Button color="success" onClick={handleDownloadFile} block>
                              Download da Folha de Pagamento
                            </Button>
                          </Col>
                        )}
                        <Col xs={12} sm={6} md={4} className="mb-3">
                          {renderSituacaoButtons()}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          )}
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={() => setShow(false)}>
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default forwardRef(FinanceiroEdit);
