import React from 'react';
import { Routes, Route } from 'react-router-dom';

import LayoutAdmin from '../layouts/admin2';
import Admins from '../pages/Admins';
import Cliente from '../pages/Cliente';
import Clientes from '../pages/Clientes';
import ContaPagar from '../pages/ContaPagar';
import ContaReceber from '../pages/ContaReceber';
import Dashboard from '../pages/DashboardAdmin';
import TipoDeAtendimentos from '../pages/Especialidades';
import Financeiro from '../pages/Financeiro';
import Fornecedores from '../pages/Fornecedores';
import Grupos from '../pages/Grupos';
import Profissionais from '../pages/Profissionais';
import Profissional from '../pages/Profissional';
import Relatorios from '../pages/Relatorios';
import SalaAtendimentos from '../pages/SalaAtendimentos';
import SolicitacaoExclusaoConta from '../pages/SolicitacaoExclusaoConta';
import Status from '../pages/Status';
import TabelaValoresCliente from '../pages/TabelaValoresClientes';
import TabelaValoresEspecialista from '../pages/TabelaValoresEspecialistas';
import TipoAtendimentos from '../pages/TipoAtendimentos';

function AppAdminRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LayoutAdmin />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/administradores" element={<Admins />} />
        <Route path="/profissionais" element={<Profissionais />} />
        <Route path="/profissional" element={<Profissional />} />
        <Route path="/profissional/:id" element={<Profissional />} />
        <Route path="/clientes" element={<Clientes />} />
        <Route path="/cliente" element={<Cliente />} />
        <Route path="/cliente/:id" element={<Cliente />} />
        <Route path="/fornecedores" element={<Fornecedores />} />
        <Route path="/especialidades" element={<TipoDeAtendimentos />} />
        <Route path="/grupos" element={<Grupos />} />
        <Route path="/tipos-atendimentos" element={<TipoAtendimentos />} />
        <Route path="/salas-atendimentos" element={<SalaAtendimentos />} />
        <Route path="/status" element={<Status />} />
        <Route path="/tabela-valores-clientes" element={<TabelaValoresCliente />} />
        <Route path="/tabela-valores-especialistas" element={<TabelaValoresEspecialista />} />
        <Route path="/financeiro" element={<Financeiro />} />
        <Route path="/conta-pagar" element={<ContaPagar />} />
        <Route path="/conta-receber" element={<ContaReceber />} />
        <Route path="/relatorios" element={<Relatorios />} />
        <Route path="/solicitar-exclusao-conta" element={<SolicitacaoExclusaoConta />} />
        <Route path="/solicitar-exclusao-conta/:email" element={<SolicitacaoExclusaoConta />} />
      </Route>
    </Routes>
  );
}

export default AppAdminRoutes;
