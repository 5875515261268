import React, { forwardRef } from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';

function CustomSelect({
  label,
  text,
  placeholder,
  value,
  options,
  loading,
  invalidMessage,
  onChange,
  disabled = false,
  ...props
}, ref) {
  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#fff',
      borderRadius: '0.25rem',
      padding: '0.05rem',
      fontSize: '1rem',
      borderColor: invalidMessage ? '#f43560' : '#ced4da',
    }),
    input: (styles) => ({
      ...styles,
      color: '#343a40',
    }),
    option: (styles) => ({
      ...styles,
      cursor: 'default',
      color: '#343a40',

      ':active': {
        ...styles[':active'],
        color: '#fff',
        backgroundColor: '#0082c8',
      },

      ':hover': {
        ...styles[':hover'],
        color: '#fff',
        backgroundColor: '#0082c8',
      },
    }),
    menu: (styles) => ({
      ...styles,
    }),
  };

  return (
    <div className="mb-3">
      {label ? <Label>{label}</Label> : null}
      <Select
        ref={ref}
        className="basic-single"
        classNamePrefix="select"
        placeholder={(
          <div
            className={
              invalidMessage
                ? 'react-select__placeholder_error'
                : 'react-select__placeholder'
            }
          >
            {placeholder}
          </div>
        )}
        noOptionsMessage={() => 'Nenhum valor encontrado'}
        loadingMessage={() => 'Carregando'}
        isLoading={loading}
        isClearable
        isSearchable
        value={value}
        options={options}
        styles={selectStyles}
        onChange={onChange}
        isDisabled={disabled}
        {...props}
      />
      {invalidMessage && (
        <div className="text-danger invalid-feedback d-inline">
          {invalidMessage}
        </div>
      )}
      {text && (
        <div className="form-text">
          <small className="form-text text-muted">{text}</small>
        </div>
      )}
    </div>
  );
}

export default forwardRef(CustomSelect);
