import React from 'react';
import { Button, Row, Progress, Container, Col, UncontrolledTooltip } from 'reactstrap';

const renderMimeTypes = (mimetype = '') => {
  const fontSize = '2.5rem';
  if (mimetype === 'application/pdf') {
    return <i className="fa-solid fa-file-pdf text-danger" style={{ fontSize }} />;
  }

  if (mimetype?.split('/')?.[0] === 'image') {
    return <i className="fa-regular fa-file-image text-warning" style={{ fontSize }} />;
  }

  if (mimetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    || mimetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.template'
    || mimetype === 'application/vnd.ms-word.document.macroEnabled.12'
    || mimetype === 'application/msword') {
    return <i className="fa-solid fa-file-word text-info" style={{ fontSize }} />;
  }

  if (mimetype === 'application/vnd.ms-excel'
    || mimetype === 'application/vnd.ms-excel.sheet.macroEnabled.12'
    || mimetype === 'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
    || mimetype === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    return <i className="fa-solid fa-file-excel text-success" style={{ fontSize }} />;
  }

  if (mimetype === 'text/csv') {
    return <i className="fa-solid fa-file-csv text-success" style={{ fontSize }} />;
  }

  return <i className="fa-solid fa-file text-gray" style={{ fontSize }} />;
};

function FileList({ files, onDelete, onDownload }) {
  return (
    <Container className="py-2">
      {files.map(
        (uploadedFile) => uploadedFile && (
        <Row
          key={uploadedFile.id}
          className="align-items-center my-2 p-2"
          style={{ borderTop: '1px solid var(--gray)' }}
        >
          <Col sm={1}>
            {renderMimeTypes(uploadedFile.mimetype)}
          </Col>
          <Col>
            <Row key={uploadedFile.id}>
              <Col xs={10} md={9}>
                <div className="mr-2 text-truncate">
                  {uploadedFile.name}
                </div>
                <div style={{ fontSize: '0.5rem' }}>
                  {`${uploadedFile.readableSize}`}
                </div>
              </Col>
              <Col xs={2} md={1} className="p-0">
                {uploadedFile.filename && onDownload && (
                <a
                  href={uploadedFile.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <UncontrolledTooltip target="#download-button-id">
                    Download
                  </UncontrolledTooltip>
                  <Button
                    id="download-button-id"
                    color="success"
                    size="sm"
                    onClick={() => onDownload(uploadedFile.filename, uploadedFile.name)}
                    block
                  >
                    <i className="fa-solid fa-download" />
                  </Button>
                </a>
                )}
              </Col>
              <Col xs={12} md={2}>
                {uploadedFile.id && onDelete && (
                <>
                  <UncontrolledTooltip target="#delete-button-id">
                    Excluir
                  </UncontrolledTooltip>
                  <Button
                    id="delete-button-id"
                    color="danger"
                    size="sm"
                    onClick={() => onDelete(uploadedFile.id)}
                    block
                  >
                    <i className="fas fa-trash" />
                  </Button>
                </>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {uploadedFile.error && (
                <small className="text-danger">
                  <i className="fas fa-exclamation-circle mr-1" />
                  Erro no upload. Tente enviar novamente.
                </small>
                )}

                {!uploadedFile.error && !uploadedFile.uploaded && (
                <div className="w-100">
                  <small className="text-info mr-3">
                    <i className="fas fa-file-upload mr-1" />
                    Fazendo upload do arquivo
                  </small>
                  {uploadedFile.progress ? (
                    <Progress
                      color="info"
                      value={uploadedFile.progress}
                      style={{ height: '0.5rem' }}
                    />
                  ) : null}
                </div>
                )}

                {uploadedFile.uploaded && (
                <small className="text-success">
                  <i className="fas fa-check-circle mr-1" />
                  Salvo com sucesso
                </small>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        ),
      )}
    </Container>
  );
}

export default FileList;
