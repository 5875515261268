/* eslint-disable max-len */
import React, { useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Row,
  Col,
} from 'reactstrap';

import { yupResolver } from '@hookform/resolvers/yup';

import { useManager } from '../../../hooks/manager';
import DatePickerMaskedInput from '../../DatePickerMaskedInput';
import { schema } from './schemaValidations';

function CustomModalPagarFatura(_props, ref) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const { update, loading } = useManager();
  const { control, setValue, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

  useImperativeHandle(ref, () => ({
    show: (dataValues) => {
      setValue('dataPagamento', new Date());
      setData(dataValues);
      setShow(true);
    },
    close: () => setShow(false),
  }), [setValue]);

  const onSuccess = useCallback((savedData) => {
    console.log('🚀 ~ file: CustomModalPagarFatura index.js ~ onSuccess ~ savedData:', savedData);
    const { onSave } = data;
    if (typeof onSave === 'function') {
      onSave(savedData);
    }

    setShow(false);
  }, [data]);

  const onSubmit = useCallback((formValues) => {
    const { dataPagamento } = formValues;
    const { id, searchArray, page, limit } = data;
    update(id, '/financeiros', { situacao: 'pago', dataPagamento }, onSuccess, null, true, searchArray, page, limit, true);
  }, [data]);

  return (
    <Modal
      isOpen={show}
      onClose={() => setShow(false)}
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          <strong>Pagar Fatura</strong>
          <Button
            className="close position-absolute top-4 right-4 py-1 px-2"
            onClick={() => setShow(false)}
          >
            <i className="fas fa-times" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <strong>Informe a data do pagamento para pagar a Fatura.</strong>
            </Col>
          </Row>
          <Controller
            name="dataPagamento"
            control={control}
            render={({ field }) => (
              <DatePickerMaskedInput
                label="Data do Pagamento"
                invalidMessage={errors?.dataPagamento?.message}
                onChange={(e) => field.onChange(e)}
                selected={field.value}
              />
            )}
          />
        </ModalBody>
        <ModalFooter tag="div" className="d-flex justify-content-end">
          <Button type="submit" color="success" disabled={loading}>Salvar</Button>
          <Button color="danger" className="ml-3" onClick={() => setShow(false)} disabled={loading}>Cancelar</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default forwardRef(CustomModalPagarFatura);
