import React from 'react';
import { Button } from 'reactstrap';

function CustomButton({
  children,
  color,
  loading = false,
  loadingMessage = 'Carregando...',
  onClick,
  block = false,
}) {
  return (
    <Button
      color={color}
      onClick={onClick}
      block={block}
      disabled={loading}
    >
      {loading ? (
        <>
          <i className="fa-solid fa-spinner mr-3" />
          {loadingMessage}
        </>
      ) : (
        children
      ) }
    </Button>
  );
}

export default CustomButton;
