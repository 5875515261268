/* eslint-disable no-await-in-loop */
/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import React, { useEffect, useState, useCallback, useMemo, useRef, useContext } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledCollapse,
  UncontrolledTooltip,
} from 'reactstrap';

import { addDays, differenceInDays, format, getDate, getDay, getMonth, getYear } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import * as Yup from 'yup';

import AlertModal from '../../components/AlertModal';
import Calendar from '../../components/Calendar';
import DatePickerMaskedInput from '../../components/DatePickerMaskedInput';
import Divider from '../../components/Divider';
import Notification from '../../components/Notification';
import TableManager from '../../components/TableManager';
import TimePickerMaskedInput from '../../components/TimePickerMaskedInput';
import { AppContext } from '../../contexts/app';
import { useManager } from '../../hooks/manager';
import api from '../../services/api';
import apiBrasil from '../../services/apiBrasil';

function SalaAtendimentos() {
  const notificationRef = useRef();
  const alertRef = useRef();
  const { setLoading } = useContext(AppContext);
  const [validationErrors, setValidationErrors] = useState({});
  const { load, destroy, update, store } = useManager();
  const [showCloseSalaAtendimentoModal, setShowCloseSalaAtendimentoModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDisponibilidade, setShowDisponibilidade] = useState(false);
  const [showHoraDisponibilidade, setShowHoraDisponibilidade] = useState(false);
  const [id, setId] = useState();
  const [disponibilidadeId, setDisponibilidadeId] = useState();
  const [nome, setNome] = useState('');
  const [quantidadeVagas, setQuantidadeVagas] = useState('');
  const [disponibilidade, setDisponibilidade] = useState([]);
  const [somenteDiasUteis, setSomenteDiasUteis] = useState(true);
  const [considerarSabado, setConsiderarSabado] = useState(false);
  const [dataInicio, setDataInicio] = useState('');
  const [dataTermino, setDataTermino] = useState('');
  const [dataCloseSalaAtendimentoInicio, setDataCloseSalaAtendimentoInicio] = useState('');
  const [dataCloseSalaAtendimentoTermino, setDataCloseSalaAtendimentoTermino] = useState('');
  const [horaInicio, setHoraInicio] = useState('');
  const [horaTermino, setHoraTermino] = useState('');
  const [newOrUpdateHoraInicio, setNewOrUpdateHoraInicio] = useState('');
  const [newOrUpdateHoraTermino, setNewOrUpdateHoraTermino] = useState('');
  const [daysOfCalendar, setDaysOfCalendar] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);

  useEffect(() => {
    load('/salaAtendimentos', 1, []);
  }, [load]);

  useEffect(() => {
    (async () => {
      try {
        if (showDisponibilidade) {
          setLoading(true);
          const firstDayOfCalendar = daysOfCalendar[0];
          const lastDayOfCalendar = daysOfCalendar[daysOfCalendar.length - 1];

          // console.log(firstDayOfCalendar, lastDayOfCalendar);
          if (firstDayOfCalendar && lastDayOfCalendar) {
            const response = await api.get(`/salaAtendimentoDisponibilidades/${id}?dataHoraInicio=${firstDayOfCalendar}&dataHoraTermino=${lastDayOfCalendar}`);
            // console.log(response.data);
            setDisponibilidade(response.data);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, [daysOfCalendar, showDisponibilidade]);

  const handleUpdate = useCallback((data) => {
    setValidationErrors({});
    setId(data._id);
    setNome(data.nome);
    setQuantidadeVagas(data.quantidadeVagas);
    setShowModal(true);
  }, []);

  const handleCreate = useCallback(() => {
    setValidationErrors({});
    setId(null);
    setNome('');
    setQuantidadeVagas('');
    setShowModal(true);
  }, []);

  const handleShowDisponibilidade = useCallback((salaAtendimento) => {
    setId(salaAtendimento._id);
    setNome(salaAtendimento.nome);
    setShowDisponibilidade(true);
  }, []);

  const handleShowHoraDisponibilidade = useCallback((d) => {
    console.log('🚀 ~ handleShowHoraDisponibilidade ~ d:', d);
    setDisponibilidadeId(d?._id || '');
    setNewOrUpdateHoraInicio(d?.dataHoraInicio ? new Date(d.dataHoraInicio) : '');
    setNewOrUpdateHoraTermino(d?.dataHoraTermino ? new Date(d.dataHoraTermino) : '');
    setValidationErrors({});
    setShowHoraDisponibilidade(true);
  }, []);

  const handleSubmitDisponibilidadeByConditions = useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        const validationData = {
          dataInicio,
          dataTermino,
          horaInicio,
          horaTermino,
        };

        const schema = Yup.object().shape({
          dataInicio: Yup.string().required('Informe a data de início'),
          dataTermino: Yup.string().required('Informe a data de término'),
          horaInicio: Yup.string().required('Informe a hora de início'),
          horaTermino: Yup.string().required('Informe a hora de término'),
        });

        await schema.validate(validationData, {
          abortEarly: false,
        });

        setLoading({
          loading: true,
          message: 'Gerando disponibilidades para período informado',
        });

        const startYear = getYear(dataInicio);
        const endYear = getYear(dataTermino);
        const holidays = [];

        for (let year = startYear; year <= endYear; year++) {
          const { data = [] } = await apiBrasil.get(`/feriados/v1/${year}`);
          holidays.push(...data.map(({ date }) => date));
        }

        console.log('🚀 ~ holidays:', holidays);
        const qtyDays = differenceInDays(dataTermino, dataInicio);
        const dates = [];
        dates.push(dataInicio);
        for (let i = 1; i <= qtyDays; i++) {
          const date = addDays(dataInicio, i);

          if ((somenteDiasUteis && getDay(date) === 0) || (somenteDiasUteis && getDay(date) === 6 && !considerarSabado) || (somenteDiasUteis && holidays.includes(format(date, 'yyyy-MM-dd')))) {
            continue;
          }

          dates.push(date);
        }

        const data = {
          selectedDates: dates,
          horaInicio,
          horaTermino,
        };

        // validation passed
        const res = await api.post(`/salaAtendimentoDisponibilidades/${id}`, data);

        notificationRef.current.notify({
          message: 'Disponibilidade da sala de atendimento cadastrado com sucesso',
        });

        setDisponibilidade((prevStates = []) => [...prevStates.filter((d) => !res.data.find((rd) => rd._id === d._id)), ...res.data].sort((a, b) => new Date(a.dataHoraInicio) - new Date(b.dataHoraInicio)));
        setShowHoraDisponibilidade(false);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message: 'Precisa informar os campos necessários para salvar',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao tentar salvar data hora selecionadas',
          color: 'danger',
        });
      }
    })();
  }, [id, somenteDiasUteis, considerarSabado, dataInicio, dataTermino, horaInicio, horaTermino]);

  const onDeleteDisponibilidadeByConditions = useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        const validationData = {
          dataInicio,
          dataTermino,
        };

        const schema = Yup.object().shape({
          dataInicio: Yup.string().required('Informe a data de início'),
          dataTermino: Yup.string().required('Informe a data de término'),
        });

        await schema.validate(validationData, {
          abortEarly: false,
        });

        setLoading({
          loading: true,
          message: 'Excluindo disponibilidades para período informado',
        });

        // validation passed
        const res = await api.delete(`/salaAtendimentoDisponibilidadesByPerid/${id}?dataInicio=${dataInicio}&dataTermino=${dataTermino}`);

        console.log('🚀 ~ res ~ data:', res.data);
        notificationRef.current.notify({
          message: 'Disponibilidade da sala de atendimento excluidas com sucesso',
        });

        setDisponibilidade((prevStates = []) => [...prevStates.filter((d) => !res.data.find((rd) => rd._id === d._id))].sort((a, b) => new Date(a.dataHoraInicio) - new Date(b.dataHoraInicio)));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message: 'Precisa informar os campos necessários para excluir por período',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao tentar excluir disponibilidade por período',
          color: 'danger',
        });
      }
    })();
  }, [id, dataInicio, dataTermino]);

  const handleDeleteDisponibilidadeByConditions = useCallback(() => {
    alertRef.current.show({
      title: 'Excluir disponibilidades',
      message: `Deseja realmente excluir disponibilidade para sala de atendimento ${nome} no período de ${format(dataInicio, 'dd/MM/yyy')} até ${format(dataTermino, 'dd/MM/yyyy')}?`,
      onConfirm: () => {
        alertRef.current.close();
        onDeleteDisponibilidadeByConditions();
      },
    });
  }, [alertRef, onDeleteDisponibilidadeByConditions, dataInicio, dataTermino]);

  const handleSubmitDisponibilidade = useCallback(() => {
    (async () => {
      try {
        const data = {
          selectedDates,
          horaInicio: newOrUpdateHoraInicio,
          horaTermino: newOrUpdateHoraTermino,
        };

        const schema = Yup.object().shape({
          horaInicio: Yup.string().required('Informe a hora de início'),
          horaTermino: Yup.string().required('Informe a hora de término'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // validation passed
        const res = await api.post(`/salaAtendimentoDisponibilidades/${id}`, data);

        notificationRef.current.notify({
          message: 'Disponibilidade da sala de atendimento cadastrado com sucesso',
        });

        setDisponibilidade((prevStates = []) => [...prevStates, ...res.data]);
        setShowHoraDisponibilidade(false);
      } catch (err) {
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message: 'Precisa informar os campos necessários para salvar',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao tentar salvar data hora selecionadas',
          color: 'danger',
        });
      }
    })();
  }, [id, selectedDates, newOrUpdateHoraInicio, newOrUpdateHoraTermino]);

  const handleUpdateDisponibilidade = useCallback(() => {
    (async () => {
      try {
        const data = {
          horaInicio: newOrUpdateHoraInicio,
          horaTermino: newOrUpdateHoraTermino,
        };

        const schema = Yup.object().shape({
          horaInicio: Yup.string().required('Informe a hora de início'),
          horaTermino: Yup.string().required('Informe a hora de término'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // validation passed
        const res = await api.put(`/salaAtendimentoDisponibilidades/${disponibilidadeId}`, data);

        setDisponibilidade((d) => [
          res.data,
          ...d.filter((value) => (value.id || value._id) !== disponibilidadeId),
        ]);

        notificationRef.current.notify({
          message: 'Disponibilidade da sala de atendimento alterado com sucesso',
        });

        setShowHoraDisponibilidade(false);
      } catch (err) {
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message: 'Precisa informar os campos necessários para salvar',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao tentar salvar data hora selecionadas',
          color: 'danger',
        });
      }
    })();
  }, [disponibilidadeId, newOrUpdateHoraInicio, newOrUpdateHoraTermino]);

  const handleDeleteDisponibilidade = useCallback(() => {
    (async () => {
      try {
        await api.delete(`/salaAtendimentoDisponibilidades/${disponibilidadeId}`);

        notificationRef.current.notify({
          message: 'Disponibilidade da sala de atendimento excluido com sucesso',
        });

        setDisponibilidade((d) => d.filter((value) => (value.id || value._id) !== disponibilidadeId));

        setShowHoraDisponibilidade(false);
      } catch (err) {
        console.log(err);

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha ao tentar excluir disponibilidade',
          color: 'danger',
        });
      }
    })();
  }, [disponibilidadeId]);

  const handleCloseSalaAtendimento = useCallback(() => {
    (async () => {
      try {
        const validationData = {
          dataCloseSalaAtendimentoInicio,
          dataCloseSalaAtendimentoTermino,
        };

        const schema = Yup.object().shape({
          dataCloseSalaAtendimentoInicio: Yup.string().required('Informe a data de início'),
          dataCloseSalaAtendimentoTermino: Yup.string().required('Informe a data de término'),
        });

        await schema.validate(validationData, {
          abortEarly: false,
        });
        setLoading(true);

        const data = {
          dataInicio: dataCloseSalaAtendimentoInicio,
          dataTermino: dataCloseSalaAtendimentoTermino,
        };

        await api.put('/salaAtendimentoDisponibilidadesClose', data);
        setLoading(false);
        notificationRef.current.notify({
          message: 'Salas fechadas com sucesso',
        });
      } catch (err) {
        setLoading(false);
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message: 'Por favor, preencha todos os dados necessários para salvar',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notificationRef.current.notify({
            message,
            color: 'danger',
          });

          return;
        }

        notificationRef.current.notify({
          message: 'Algo inesperado aconteceu e não foi possível salvar',
          color: 'danger',
        });
      }
    })();
  }, [dataCloseSalaAtendimentoInicio, dataCloseSalaAtendimentoTermino]);

  const handleSave = async () => {
    try {
      const data = {
        nome,
        quantidadeVagas,
      };

      const schema = Yup.object().shape({
        nome: Yup.string().required('Informe o nome da sala de atendimento'),
        quantidadeVagas: Yup.string().required('Informe o número da quantidade de vagas da sala'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (id) {
        update(id, '/salaAtendimentos', data, (salaAtendimento) => {
          setNome(salaAtendimento.nome);
          setLoading(false);
          setShowModal(false);
          setShowDisponibilidade(true);
        }, () => setLoading(false));
        return;
      }

      store('/salaAtendimentos', data, (salaAtendimento) => {
        setId(salaAtendimento._id);
        setNome(salaAtendimento.nome);
        setLoading(false);
        setShowModal(false);
        setShowDisponibilidade(true);
      }, () => setLoading(false));
    } catch (err) {
      setLoading(false);
      console.log(err);
      setValidationErrors({});
      // Validation failed
      if (err instanceof Yup.ValidationError) {
        notificationRef.current.notify({
          message: 'Por favor, preencha todos os dados necessários para salvar',
          color: 'warning',
        });
        const ve = {};
        err.inner.forEach((e) => {
          ve[e.path] = e.message;
        });
        setValidationErrors(ve);
        return;
      }

      if (err && err.response && err.response.data) {
        const { message } = err.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });

        return;
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível salvar',
        color: 'danger',
      });
    }
  };

  const columns = useMemo(() => [
    {
      dataField: 'nome',
      text: 'Nome',
      textFilter: true,
    },
    {
      dataField: 'quantidadeVagas',
      text: 'Quantidade de Vagas',
      textFilter: true,
    },
  ]);

  const actions = useMemo(
    () => [
      {
        name: 'show-disponibilidade',
        tooltip: 'Gerenciar disponibilidade',
        color: 'warning',
        className: 'mt-2 mr-2 text-white',
        icon: 'fas fa-calendar',
        onClick: (row) => handleShowDisponibilidade(row),
      },
    ],
    [],
  );

  const renderDayContents = useCallback((dateValue) => {
    const disponibilidadeFilter = disponibilidade.filter(
      (d) => (getDate(new Date(d.dataHoraInicio)) === getDate(dateValue)
        && getMonth(new Date(d.dataHoraInicio)) === getMonth(dateValue)
        && getYear(new Date(d.dataHoraInicio)) === getYear(dateValue)
      ),
    );
    // console.log(disponibilidadeFilter);
    if (disponibilidadeFilter.length > 0) {
      return (
        <>
          <span>{dateValue ? format(dateValue, 'dd', { locale: ptBR }) : ''}</span>
          <div className="position-relative">
            <div
              className="d-flex flex-wrap"
              style={{
                position: 'absolute',
                top: '-18px',
                left: '-55px',
                width: 'max-content',
                height: '35px',
              }}
            >
              {disponibilidadeFilter.map((event) => (
                <div key={event._id}>
                  <UncontrolledTooltip target={`calendar-event-${event._id}`}>
                    {`${event?.dataHoraInicio ? format(new Date(event.dataHoraInicio), 'HH:mm', { locale: ptBR }) : 'Sem Data Hora de Início'} - ${event?.dataHoraTermino ? format(new Date(event.dataHoraTermino), 'HH:mm', { locale: ptBR }) : 'Sem Data Hora de Término'}`}
                  </UncontrolledTooltip>
                  <Button
                    className="p-0"
                    color="link"
                    id={`calendar-event-${event._id}`}
                    onClick={() => handleShowHoraDisponibilidade(event)}
                  >
                    <i className="fas fa-circle text-primary" />
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </>
      );
    }

    return dateValue ? format(dateValue, 'dd', { locale: ptBR }) : '';
  }, [disponibilidade]);

  return (
    <Container fluid>
      <AlertModal ref={alertRef} />
      <Row className="mt-5">
        <Col>
          <Notification ref={notificationRef} />
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h3>Salas de Atendimentos</h3>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col>
                  <Row>
                    <Col xs={12} sm={5} md={3} className="mb-3 mb-xl-0">
                      <Button block color="success" onClick={handleCreate}>
                        Cadastrar
                      </Button>
                    </Col>
                    <Col xs={12} sm={5} md={3} className="mb-3 mb-xl-0">
                      <Button block color="danger" onClick={() => setShowCloseSalaAtendimentoModal(true)}>
                        Fechar Salas de Atendimento
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableManager
            initialColumns={columns}
            actions={actions}
            onUpdate={(row) => handleUpdate(row)}
            onDelete={(row) => destroy(row._id, '/salaAtendimentos')}
          />
        </Col>
      </Row>
      <Modal
        isOpen={showHoraDisponibilidade}
        toggle={() => setShowHoraDisponibilidade(!showHoraDisponibilidade)}
        centered
      >
        <ModalHeader>
          {disponibilidadeId ? (
            <strong>
              {`Alterar ou Excluir a disponibilidade do dia ${newOrUpdateHoraInicio ? format(newOrUpdateHoraInicio, 'dd/MM/yyyy') : 'não defenido'}`}
            </strong>
          ) : (
            <strong>
              Informe a hora de início e término para a(s) data(s)
              selecionada(s)
            </strong>
          )}
          <Button
            className="close position-absolute top-4 right-4 py-1 px-2"
            onClick={() => setShowHoraDisponibilidade(false)}
          >
            <i className="fas fa-times" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={6}>
              <TimePickerMaskedInput
                title="Hora Início"
                label="Hora Início"
                invalidMessage={validationErrors.horaInicio}
                selected={newOrUpdateHoraInicio}
                onChange={(date) => setNewOrUpdateHoraInicio(date)}
              />
            </Col>
            <Col xs={6}>
              <TimePickerMaskedInput
                title="Hora Término"
                label="Hora Término"
                invalidMessage={validationErrors.horaTermino}
                selected={newOrUpdateHoraTermino}
                onChange={(date) => setNewOrUpdateHoraTermino(date)}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          {disponibilidadeId ? (
            <>
              <Button
                color="primary"
                onClick={handleUpdateDisponibilidade}
                block
              >
                Alterar
              </Button>
              <Button
                color="danger"
                onClick={handleDeleteDisponibilidade}
                block
              >
                Excluir
              </Button>
            </>
          ) : (
            <Button onClick={handleSubmitDisponibilidade} block>
              Confirmar
            </Button>
          )}
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showDisponibilidade}
        toggle={() => setShowDisponibilidade(!showDisponibilidade)}
        size="lg"
        centered
      >
        <ModalHeader>
          <strong>{`Disponibilidade da Sala de Atendimento ${nome}`}</strong>
          <Button
            className="close position-absolute top-4 right-4 py-1 px-2"
            onClick={() => setShowDisponibilidade(false)}
          >
            <i className="fas fa-times" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Button
                id="toggler-gerar-disponibilidade"
                color="primary"
                block
              >
                Gerar Disponibilidade por condições
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <UncontrolledCollapse toggler="#toggler-gerar-disponibilidade">
                <Card className="shadow">
                  <CardBody>
                    <Row>
                      <Col xs={6}>
                        <DatePickerMaskedInput
                          label="Data de"
                          placeholderText="Data de"
                          selected={dataInicio}
                          onChange={(date) => setDataInicio(date)}
                        />
                      </Col>
                      <Col xs={6}>
                        <DatePickerMaskedInput
                          label="Data até"
                          placeholderText="Data até"
                          selected={dataTermino}
                          onChange={(date) => setDataTermino(date)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <TimePickerMaskedInput
                          title="Hora Início"
                          label="Hora Início"
                          invalidMessage={validationErrors.horaInicio}
                          selected={horaInicio}
                          onChange={(date) => setHoraInicio(date)}
                        />
                      </Col>
                      <Col xs={6}>
                        <TimePickerMaskedInput
                          title="Hora Término"
                          label="Hora Término"
                          invalidMessage={validationErrors.horaTermino}
                          selected={horaTermino}
                          onChange={(date) => setHoraTermino(date)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col tag="div" className="d-flex">
                        <FormGroup check>
                          <Input
                            type="checkbox"
                            checked={somenteDiasUteis}
                            onChange={() => setSomenteDiasUteis((prevState) => {
                              if (!prevState) {
                                setConsiderarSabado(false);
                              }
                              return !prevState;
                            })}
                          />
                          <Label check>Somente dias úteis</Label>
                        </FormGroup>
                        {somenteDiasUteis && (
                          <FormGroup check className="ml-3">
                            <Input
                              type="checkbox"
                              checked={considerarSabado}
                              onChange={() => setConsiderarSabado((prevState) => !prevState)}
                            />
                            <Label check>Considerar Sábados</Label>
                          </FormGroup>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          color="success"
                          onClick={handleSubmitDisponibilidadeByConditions}
                          disabled={!dataInicio && !dataTermino && !horaInicio && !horaTermino}
                          block
                        >
                          Gerar Disponibilidades
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </UncontrolledCollapse>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Button
                id="toggler-excluir-disponibilidade"
                color="danger"
                block
              >
                Excluir disponibilidade por período
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <UncontrolledCollapse toggler="#toggler-excluir-disponibilidade">
                <Card className="shadow">
                  <CardBody>
                    <Row>
                      <Col xs={6}>
                        <DatePickerMaskedInput
                          label="Data de"
                          placeholderText="Data de"
                          selected={dataInicio}
                          onChange={(date) => setDataInicio(date)}
                        />
                      </Col>
                      <Col xs={6}>
                        <DatePickerMaskedInput
                          label="Data até"
                          placeholderText="Data até"
                          selected={dataTermino}
                          onChange={(date) => setDataTermino(date)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          color="danger"
                          onClick={handleDeleteDisponibilidadeByConditions}
                          disabled={!dataInicio && !dataTermino}
                          block
                        >
                          Excluir Disponibilidades
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </UncontrolledCollapse>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-flex align-items-center">
                <Divider width="30%" />
                <span className="my-3">ou selecione as datas</span>
                <Divider width="30%" />
              </div>
            </Col>
          </Row>
          <Calendar
            selectedDates={selectedDates}
            renderDayContents={renderDayContents}
            onDaysOfCalendarChange={(dates) => setDaysOfCalendar(dates)}
            onSelectedDatesChange={(dates) => setSelectedDates(dates)}
          />
        </ModalBody>
        <ModalFooter>
          {selectedDates.length ? (
            <Button color="success" onClick={handleShowHoraDisponibilidade}>
              Confirmar data(s) selecionada(s)
            </Button>
          ) : (
            <Button color="danger" onClick={() => setShowDisponibilidade(false)}>
              Fechar
            </Button>
          )}
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        size="lg"
        centered
      >
        <ModalHeader>
          <strong>Sala de Atendimento</strong>
          <Button
            className="close position-absolute top-4 right-4 py-1 px-2"
            onClick={() => setShowModal(false)}
          >
            <i className="fas fa-times" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Input
                type="text"
                placeholder="Nome"
                value={nome}
                invalid={!!validationErrors.nome}
                onChange={({ target: { value } }) => setNome(value)}
              />
              <FormFeedback>{validationErrors.nome}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Input
                type="number"
                placeholder="Quantidade de Vagas"
                value={quantidadeVagas}
                invalid={!!validationErrors.quantidadeVagas}
                onChange={({ target: { value } }) => setQuantidadeVagas(value)}
              />
              <FormFeedback>{validationErrors.quantidadeVagas}</FormFeedback>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSave}>Salvar</Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showCloseSalaAtendimentoModal}
        toggle={() => setShowCloseSalaAtendimentoModal(!showCloseSalaAtendimentoModal)}
        centered
      >
        <ModalHeader>
          <strong>Fechar Sala de Atendimento por período</strong>
          <Button
            className="close position-absolute top-4 right-4 py-1 px-2"
            onClick={() => setShowCloseSalaAtendimentoModal(false)}
          >
            <i className="fas fa-times" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={6}>
                <DatePickerMaskedInput
                  label="Data de"
                  placeholderText="Data de"
                  selected={dataCloseSalaAtendimentoInicio}
                  invalidMessage={validationErrors.dataCloseSalaAtendimentoInicio}
                  onChange={(date) => setDataCloseSalaAtendimentoInicio(date)}
                />
              </Col>
              <Col xs={6}>
                <DatePickerMaskedInput
                  label="Data até"
                  placeholderText="Data até"
                  selected={dataCloseSalaAtendimentoTermino}
                  invalidMessage={validationErrors.dataCloseSalaAtendimentoTermino}
                  onChange={(date) => setDataCloseSalaAtendimentoTermino(date)}
                />
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleCloseSalaAtendimento}>Fechar Salas nesse período</Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}

export default SalaAtendimentos;
