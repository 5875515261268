import React, { forwardRef } from 'react';
import { PatternFormat } from 'react-number-format';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';

function CustomMaskedInput({
  id,
  label,
  format,
  placeholder,
  value,
  invalidMessage,
  withFormGroup = true,
  ...rest }, ref) {
  if (!withFormGroup) {
    return (
      <PatternFormat
        id={id}
        ref={ref}
        customInput={Input}
        type="text"
        format={format}
        mask="_"
        invalid={!!invalidMessage}
        valueIsNumericString
        placeholder={placeholder}
        value={value}
        {...rest}
      />
    );
  }

  return (
    <FormGroup>
      {label && <Label for={id}>{label}</Label>}
      <PatternFormat
        id={id}
        ref={ref}
        customInput={Input}
        type="text"
        format={format}
        mask="_"
        invalid={!!invalidMessage}
        valueIsNumericString
        placeholder={placeholder}
        value={value}
        {...rest}
      />
      <FormFeedback>{invalidMessage}</FormFeedback>
    </FormGroup>
  );
}

export default forwardRef(CustomMaskedInput);
