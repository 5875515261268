/* eslint-disable max-len */
import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { PatternFormat } from 'react-number-format';
import { Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';

import { AppContext } from '../../contexts/app';
import { consultaCep } from '../../services/apiConsultaCep';
import locationApi from '../../services/apiLocalidade';
import Select from '../Select';

function Endereco({
  id,
  validationErrors = {},
  onValidationErrors = () => {},
}, ref) {
  const { setLoading } = useContext(AppContext);
  const [_id, setId] = useState();
  const [cep, setCep] = useState('');
  const [initialCep, setInitialCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [cidadeBySearch, setCidadeBySearch] = useState('');
  const [cidades, setCidades] = useState([]);
  const [loadingCidades, setLoadingCidades] = useState(false);
  const [estado, setEstado] = useState('');
  const [estadoBySearch, setEstadoBySearch] = useState('');
  const [estados, setEstados] = useState([]);
  const [loadingEstados, setLoadingEstados] = useState(false);
  const [referencia, setReferencia] = useState('');
  const [complemento, setComplemento] = useState('');
  const [location, setLocation] = useState('');

  const populateInitialData = useCallback((data) => {
    setId(data?._id || '');
    setInitialCep(data?.cep || '');
    setCep(data?.cep || '');
    setLogradouro(data?.logradouro || '');
    setNumero(data?.numero || '');
    setBairro(data?.bairro || '');
    setCidade(data?.cidade || '');
    setEstado(data?.estado || '');
    setReferencia(data?.referencia || '');
    setComplemento(data?.complemento || '');
  }, []);

  const clearAddress = useCallback(() => {
    setId('');
    setCep('');
    setLogradouro('');
    setNumero('');
    setBairro('');
    setCidade('');
    setCidadeBySearch('');
    setEstado('');
    setEstadoBySearch('');
  }, []);

  useImperativeHandle(ref, () => ({
    setData: (data) => {
      // console.log(data);
      populateInitialData(data);
    },
    getData: () => ({
      _id,
      cep,
      logradouro,
      numero,
      bairro,
      cidade,
      estado,
      referencia,
      complemento,
      location,
    }),
  }), [
    _id,
    cep,
    logradouro,
    numero,
    bairro,
    cidade,
    estado,
    referencia,
    complemento,
  ]);

  useEffect(() => {
    if (!estados.length) {
      (async () => {
        try {
          setLoadingEstados(true);
          const { data = [] } = await locationApi.get('estados?orderBy=nome');
          setEstados(
            data.map((s) => ({
              value: s?.id,
              label: s?.nome,
              sigla: s?.sigla,
            })),
          );
          setLoadingEstados(false);
        } catch (error) {
          setLoadingEstados(false);
          console.log(error);
        }
      })();
    }
  }, [estados]);

  useEffect(() => {
    (async () => {
      try {
        if (estado) {
          setLoadingCidades(true);
          const { data = [] } = await locationApi.get(
            `estados/${estado.value}/municipios`,
          );
          const citiesData = data.map((c) => ({
            value: c?.nome,
            label: c?.nome,
            regiao: c?.microrregiao?.mesorregiao?.nome,
            ibge: c?.id,
          }));
          setCidades(citiesData);
          setLoadingCidades(false);
        }
      } catch (error) {
        setLoadingCidades(false);
        console.log(error);
      }
    })();
  }, [estado]);

  useEffect(() => {
    if (estados.length && estadoBySearch) {
      const eObject = estados.find((e) => e.sigla === estadoBySearch);
      setEstado(eObject || '');
    }
  }, [estados, estadoBySearch]);

  useEffect(() => {
    if (cidades.length && cidadeBySearch) {
      const cObject = cidades.find((c) => c.value === cidadeBySearch);
      setCidade(cObject || '');
    }
  }, [cidades, cidadeBySearch]);

  const handlePesquisaCep = useCallback((value = '') => {
    (async () => {
      try {
        setCep(value);

        // Verifica se campo cep possui valor informado.
        if (value !== '') {
          if (value === initialCep) {
            return;
          }

          // Expressão regular para validar o CEP.
          const validacep = /^[0-9]{8}$/;

          // Valida o formato do CEP.
          if (validacep.test(value)) {
            setLoading(true);

            const data = await consultaCep(value);

            if (data) {
              const { street, neighborhood, location: locationData, state, city } = data;
              onValidationErrors((prevState) => ({ ...prevState, cep: '' }));
              setLogradouro((prevState) => street || prevState);
              setBairro((prevState) => neighborhood || prevState);
              setEstadoBySearch((prevState) => state || prevState);
              setCidadeBySearch((prevState) => city || prevState);
              setLocation((prevState) => locationData || prevState);
              setLoading(false);
              return;
            }

            setLoading(false);
            onValidationErrors((prevState) => ({ ...prevState, cep: 'CEP não encontrado' }));
          }
        }

        clearAddress();
      } catch (error) {
        console.error(error);
        setLoading(false);
        onValidationErrors((prevState) => ({ ...prevState, cep: 'CEP não encontrado' }));
        clearAddress();
      }
    })();
  }, [initialCep]);

  return (
    <>
      <FormGroup>
        <Label for={`${id}-cep`}>CEP</Label>
        <PatternFormat
          customInput={Input}
          id={`${id}-cep`}
          format="##.###-###"
          mask="_"
          valueIsNumericString
          type="text"
          invalid={!!validationErrors.cep}
          placeholder="CEP"
          value={cep}
          onValueChange={({ value }) => handlePesquisaCep(value)}
        />
        <FormFeedback>{validationErrors.cep}</FormFeedback>
      </FormGroup>
      <Row>
        <Col xs={12} sm={10}>
          <FormGroup>
            <Label for={`${id}-logradouro`}>Logradouro</Label>
            <Input
              id={`${id}-logradouro`}
              type="text"
              placeholder="Logradouro"
              value={logradouro}
              invalid={!!validationErrors.logradouro}
              onChange={({ target: { value } }) => setLogradouro(value)}
            />
            <FormFeedback>{validationErrors.logradouro}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs={12} sm={2}>
          <FormGroup>
            <Label for={`${id}-numero`}>Número</Label>
            <Input
              id={`${id}-numero`}
              type="text"
              placeholder="Número"
              value={numero}
              invalid={!!validationErrors.numero}
              onChange={({ target: { value } }) => setNumero(value)}
            />
            <FormFeedback>{validationErrors.numero}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label for={`${id}-bairro`}>Bairro</Label>
        <Input
          id={`${id}-bairro`}
          type="text"
          placeholder="Bairro"
          value={bairro}
          invalid={!!validationErrors.bairro}
          onChange={({ target: { value } }) => setBairro(value)}
        />
        <FormFeedback>{validationErrors.bairro}</FormFeedback>
      </FormGroup>
      <Select
        label="Estado"
        placeholder="Selecione um estado"
        value={estado}
        options={estados}
        loading={loadingEstados}
        invalidMessage={validationErrors.estado}
        onChange={setEstado}
      />
      <Select
        label="Cidade"
        placeholder="Selecione uma cidade"
        value={cidade}
        options={cidades}
        loading={loadingCidades}
        invalidMessage={validationErrors.cidade}
        noOptionsMessage={() => (estado ? 'Nenhum valor encontrado' : 'Selecione um estado')}
        onChange={setCidade}
      />
      <FormGroup>
        <Label for={`${id}-complemento`}>Complemento</Label>
        <Input
          id={`${id}-complemento`}
          type="textarea"
          placeholder="Complemento"
          value={complemento}
          onChange={({ target: { value } }) => setComplemento(value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for={`${id}-referencia`}>Referencia</Label>
        <Input
          id={`${id}-referencia`}
          type="textarea"
          placeholder="referencia"
          value={referencia}
          onChange={({ target: { value } }) => setReferencia(value)}
        />
      </FormGroup>
    </>
  );
}

export default forwardRef(Endereco);
