/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState, useCallback, useMemo, useRef, useContext } from 'react';
import ColorPicker from 'react-pick-color';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import * as Yup from 'yup';

import Notification from '../../components/Notification';
import TableManager from '../../components/TableManager';
import { AppContext } from '../../contexts/app';
import { useManager } from '../../hooks/manager';

function Status() {
  const notificationRef = useRef();
  const { setLoading } = useContext(AppContext);
  const [validationErrors, setValidationErrors] = useState({});
  const { load, destroy, update, store } = useManager();
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState({});
  const [nome, setNome] = useState('');
  const [color, setColor] = useState('#0082c8');

  useEffect(() => {
    load('/statuses', 1, []);
  }, [load]);

  const handleUpdate = useCallback((data) => {
    setValidationErrors({});
    setId(data._id);
    setNome(data.nome);
    setColor(data.color);
    setShowModal(true);
  }, []);

  const handleCreate = useCallback(() => {
    setValidationErrors({});
    setId(null);
    setNome('');
    setColor('#000000');
    setShowModal(true);
  }, []);

  const handleSave = async () => {
    try {
      const data = {
        nome,
        color,
      };

      const schema = Yup.object().shape({
        nome: Yup.string().required('Informe o nome do status'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (id) {
        update(id, '/statuses', data, () => {
          setLoading(false);
          setShowModal(false);
        }, () => setLoading(false));
        return;
      }

      store('/statuses', data, () => {
        setLoading(false);
        setShowModal(false);
      }, () => setLoading(false));
    } catch (err) {
      setLoading(false);
      console.log(err);
      setValidationErrors({});
      // Validation failed
      if (err instanceof Yup.ValidationError) {
        notificationRef.current.notify({
          message: 'Por favor, preencha todos os dados necessários para salvar',
          color: 'warning',
        });
        const ve = {};
        err.inner.forEach((e) => {
          ve[e.path] = e.message;
        });
        setValidationErrors(ve);
        return;
      }

      if (err && err.response && err.response.data) {
        const { message } = err.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });

        return;
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível salvar',
        color: 'danger',
      });
    }
  };

  const statusFormatter = useCallback(
    (row) => <i className="fa-solid fa-circle" style={{ color: row.color }} />,
    [],
  );

  const columns = useMemo(() => [
    {
      dataField: 'nome',
      text: 'Nome',
      textFilter: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: statusFormatter,
    },
  ]);

  return (
    <Container fluid>
      <Row className="mt-5">
        <Col>
          <Notification ref={notificationRef} />
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h3>Status</h3>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col>
                  <Row>
                    <Col xs={12} sm={5} md={3} className="mb-3 mb-xl-0">
                      <Button block color="success" onClick={handleCreate}>
                        Cadastrar
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableManager
            initialColumns={columns}
            onUpdate={(row) => handleUpdate(row)}
            onDelete={(row) => destroy(row._id, '/statuses')}
          />
        </Col>
      </Row>
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        centered
      >
        <ModalHeader>
          <strong>Status</strong>
          <Button
            className="close position-absolute top-4 right-4 py-1 px-2"
            onClick={() => setShowModal(false)}
          >
            <i className="fas fa-times" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Input
                type="text"
                placeholder="Nome"
                value={nome}
                invalid={!!validationErrors.nome}
                onChange={({ target: { value } }) => setNome(value)}
              />
              <FormFeedback>{validationErrors.nome}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label>Cor do Status</Label>
              <ColorPicker
                color={color}
                onChange={(c) => setColor(c.hex)}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleSave}>Salvar</Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}

export default Status;
