/* eslint-disable max-len */
import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { NumericFormat } from 'react-number-format';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap';

import * as Yup from 'yup';

import { AppContext } from '../../contexts/app';
import { useManager } from '../../hooks/manager';
import api from '../../services/api';
import TipoDeAtendimento from '../Especialidade';
import { MoneyFormatter } from '../Formatters';
import Notification from '../Notification';
import Select from '../Select';

function TabelaValorCliente({ onSave = () => null }, ref) {
  const notificationRef = useRef();
  const especialidadeRef = useRef();
  const { setLoading } = useContext(AppContext);
  const [validationErrors, setValidationErrors] = useState({});
  const { update, store } = useManager();
  const [showModal, setShowModal] = useState(false);
  const [addTableDatas, setAddTableDatas] = useState(true);
  const [id, setId] = useState({});
  const [nome, setNome] = useState('');
  const [newEspecialidade, setNewEspecialidade] = useState('');
  const [especialidade, setEspecialidade] = useState('');
  const [especialidades, setEspecialidades] = useState([]);
  const [, setEspecialidadesList] = useState([]);
  const [valores, setValores] = useState([]);
  const [valor, setValor] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const { data = [] } = await api.get('/especialidades');
        setEspecialidadesList(data);
        setEspecialidades(
          data.map((e) => ({
            value: e._id,
            label: e.nome,
          })),
        );
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (newEspecialidade) {
      setEspecialidadesList((prevEspecialidadesList) => [
        newEspecialidade,
        ...prevEspecialidadesList,
      ]);
      setEspecialidades((prevEspecialidades) => [
        { value: newEspecialidade._id, label: newEspecialidade.nome },
        ...prevEspecialidades,
      ]);
      setEspecialidade({
        value: newEspecialidade._id,
        label: newEspecialidade.nome,
      });
      setNewEspecialidade('');
    }
  }, [newEspecialidade]);

  useImperativeHandle(ref, () => ({
    show: (data, add = true) => {
      setValidationErrors({});
      setAddTableDatas(add);

      if (data) {
        setValidationErrors({});
        setId(data._id);
        setNome(data.nome);
        setEspecialidade('');
        setValor('');
        setValores(
          data?.valores?.map((v) => ({
            especialidade: {
              value: v.especialidade._id,
              label: v.especialidade.nome,
            },
            valor: v.valor,
          })) || [],
        );
        setEspecialidadesList((prevEspecialidadesList = []) => {
          setEspecialidades(
            data?.valores?.length
              ? prevEspecialidadesList
                .filter(
                  (e) => !data.valores.find((v) => e._id === v.especialidade._id),
                )
                .map((e) => ({
                  value: e._id,
                  label: e.nome,
                }))
              : prevEspecialidadesList.map((e) => ({
                value: e._id,
                label: e.nome,
              })),
          );
          return prevEspecialidadesList;
        });
        setShowModal(true);
        return;
      }

      setValidationErrors({});
      setId(null);
      setNome('');
      setEspecialidade('');
      setEspecialidadesList((prevEspecialidadesList = []) => {
        setEspecialidades(
          prevEspecialidadesList.map((e) => ({
            value: e._id,
            label: e.nome,
          })),
        );
        return prevEspecialidadesList;
      });
      setValor('');
      setValores([]);
      setShowModal(true);
    },
    close: () => setShowModal(false),
  }));

  const handleAddValores = useCallback(() => {
    (async () => {
      try {
        const data = {
          especialidade: especialidade?.value ? especialidade.value : '',
          valor,
        };

        const schema = Yup.object().shape({
          especialidade: Yup.string().required('Selecione um tipo de atendimento'),
          valor: Yup.string().required('Informe um valor'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setEspecialidade('');
        setValor('');
        setValidationErrors({});
        setEspecialidades((prevEspecialidades = []) => prevEspecialidades.filter((e) => e.value !== especialidade.value));
        setValores((prevValores) => [...prevValores, { especialidade, valor }]);
      } catch (err) {
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message: 'Por favor, preencha os dados necessários',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
        }
      }
    })();
  }, [especialidade, valor]);

  const handleRemoveValores = useCallback((especialidadeData) => {
    setEspecialidades((prevEspecialidades = []) => [...prevEspecialidades, especialidadeData]);
    setValores((prevValores = []) => prevValores.filter((v) => v.especialidade.value !== especialidadeData.value));
  }, [especialidade]);

  const handleSave = useCallback(() => {
    (async () => {
      try {
        const data = {
          nome,
          valores: valores.map((v) => ({
            especialidade: v.especialidade.value,
            valor: `${v.valor}`,
          })),
        };

        const schema = Yup.object().shape({
          nome: Yup.string().required(
            'Informe o nome da tabela de valores clientes',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (!valores.length) {
          notificationRef.current.notify({
            message: 'Informe o(s) tipo de atendimento(s) e seu valor',
            color: 'warning',
          });
          return;
        }

        if (id) {
          update(id, '/tabelaValoresClientes', data, (updatedData) => {
            setLoading(false);
            setShowModal(false);
            onSave(updatedData);
          }, () => setLoading(false), addTableDatas);
          return;
        }

        store('/tabelaValoresClientes', data, (createdData) => {
          setLoading(false);
          setShowModal(false);
          onSave(createdData);
        }, () => setLoading(false), addTableDatas);
      } catch (err) {
        setLoading(false);
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message:
              'Por favor, preencha todos os dados necessários para salvar',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notificationRef.current.notify({
            message,
            color: 'danger',
          });

          return;
        }

        notificationRef.current.notify({
          message: 'Algo inesperado aconteceu e não foi possível salvar',
          color: 'danger',
        });
      }
    })();
  }, [id, nome, valores, addTableDatas]);

  return (
    <>
      <TipoDeAtendimento ref={especialidadeRef} onSave={setNewEspecialidade} />
      <Notification ref={notificationRef} />
      <Modal
        isOpen={showModal}
        fullscreen
      >
        <ModalHeader>
          <strong>Tabela de Valores para Clientes</strong>
          <Button
            className="close position-absolute top-4 right-4 py-1 px-2"
            onClick={() => setShowModal(false)}
          >
            <i className="fas fa-times" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Input
                type="text"
                placeholder="Nome da Tabela"
                value={nome}
                invalid={!!validationErrors.nome}
                onChange={({ target: { value } }) => setNome(value)}
              />
              <FormFeedback>{validationErrors.nome}</FormFeedback>
            </FormGroup>
            <Row>
              <Col xs={5}>
                <Select
                  placeholder="Selecione um tipo de atendimento"
                  value={especialidade}
                  options={especialidades}
                  invalidMessage={validationErrors.especialidade}
                  onChange={setEspecialidade}
                />
              </Col>
              <Col xs={1} className="pl-0">
                <UncontrolledTooltip target="new-especialidade">
                  Cadastrar um tipo de atendimento
                </UncontrolledTooltip>
                <Button
                  id="new-especialidade"
                  color="success"
                  onClick={() => especialidadeRef.current.show(null, false)}
                >
                  <i className="fa-solid fa-circle-plus text-white" />
                </Button>
              </Col>
              <Col xs={4}>
                <FormGroup>
                  <NumericFormat
                    customInput={Input}
                    placeholder="Digite um valor"
                    decimalSeparator=","
                    thousandSeparator="."
                    decimalScale={2}
                    prefix="R$"
                    value={valor}
                    invalid={!!validationErrors.valor}
                    fixedDecimalScale
                    onValueChange={({ floatValue }) => setValor(floatValue)}
                  />
                  <FormFeedback>{validationErrors.valor}</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs={2}>
                <Button color="success" onClick={handleAddValores} block>
                  <i className="fa-solid fa-circle-plus text-white" />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                {valores.length ? (
                  valores.map((v, index) => (
                    <Row className="mb-2" key={`valores-${index}`}>
                      <Col xs={6}>{v.especialidade.label}</Col>
                      <Col xs={4}>
                        <MoneyFormatter value={parseFloat(v.valor)} />
                      </Col>
                      <Col xs={2}>
                        <Button
                          color="danger"
                          onClick={() => handleRemoveValores(v.especialidade)}
                          block
                        >
                          <i className="fa-solid fa-trash-can text-white" />
                        </Button>
                      </Col>
                    </Row>
                  ))
                ) : (
                  <p className="text-center">Cadastre os valores</p>
                )}
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleSave}>Salvar</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default forwardRef(TabelaValorCliente);
