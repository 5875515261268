/* eslint-disable max-len */
import React, { useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
} from 'reactstrap';

import { yupResolver } from '@hookform/resolvers/yup';

import { useManager } from '../../../hooks/manager';
import DatePickerMaskedInput from '../../DatePickerMaskedInput';
import MoneyMaskedInput from '../../MoneyMaskedInput';
import { schema } from './schemaValidations';

function CustomModalFecharFatura(_props, ref) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const { update, loading } = useManager();
  const { control, setError, clearErrors, setValue, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

  const onClose = useCallback(() => {
    setShow(false);
    clearErrors();
  }, []);

  useImperativeHandle(ref, () => ({
    show: (dataValues) => {
      setData(dataValues);
      setValue('dataFechamento', new Date());
      setValue('valor', dataValues.valor);
      setShow(true);
    },
    close: onClose,
  }));

  const onSuccess = useCallback((savedData) => {
    console.log('🚀 ~ file: CustomModalFecharFatura index.js ~ onSuccess ~ savedData:', savedData);
    const { onSave } = data;
    if (typeof onSave === 'function') {
      onSave(savedData);
    }

    setShow(false);
  }, [data]);

  const onSubmit = useCallback((formValues) => {
    const { dataVencimento, dataFechamento, valor } = formValues;

    const { id, agendamento, searchArray, page, limit } = data;

    if (!agendamento || (agendamento && agendamento.status !== 'cancelado')) {
      if (!dataVencimento) {
        setError('dataVencimento', { type: 'manual', message: 'Informe uma data da Vencimento' });
        return;
      }
    }

    update(id, '/financeiros', { status: 'fechado', dataVencimento, dataFechamento, valor }, onSuccess, null, true, searchArray, page, limit, true);
  }, [data]);

  return (
    <Modal
      isOpen={show}
      onClose={() => setShow(false)}
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          <strong>Fechar Fatura</strong>
          <Button
            className="close position-absolute top-4 right-4 py-1 px-2"
            onClick={() => setShow(false)}
          >
            <i className="fas fa-times" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <Controller
            name="dataVencimento"
            control={control}
            render={({ field }) => (
              <DatePickerMaskedInput
                label="Data do Vencimento"
                invalidMessage={errors?.dataVencimento?.message}
                onChange={(e) => field.onChange(e)}
                selected={field.value}
              />
            )}
          />
          <Controller
            name="dataFechamento"
            control={control}
            render={({ field }) => (
              <DatePickerMaskedInput
                label="Data de Fechamento"
                invalidMessage={errors?.dataFechamento?.message}
                onChange={(e) => field.onChange(e)}
                selected={field.value}
              />
            )}
          />
          <Controller
            name="valor"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <MoneyMaskedInput
                label="Valor"
                name={name}
                invalidMessage={errors?.valor?.message}
                value={value}
                onValueChange={({ floatValue }) => onChange(floatValue)}
              />
            )}
          />
        </ModalBody>
        <ModalFooter tag="div" className="d-flex justify-content-end">
          <Button type="submit" color="success" disabled={loading}>Salvar</Button>
          <Button color="danger" className="ml-3" onClick={onClose} disabled={loading}>Cancelar</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default forwardRef(CustomModalFecharFatura);
