/* eslint-disable no-nested-ternary */
import React, { useState, useCallback } from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';

import camera from '../../assets/img/camera.svg';
import './styles.css';

function ImageFileInput({
  label = 'Selecione uma imagem',
  previewUrl,
  onChange,
  invalid,
  style,
  width = '100%',
  height = '200px',
  shape = 'default',
  ...props
}) {
  const [preview, setPreview] = useState();

  const handlePreview = useCallback((e) => {
    const file = e.target.files[0];

    if (!file) {
      setPreview(null);
      return;
    }

    setPreview(encodeURI(URL.createObjectURL(file)));

    if (onChange) {
      onChange(file);
    }
  }, []);

  return (
    <FormGroup>
      <Label
        className="image-file-input-container"
        style={
          shape === 'avatar'
            ? {
              border: invalid
                ? '1px solid var(--danger)'
                : preview || previewUrl
                  ? '1px solid var(--dark)'
                  : '1px dashed var(--dark)',
              backgroundImage: `url(${preview || previewUrl})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              width,
              height,
              borderRadius: '50%',
              ...style,
            }
            : {
              border: invalid
                ? '1px solid var(--danger)'
                : preview || previewUrl
                  ? '1px solid var(--dark)'
                  : '1px dashed var(--dark)',
              backgroundImage: `url(${preview || previewUrl})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              width,
              height,
              ...style,
            }
        }
      >
        {label && !invalid && (
          <small
            style={{
              display: preview || previewUrl ? 'none' : '',
            }}
          >
            {label}
          </small>
        )}
        <img
          src={camera}
          alt="Selecione uma Imagem"
          style={{
            display: preview || previewUrl ? 'none' : '',
          }}
        />
        <Input
          type="file"
          invalid={!!invalid}
          onChange={handlePreview}
          hidden
          {...props}
        />
        <FormFeedback className="text-center">{invalid}</FormFeedback>
      </Label>
    </FormGroup>
  );
}

export default ImageFileInput;
