import React, { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';

function MoneyMaskedInput({ label, value, invalidMessage, ...rest }, ref) {
  return (
    <FormGroup>
      {label && <Label>{label}</Label>}
      <NumericFormat
        ref={ref}
        customInput={Input}
        placeholder="Digite um valor"
        decimalSeparator=","
        thousandSeparator="."
        decimalScale={2}
        prefix="R$"
        value={value}
        invalid={!!invalidMessage}
        fixedDecimalScale
        valueIsNumericString
        {...rest}
      />
      <FormFeedback>{invalidMessage}</FormFeedback>
    </FormGroup>
  );
}

export default forwardRef(MoneyMaskedInput);
