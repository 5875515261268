import React from 'react';
import { NumericFormat } from 'react-number-format';

export default function MoneyFormat({ children, value }) {
  return (
    <NumericFormat
      value={children || value}
      defaultValue="0"
      displayType="text"
      decimalSeparator=","
      thousandSeparator="."
      decimalScale={2}
      prefix="R$"
      renderText={(formattedValue) => formattedValue}
      fixedDecimalScale
    />
  );
}
