/* eslint-disable max-len */
import React, { useState, forwardRef, useImperativeHandle, useCallback, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Row,
  Col,
} from 'reactstrap';

import { yupResolver } from '@hookform/resolvers/yup';

import { AppContext } from '../../../contexts/app';
import { useManager } from '../../../hooks/manager';
import api from '../../../services/api';
import DatePickerMaskedInput from '../../DatePickerMaskedInput';
import { schema } from './schemaValidations';

function CustomModalEmitirFatura(_props, ref) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const { loading, setLoading, notificationRef } = useContext(AppContext);
  const { setDatas } = useManager();
  const { control, setValue, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

  useImperativeHandle(ref, () => ({
    show: (dataValues) => {
      setData(dataValues);
      if (dataValues.dataVencimento) {
        setValue('dataVencimento', new Date(dataValues.dataVencimento));
      }
      setShow(true);
    },
    close: () => setShow(false),
  }));

  const onSuccess = useCallback((savedData) => {
    console.log('🚀 ~ file: CustomModalEmitirFatura index.js ~ onSuccess ~ savedData:', savedData);
    const { onSave } = data;
    if (typeof onSave === 'function') {
      onSave(savedData);
    }

    setShow(false);
  }, [data]);

  const onSubmit = useCallback((formValues) => {
    const { dataVencimento } = formValues;
    const { selectedRows } = data;
    (async () => {
      try {
        setLoading(true);
        const { data: { docs = [], message } } = await api.post('/folhaPagamentos', {
          dataVencimento,
          ids: selectedRows.map((selectedRow) => selectedRow._id),
        });
        setDatas((datas = []) => datas.map((dt) => (docs.find((dc) => dt._id === dc._id) ? docs.find((dc) => dt._id === dc._id) : dt)));
        setLoading(false);
        onSuccess(docs);
        notificationRef.current.notify({
          message,
        });
      } catch (err) {
        setLoading(false);
        console.log('🚀 ~ file: CustoModalEmitirFatura index.js:55 ~ err:', err);

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });

            return;
          }
        }

        notificationRef.current.notify({
          message: 'Falha para gerar folha de pagamento',
          color: 'danger',
        });
      }
    })();
  }, [data]);

  const { selectedRows = [], subTitle } = data;

  return (
    <Modal
      isOpen={show}
      onClose={() => setShow(false)}
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          <strong>Gerar Folha de Pagamento</strong>
          <Button
            className="close position-absolute top-4 right-4 py-1 px-2"
            onClick={() => setShow(false)}
          >
            <i className="fas fa-times" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <strong>{subTitle || `Deseja realmente gerar folha de pagamento da(s) ${selectedRows.length} fatura(s) selecionada(s)?`}</strong>
            </Col>
          </Row>
          <Controller
            name="dataVencimento"
            control={control}
            render={({ field }) => (
              <DatePickerMaskedInput
                label="Informe a data do vencimento"
                invalidMessage={errors?.dataVencimento?.message}
                onChange={(e) => field.onChange(e)}
                selected={field.value}
              />
            )}
          />
        </ModalBody>
        <ModalFooter tag="div" className="d-flex justify-content-end">
          <Button type="submit" color="success" disabled={loading?.loading || loading}>Gerar</Button>
          <Button color="danger" className="ml-3" onClick={() => setShow(false)} disabled={loading?.loading || loading}>Cancelar</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default forwardRef(CustomModalEmitirFatura);
