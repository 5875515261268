import React from 'react';

import classNames from 'classnames';

function Backdrop({ isOpen, toggle }) {
  return (
    <div
      role="presentation"
      className={classNames('sidebar-backdrop', { isOpen })}
      onClick={toggle}
      onKeyDown={toggle}
      onKeyUp={toggle}
    />
  );
}

export default Backdrop;
