/* eslint-disable max-len */
/* eslint-disable default-param-last */
import React, {
  createContext,
  useState,
  useCallback,
  useMemo,
  useContext,
  useRef,
} from 'react';

import AlertModal from '../components/AlertModal';
import Notification from '../components/Notification';
import { AppContext } from '../contexts/app';
import api from '../services/api';

const ManagerContext = createContext();

function ManagerProvider({ children }) {
  const notificationRef = useRef();
  const confirmRef = useRef();
  const [currentUrl, setCurrentUrl] = useState('');
  const [datas, setDatas] = useState([]);
  const [extraData, setExtraData] = useState({});
  const { loading, setLoading } = useContext(AppContext);
  const [filtered, setFiltered] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);

  const handlerError = useCallback((error, title) => {
    if (typeof error === 'string') {
      notificationRef.current.notify({
        title,
        message: error,
        color: 'danger',
      });
      return;
    }

    if (error && error.response) {
      const { message } = error.response.data;
      if (message) {
        notificationRef.current.notify({
          title,
          message,
          color: 'danger',
        });
        return;
      }
    }

    notificationRef.current.notify({
      message: title,
      color: 'danger',
    });
  }, []);

  const load = useCallback(
    (url, page = 1, searchArray = [], limit = 10, onSuccess, onError) => {
      setDatas([]);
      if (url) {
        setCurrentUrl(url);
        (async () => {
          try {
            setLoading(true);
            // console.log('limit', limit);
            let urlString = `${url}?page=${page}&limit=${limit}`;
            setFiltered(false);
            if (searchArray.length && !!searchArray?.filter((s) => s.field && (s.value || s.startRange || s.endRange))?.length) {
              setFiltered(true);
              urlString = `${urlString}&search=${JSON.stringify(searchArray)}`;
            }
            const res = await api.get(urlString);
            // console.log(res.data);
            setExtraData(res?.data?.extraData || []);
            setDatas(res.data.docs);
            setHasPrevPage(res.data.hasPrevPage);
            setHasNextPage(res.data.hasNextPage);
            setTotalPages(res.data.totalPages);
            setTotalDocs(res.data.totalDocs);
            if (onSuccess) {
              onSuccess();
            }
            setLoading(false);
          } catch (error) {
            if (onError) {
              onError(error);
            }
            setLoading(false);
            handlerError(error, 'Falha ao carregar os dados');
          }
        })();
      }
    },
    [handlerError],
  );

  const store = useCallback(
    (url, data, onSuccess, onError, addDatas = true) => {
      if (url && data) {
        (async () => {
          try {
            setLoading(true);
            const res = await api.post(url, data);
            if (addDatas) {
              setDatas((d) => [res.data, ...d]);
            }
            setLoading(false);
            notificationRef.current.notify({
              message: res.data.message || 'Cadastrado com sucesso!!!',
            });
            if (onSuccess) {
              onSuccess(res.data);
            }
          } catch (error) {
            if (onError) {
              onError(error);
            }
            setLoading(false);
            handlerError(error, 'Falha ao Cadastrar');
          }
        })();
      }
    },
    [handlerError],
  );

  const update = useCallback(
    (id, url, data, onSuccess, onError, addDatas = true, searchArray = [], page = 1, limit = 10, reloadDatas = false) => {
      if (id && url && data) {
        (async () => {
          try {
            setLoading(true);
            let urlString = `${url}/${id}`;
            if (searchArray.length && !!searchArray?.filter((s) => s.field && (s.value || s.startRange || s.endRange))?.length) {
              urlString = `${urlString}?search=${JSON.stringify(searchArray)}`;

              if (reloadDatas) {
                urlString = `${urlString}&page=${page}&limit=${limit}&reloadDatas=true`;
              }
            }

            // console.log(urlString);
            const res = await api.put(urlString, data);
            if (!res.data) {
              setLoading(false);
              if (onError) {
                onError('Erro ao alterar');
              }
              notificationRef.current.notify({ type: 'error', message: 'Erro ao alterar' });
              return;
            }
            console.log('🚀 ~ file: manager.js:146 ~ res:', res.data);
            if (res.data.extraData) {
              setExtraData(res.data.extraData || {});
            }

            if (addDatas) {
              if (searchArray.length && !!searchArray?.filter((s) => s.field && (s.value || s.startRange || s.endRange))?.length && reloadDatas) {
                setDatas(res.data.docs);
                setHasPrevPage(res.data.hasPrevPage);
                setHasNextPage(res.data.hasNextPage);
                setTotalPages(res.data.totalPages);
                setTotalDocs(res.data.totalDocs);
              } else {
                setDatas((prevDatas = []) => prevDatas.map((d) => ((d.id || d._id) === id ? res.data.doc || res.data : d)));
              }
            }

            setLoading(false);
            notificationRef.current.notify({
              message: res.data.message || 'Alterado com sucesso!!!',
            });
            if (typeof onSuccess === 'function') {
              onSuccess(res.data);
            }
          } catch (error) {
            console.log('🚀 ~ file: manager.js:171 ~ error:', error);
            if (onError) {
              onError(error);
            }
            setLoading(false);
            handlerError(error, 'Falha ao alterar');
          }
        })();
      }
    },
    [handlerError],
  );

  const destroy = useCallback(
    (id, url, onSuccess, onError) => {
      if (id && url) {
        (async () => {
          try {
            // console.log('destroy', id, url);
            confirmRef.current.show({
              message: 'Tem certeza que deseja inativar?',
              confirmColor: 'danger',
              confirmText: 'Inativar',
              cancelText: 'Cancelar',
              cancelColor: 'link',
              onConfirm: async () => {
                try {
                  setLoading(true);
                  await api.delete(`${url}/${id}`);
                  setDatas((d) => d.filter((value) => (value.id || value._id) !== id));
                  setLoading(false);
                  notificationRef.current.notify({
                    message: 'Excluido com sucesso',
                  });
                  if (onSuccess) {
                    onSuccess();
                  }
                  confirmRef.current.close();
                } catch (error) {
                  if (onError) {
                    onError(error);
                  }
                  setLoading(false);
                  handlerError(error, 'Falha ao Inativar');
                }
              },
            });
          } catch (error) {
            if (onError) {
              onError(error);
            }
            setLoading(false);
            handlerError(error, 'Falha ao Inativar');
          }
        })();
      }
    },
    [handlerError],
  );

  const value = useMemo(
    () => ({
      currentUrl,
      loading,
      load,
      datas,
      extraData,
      filtered,
      setDatas,
      hasNextPage,
      hasPrevPage,
      totalPages,
      totalDocs,
      store,
      update,
      destroy,
    }),
    [
      currentUrl,
      loading,
      load,
      datas,
      extraData,
      filtered,
      setDatas,
      hasNextPage,
      hasPrevPage,
      totalPages,
      totalDocs,
      store,
      update,
      destroy,
    ],
  );

  return (
    <ManagerContext.Provider value={value}>
      <AlertModal ref={confirmRef} />
      <Notification ref={notificationRef} />
      {children}
    </ManagerContext.Provider>
  );
}

export const useManager = () => {
  const context = useContext(ManagerContext);

  if (!context) {
    throw new Error('useManager must be used within a ManagerProvider');
  }

  return context;
};

export default ManagerProvider;
