/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useRef } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import InputMask from 'react-input-mask';
import {
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import { format, getDate, getMonth, getYear } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import './styles.css';

registerLocale('ptBR', ptBR);
setDefaultLocale('ptBR');

function DatePickerMaskedInput({
  selected,
  title = 'Data',
  label,
  placeholderText = 'hh:mm',
  dateFormat = 'HH:mm',
  locale = 'ptBR',
  invalidMessage = '',
  onChange,
  ...props
}) {
  const datepickerRef = useRef();
  const [errorMessage, setErrorMessage] = useState('');
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setErrorMessage(invalidMessage);
  }, [invalidMessage]);

  useEffect(() => {
    if (selected) {
      setInputValue(format(selected, dateFormat));
      onChange(selected);
    }
  }, [selected]);

  return (
    <DatePicker
      ref={datepickerRef}
      title={title}
      placeholderText={placeholderText}
      dateFormat={dateFormat}
      locale={locale}
      selected={selected}
      value={inputValue}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={5}
      timeCaption="Hora"
      onChange={(date) => {
        setInputValue(format(date, dateFormat));
        onChange(date);
      }}
      onChangeRaw={({ target: input }) => {
        setInputValue('');
        const { value } = input;
        // console.log(value);
        if (!value) return;
        setInputValue(value);
        onChange('');
        let date;
        let hour = value.substring(0, 2).replace(/[^\d]+/g, '');
        let minute = value.substring(3, 5).replace(/[^\d]+/g, '');

        if (hour.length === 2) {
          if (Number(hour) > 23) {
            hour = '23';
          }

          date = new Date(
            getYear(new Date()),
            getMonth(new Date()),
            getDate(new Date()),
            parseInt(hour, 10),
          );
          onChange(date);
          datepickerRef.current.setPreSelection(date);
        }

        if (minute.length === 2) {
          if (Number(minute) > 59) {
            minute = '59';
          }

          date = new Date(
            getYear(new Date()),
            getMonth(new Date()),
            getDate(new Date()),
            parseInt(hour, 10),
            parseInt(minute, 10),
          );
          onChange(date);
          datepickerRef.current.setPreSelection(date);
        }

        setInputValue(`${hour}:${minute}`);

        if (hour.length === 2 && minute.length === 2) {
          datepickerRef.current.setOpen(false);
          onChange(date);
        }
      }}
      customInput={(
        <InputMask mask="99:99" placeholder="hh:mm">
          {(inputProps) => (
            <FormGroup>
              {label ? (
                <Label for={`time-picker-${props.id}`}>{label}</Label>
              ) : null}
              <Input
                id={`time-picker-${props.id}`}
                className="w-100"
                invalid={!!errorMessage}
                {...inputProps}
              />
              <FormFeedback className="text-danger">
                {errorMessage}
              </FormFeedback>
            </FormGroup>
          )}
        </InputMask>
        )}
      {...props}
    />
  );
}

export default DatePickerMaskedInput;
