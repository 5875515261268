/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { NumericFormat } from 'react-number-format';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap';

import classNames from 'classnames';
import * as Yup from 'yup';

import { AppContext } from '../../contexts/app';
import { useManager } from '../../hooks/manager';
import api from '../../services/api';
import AlertModal from '../AlertModal';
import CustomButton from '../CustomButton';
import TipoDeAtendimento from '../Especialidade';
import { MoneyFormatter } from '../Formatters';
import Notification from '../Notification';
import Select from '../Select';

function TabelaValorEspecialista({ onSave = () => null }, ref) {
  const notificationRef = useRef();
  const especialidadeRef = useRef();
  const alertRef = useRef();
  const { loading, setLoading } = useContext(AppContext);
  const [validationErrors, setValidationErrors] = useState({});
  const { update, store } = useManager();
  const [showModal, setShowModal] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [updatedItemNotSaved, setUpdatedItemNotSaved] = useState(false);
  const [addTableDatas, setAddTableDatas] = useState(true);
  const [id, setId] = useState({});
  const [nome, setNome] = useState('');
  const [newEspecialidade, setNewEspecialidade] = useState('');
  const [especialidade, setEspecialidade] = useState('');
  const [especialidades, setEspecialidades] = useState([]);
  const [, setEspecialidadesList] = useState([]);
  const [valores, setValores] = useState([]);
  const [valor, setValor] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const { data = [] } = await api.get('/especialidades');
        setEspecialidadesList(data);
        setEspecialidades(
          data.map((e) => ({
            value: e._id,
            label: e.nome,
          })),
        );
      } catch (error) {
        console.log(error);
      }
    })();

    return () => null;
  }, []);

  useEffect(() => {
    if (newEspecialidade) {
      setEspecialidadesList((prevEspecialidadesList) => [
        newEspecialidade,
        ...prevEspecialidadesList,
      ]);
      setEspecialidades((prevEspecialidades) => [
        { value: newEspecialidade._id, label: newEspecialidade.nome },
        ...prevEspecialidades,
      ]);
      setEspecialidade({
        value: newEspecialidade._id,
        label: newEspecialidade.nome,
      });
      setNewEspecialidade('');
    }

    return () => null;
  }, [newEspecialidade]);

  useImperativeHandle(ref, () => ({
    show: (data, add = true) => {
      setValidationErrors({});
      setUpdating(false);
      setUpdatedItemNotSaved(false);
      setAddTableDatas(add);

      if (data) {
        setValidationErrors({});
        setId(data._id);
        setNome(data.nome);
        setEspecialidade('');
        setValor('');
        setValores(
          data?.valores?.map((v) => ({
            especialidade: {
              value: v.especialidade._id,
              label: v.especialidade.nome,
            },
            valor: v.valor,
          })) || [],
        );
        setEspecialidadesList((prevEspecialidadesList = []) => {
          setEspecialidades(
            data?.valores?.length
              ? prevEspecialidadesList
                .filter(
                  (e) => !data.valores.find((v) => e._id === v.especialidade._id),
                )
                .map((e) => ({
                  value: e._id,
                  label: e.nome,
                }))
              : prevEspecialidadesList.map((e) => ({
                value: e._id,
                label: e.nome,
              })),
          );
          return prevEspecialidadesList;
        });
        setShowModal(true);
        return;
      }

      setValidationErrors({});
      setId(null);
      setNome('');
      setEspecialidade('');
      setEspecialidadesList((prevEspecialidadesList = []) => {
        setEspecialidades(
          prevEspecialidadesList.map((e) => ({
            value: e._id,
            label: e.nome,
          })),
        );
        return prevEspecialidadesList;
      });
      setValor('');
      setValores([]);
      setShowModal(true);
    },
    close: () => setShowModal(false),
  }));

  const handleClose = useCallback(() => {
    if (updatedItemNotSaved) {
      alertRef.current.show({
        title: 'Fechar sem Salvar',
        message: 'Você alterou informações que não foram salvas. Deseja realmente fechar sem salvar?',
        onConfirm: () => {
          alertRef.current.close();
          setShowModal(false);
        },
      });
      return;
    }

    setShowModal(false);
  }, [updatedItemNotSaved]);

  const handleAddValores = useCallback(() => {
    (async () => {
      try {
        const data = {
          especialidade: especialidade?.value ? especialidade.value : '',
          valor,
        };

        const schema = Yup.object().shape({
          especialidade: Yup.string().required('Selecione um tipo de atendimento'),
          valor: Yup.string().required('Informe um valor'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setEspecialidade('');
        setValor('');
        setValidationErrors({});
        setEspecialidades((prevEspecialidades = []) => prevEspecialidades.filter((e) => e.value !== especialidade.value));
        setValores((prevValores) => [...prevValores, { especialidade, valor }]);
        setUpdating(false);
      } catch (err) {
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message: 'Por favor, preencha os dados necessários',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
        }
      }
    })();
  }, [especialidade, valor]);

  const handleUpdateValores = useCallback((especialidadeData, valorData) => {
    setValidationErrors({});
    setEspecialidades((prevEspecialidades = []) => [...prevEspecialidades, especialidadeData].sort((a, b) => (a.label > b.label ? 1 : -1)));
    setValores((prevValores = []) => prevValores.filter((v) => v.especialidade.value !== especialidadeData.value));
    setEspecialidade(especialidadeData);
    setValor(valorData);
    setUpdating(true);
    setUpdatedItemNotSaved(true);
  }, []);

  const handleRemoveValores = useCallback((especialidadeData) => {
    (async () => {
      try {
        setLoading(true);
        await api.get(`/tabelaValorEspecialidades/${id}/${especialidadeData.value}`);
        setEspecialidades((prevEspecialidades = []) => [...prevEspecialidades, especialidadeData]);
        setValores((prevValores = []) => prevValores.filter((v) => v.especialidade.value !== especialidadeData.value));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notificationRef.current.notify({
            message,
            color: 'danger',
          });

          return;
        }

        notificationRef.current.notify({
          message: 'Algo inesperado aconteceu e não foi possível realizar essa ação',
          color: 'danger',
        });
      }
    })();
  }, [id]);

  const handleSave = useCallback(() => {
    (async () => {
      try {
        const data = {
          nome,
          valores: valores.map((v) => ({
            especialidade: v.especialidade.value,
            valor: `${v.valor}`,
          })),
        };

        const schema = Yup.object().shape({
          nome: Yup.string().required(
            'Informe o nome da tabela de valores clientes',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (!valores.length) {
          notificationRef.current.notify({
            message: 'Informe o(s) tipo(s) de atendimento(s) e seu valor',
            color: 'warning',
          });
          return;
        }

        if (id) {
          update(id, '/tabelaValoresEspecialistas', data, (updatedData) => {
            setLoading(false);
            setShowModal(false);
            setUpdatedItemNotSaved(false);
            onSave(updatedData);
          }, () => setLoading(false), addTableDatas);
          return;
        }

        store('/tabelaValoresEspecialistas', data, (createdData) => {
          setLoading(false);
          setShowModal(false);
          setUpdatedItemNotSaved(false);
          onSave(createdData);
        }, () => setLoading(false), addTableDatas);
      } catch (err) {
        setLoading(false);
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message:
              'Por favor, preencha todos os dados necessários para salvar',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notificationRef.current.notify({
            message,
            color: 'danger',
          });

          return;
        }

        notificationRef.current.notify({
          message: 'Algo inesperado aconteceu e não foi possível salvar',
          color: 'danger',
        });
      }
    })();
  }, [id, nome, valores, addTableDatas]);

  return (
    <>
      <TipoDeAtendimento ref={especialidadeRef} onSave={setNewEspecialidade} />
      <AlertModal ref={alertRef} />
      <Notification ref={notificationRef} />
      <Modal
        isOpen={showModal}
        fullscreen
      >
        <ModalHeader>
          <strong>Tabela de Valores para Especialistas</strong>
          <Button
            className="close position-absolute top-4 right-4 py-1 px-2"
            onClick={handleClose}
          >
            <i className="fas fa-times" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Input
                type="text"
                placeholder="Nome da Tabela"
                value={nome}
                invalid={!!validationErrors.nome}
                onChange={({ target: { value } }) => setNome(value)}
              />
              <FormFeedback>{validationErrors.nome}</FormFeedback>
            </FormGroup>
            <Row>
              <Col xs={12} lg={5}>
                <Row>
                  <Col xs={9}>
                    <Select
                      placeholder="Selecione um tipo de atendimento"
                      value={especialidade}
                      options={especialidades}
                      invalidMessage={validationErrors.especialidade}
                      onChange={setEspecialidade}
                      disabled={updating}
                    />
                  </Col>
                  <Col xs={3}>
                    <UncontrolledTooltip target="new-especialidade">
                      {updating ? 'Termine a alteração para cadastrar um tipo de atendimento' : 'Cadastrar um tipo de atendimento'}
                    </UncontrolledTooltip>
                    <Button
                      id="new-especialidade"
                      color="success"
                      disabled={updating}
                      onClick={() => especialidadeRef.current.show(null, false)}
                      block
                    >
                      <i className="fa-solid fa-circle-plus text-white" />
                      <i className="fa-solid fa-layer-group ml-2 text-white" />
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={4}>
                <FormGroup>
                  <NumericFormat
                    customInput={Input}
                    placeholder="Digite o valor"
                    decimalSeparator=","
                    thousandSeparator="."
                    decimalScale={2}
                    prefix="R$"
                    value={valor}
                    invalid={!!validationErrors.valor}
                    fixedDecimalScale
                    onValueChange={({ floatValue }) => setValor(floatValue)}
                  />
                  <FormFeedback>{validationErrors.valor}</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs={12} lg={3}>
                <Button color={updating ? 'info' : 'success'} onClick={handleAddValores} block>
                  <i className={classNames('fa-solid text-white', { 'fa-circle-plus': !updating, 'fa-edit': update })} />
                  {updating ? (
                    <span className="text-white ml-2">Alterar</span>
                  ) : (
                    <span className="text-white ml-2">Adicionar</span>
                  )}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                {valores.length ? (
                  valores
                    .sort((a, b) => (a.especialidade.label > b.especialidade.label ? 1 : -1))
                    .map((v, index) => (
                      <Row className="mb-2" key={`valores-${index}`}>
                        <Col xs={6}>{v.especialidade.label}</Col>
                        <Col xs={4}>
                          <MoneyFormatter value={parseFloat(v.valor)} />
                        </Col>
                        <Col xs={2} tag="div" className="d-flex justify-content-end">
                          <UncontrolledTooltip target={`edit-button-${index}`}>
                            {updating ? 'Termine a alteração para alterar outro tipo de atendimento' : 'Alterar'}
                          </UncontrolledTooltip>
                          <Button
                            id={`edit-button-${index}`}
                            color="info"
                            className="mr-2"
                            disabled={updating}
                            onClick={() => handleUpdateValores(v.especialidade, v.valor)}
                          >
                            <i className="fa-solid fa-edit text-white" />
                          </Button>
                          <UncontrolledTooltip target={`delete-button-${index}`}>
                            Excluir
                          </UncontrolledTooltip>
                          <Button
                            id={`delete-button-${index}`}
                            color="danger"
                            onClick={() => handleRemoveValores(v.especialidade)}
                          >
                            <i className="fa-solid fa-trash-can text-white" />
                          </Button>
                        </Col>
                      </Row>
                    ))
                ) : (
                  <p className="text-center">Cadastre os valores</p>
                )}
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            color="success"
            onClick={handleSave}
            loadingMessage={updating ? 'Termine a alteração para Salvar' : 'Salvando...'}
            loading={updating ? true : loading?.loading ? loading.loading : loading}
          >
            Salvar
          </CustomButton>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default forwardRef(TabelaValorEspecialista);
