import React, { useState, useEffect, useCallback } from 'react';

import classNames from 'classnames';

import { SIDEBARSHOW, SIDEBAROPEN } from '../../constants/app';
import Content from './content';
import SideBar from './sidebar';
import SideBarBackdrop from './sidebar/backdrop';

import './styles.css';

function LayoutAdmin() {
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const [sidebarIsShow, setSidebarShow] = useState(true);

  const toggleSidebar = useCallback(() => {
    setSidebarOpen((prevStateOpen) => {
      if (sidebarIsShow === false) {
        localStorage.setItem(SIDEBARSHOW, 'show');
        setSidebarShow(true);
        return prevStateOpen;
      }

      localStorage.setItem(SIDEBAROPEN, prevStateOpen ? 'closed' : 'opened');
      return !prevStateOpen;
    });
  }, [sidebarIsShow]);

  const toggleSidebarShow = useCallback(() => {
    setSidebarShow((prevState) => {
      localStorage.setItem(SIDEBARSHOW, prevState ? 'noshow' : 'show');
      return !prevState;
    });
  }, []);

  useEffect(() => {
    const sidebarShowStorage = localStorage.getItem(SIDEBARSHOW);
    if (sidebarShowStorage) {
      setSidebarShow(sidebarShowStorage === 'show');
    }

    const sidebarOpenStorage = localStorage.getItem(SIDEBAROPEN);
    if (sidebarOpenStorage) {
      setSidebarOpen(sidebarOpenStorage === 'opened');
    }
  }, []);

  return (
    <div className={classNames('admin-container wrapper', { 'is-open': sidebarIsOpen, closed: !sidebarIsShow })}>
      <SideBar
        toggle={toggleSidebarShow}
        isOpen={sidebarIsOpen}
        isShow={sidebarIsShow}
      />
      <SideBarBackdrop toggle={toggleSidebar} isOpen={sidebarIsOpen} />
      <Content toggleSidebar={toggleSidebar} sidebarIsOpen={sidebarIsOpen} />
    </div>
  );
}

export default LayoutAdmin;
