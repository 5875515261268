const navigation = [
  {
    to: '/',
    name: 'Dashboard',
    icon: 'fa-solid fa-chart-line',
    exact: true,
  },
  {
    to: '/administradores',
    name: 'Administradores',
    icon: 'fas fa-user-cog',
  },
  {
    to: '/profissionais',
    name: 'Profissionais',
    icon: 'fas fa-user-tie',
  },
  {
    to: '/clientes',
    name: 'Clientes',
    icon: 'fas fa-user',
  },
  {
    to: '/fornecedores',
    name: 'Fornecedores',
    icon: 'fa-solid fa-building-user',
  },
  {
    to: '/especialidades',
    name: 'Tipo de Atendimentos',
    icon: 'fa-solid fa-layer-group',
  },
  {
    to: '/grupos',
    name: 'Grupos',
    icon: 'fa-solid fa-users-rectangle',
  },
  /* {
    to: '/status',
    name: 'Status',
    icon: 'fa-solid fa-circle',
  }, */
  /* {
    to: '/tipos-atendimentos',
    name: 'Tipos de Atendimentos',
    icon: 'fa-regular fa-rectangle-list',
  }, */
  {
    to: '/salas-atendimentos',
    name: 'Salas de Atendimentos',
    icon: 'fa-brands fa-buromobelexperte',
  },
  {
    to: '/tabela-valores-especialistas',
    name: 'Tabela Especialistas',
    icon: 'fa-solid fa-table',
  },
  {
    to: '/tabela-valores-clientes',
    name: 'Tabela Clientes',
    icon: 'fa-solid fa-table',
  },
  {
    to: '/financeiro',
    name: 'Financeiro',
    icon: 'fa-solid fa-file-invoice-dollar',
  },
  {
    to: '/relatorios',
    name: 'Relatórios',
    icon: 'fa-solid fa-file-invoice',
  },
];

export default navigation;
