import { useState, useEffect, useRef } from 'react';

export const useDebounce = (value, milliSeconds = 1000) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, milliSeconds);

    return () => {
      clearTimeout(handler);
    };
  }, [value, milliSeconds]);

  return debouncedValue;
};

export const useDebounceHook = (initialValue, milliSeconds = 1000) => {
  const _value = useRef('');
  const handler = useRef();

  const [debouncedValue, setDebouncedValue] = useState(initialValue);

  function setValue(value) {
    _value.current = value;

    if (handler.current) clearTimeout(handler.current);

    handler.current = setTimeout(() => {
      setDebouncedValue(_value.current);
    }, milliSeconds);
  }

  return [debouncedValue, setValue];
};

export default useDebounce;
